import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment from 'moment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Documentacion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'programas',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      documentos: [],
      titulo: "",
      descripcion: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);

  }

    handleTitulo(event) {
        this.setState({titulo: event.target.value});
    }

    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("concurso") !== null){
        this.setState({concurso: JSON.parse(localStorage.getItem('concurso')).nidconcurso});
        this.listarDocumentos();
    }else{
      this.props.history.push('/programas');
    }
  }

  async listarDocumentos() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listdocumentos?nid=' + this.state.concurso)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                niddocumento: data.nid,
                titulo: data.ctxt_titulo,
                descripcion: data.ctxt_descripcion,
                tipo: data.ctxt_tipo
            })
          })
          this.setState({documentos: lista, isLoading: false})
        }else{
          this.setState({documentos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/removerdocumento?nid=' + data.niddocumento)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarDocumentos();
        this.props.handleClick("tl", "info", "El documento ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.listarDocumentos();
    this.cancelar();
    this.props.handleClick("tl", "info", "El documento ha sido registrada!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({titulo: "", descripcion: "", nidcarga: ""})
  }

  async volver(){
    this.props.history.push('/programas');
  }

  async update(){
    let fromData = new FormData();
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('nids', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/editardocumento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(tipo){
      let fromData = new FormData();
      fromData.append('titulo', this.state.titulo);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('tipo', tipo);
      fromData.append('nidpasantia', this.state.concurso);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${concursos}` + 'private/insertardocumento', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    this.setState({
      titulo: datos.titulo,
      descripcion: datos.descripcion,
      nidcarga: datos.niddocumento,
    })
  }

  async save(){
    if(this.state.titulo != "" && this.state.descripcion){
      if(this.state.nidcarga == ''){
        if(this.state.documentos.length == 5){
          this.props.handleClick("tl", "warning", "El programa ya no permite mas documentos!", "pe-7s-info")
        }else{
          let tipo = "";
          let file1 = 0;
          let file2 = 0;
          let file3 = 0;
          let file4 = 0;
          let file5 = 0;
          this.state.documentos.map((data) => {
              if(data.tipo == "file1"){
                 file1 = 1
              }else if(data.tipo == "file2"){
                 file2 = 1
              }else if(data.tipo == "file3"){
                 file3 = 1
              }else if(data.tipo == "file4"){
                 file4 = 1
              }else{
                 file5 = 1
              }
          })
          if(file1 == 0){
             tipo = "file1"
          }else if(file2 == 0){
             tipo = "file2"
          }else if(file3 == 0){
             tipo = "file3"
          }else if(file4 == 0){
             tipo = "file4"
          }else{
             tipo = "file5"
          }
          this.guardar(tipo)
        }
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "titulo", text: "TÍTULO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.documentos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.documentos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Documentos del Programa</h4>
              <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>TÍTULO DEL DOCUMENTO</ControlLabel>
                        <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                    </div>
                    <div className="col-md-8" style={{marginBottom: 18}}>
                        <ControlLabel>DESCRIPCIÓN DEL DOCUMENTO</ControlLabel>
                        <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                      <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Documentacion;