import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment from 'moment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Auditoria extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'auditoria',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: "",
      concursos: [],
      tiposconcurso: [],
      concurso: "",
      periodo: new Date().getFullYear(),
      mes: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true,
      fechaini: moment(new Date()).format('YYYY-MM-DD'),
      fechafin: moment(new Date()).format('YYYY-MM-DD')
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handleConcurso = this.handleConcurso.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleMes = this.handleMes.bind(this);

    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);

  }

    handleConcurso(event) {
        this.setState({concurso: event.target.value});
    }

    handlePeriodo(event) {
        this.setState({periodo: event.target.value});
    }

    handleMes(event) {
        this.setState({mes: event.target.value});
    }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

  handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
  }
  handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
  }

  async buscar(){
    swal({
      title: "Búsqueda de Concursos",
      text: 'Ingresa el año de búsqueda',
      content: "input",
      button: {
        text: "Buscar",
        closeModal: false,
      },
    })
    .then((value) => {
      if(value == null){
        swal.close();
      }else{
        this.setState({periodo: value})
        this.listarConcursos()
        swal.close();
      }
    });
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.calcularMes();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async calcularMes(){
    let mesx = new Date().getMonth() + 1;
    if(mesx == 13){
      this.setState({mes: '1'})
    }else{
      this.setState({mes: mesx})
    }
    this.cargaView();
  }

  formatoMes(mes){
    if(mes <= 9){
      mes = '0' + mes
    }else if(mes >= 13){
      mes = '01'
    }
    return mes
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listtipoconcursos')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid,
                label: data.ctxt_descripcion,
            })
          });
          this.setState({tiposconcurso: lista, selectedOption: {value: lista[0].value, label: lista[0].label}})
          this.listarConcursos();
      }else{
        this.setState({tiposconcurso: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

  async listarConcursos() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listconcursosx?anio=' + this.state.periodo)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidconcurso: data.nid,
                nidtipoconcurso: data.nid_tipo,
                concurso: data.ctxt_descripcion,
                tipo: data.ctxt_tipo,
                anio: data.cnum_anio,
                mes: data.cnum_mes,
                fecha: Calendar.calendario9(data.dfec_inicio),
                fecha2: Calendar.calendario9(data.dfec_final),
                fecha3: Calendar.calendario10(data.dfec_inicio),
                fecha4: Calendar.calendario10(data.dfec_final),
                publicado: data.flg_publicado
            })
          })
          this.setState({concursos: lista, isLoading: false})
        }else{
          this.setState({concursos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

 async limpiar(){
    this.listarConcursos();
    this.cancelar();
    this.props.handleClick("tl", "info", "El concurso ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: this.state.tiposconcurso[0].value, label: this.state.tiposconcurso[0].label}, concurso: "", nidcarga: ""})
  }
  
  async obtener(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/audiPostulantes');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.publicado == '1' ? (
              <span data-tip="Ver Postulantes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-graduation-cap fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concurso", text: "CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "anio", text: "PERIODO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "mes", text: "MES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO DE CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA INICIAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Concursos Públicados</h4>
              <Button style={{width: 150, marginTop: 10, marginBottom: 10}} bsStyle="success" pullLeft fill type="submit" onClick={() => { this.buscar() }}>Buscar</Button>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Auditoria;