import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import { validator } from 'store/Apis';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import { pdf, PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";

import moment from 'moment'
import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Remoto extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'remoto',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      labores: [],
      areas: [],
      trabajadores: [],
      area: "",
      trabajador: "",
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      tipoHorario2: [{value: "0", label: "JORNADA LABORAL"},{value: "1", label: "HORAS ADICIONALES"}, {value: "2", label: "TODAS"}],
      selectedOption2: {value: "0", label: "JORNADA LABORAL"},
      horas: 1,
      minutos: 0,
      nidcarga: "",
      estado: 0,
      isLoading: false,
      base: ""
    }
    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);

  }

    handleArea = area => {
        this.setState(
        { area },
        () => this.listarTrabajadores()
        );
    };

    handleTrabajador = trabajador => {
        this.setState(
        { trabajador },
        );
    };
    
    handleFechaini(event) {
      this.setState({fechaini: event.target.value});
    }

    handleFechafin(event) {
      this.setState({fechafin: event.target.value});
    }

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarAreas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarAreas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listAreas')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
                text: data.personname1
            })
          });
          this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label, text: lista[0].text}})
          this.listarTrabajadores()
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTrabajadores(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listPersonasxarea?nid=' + this.state.area.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.Person,
                label: data.nombre,
            })
          });
          this.setState({trabajadores: lista, trabajador: {value: lista[0].value, label: lista[0].label}})
      }else{
        this.setState({trabajadores: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarLabores() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'labores/listCargasxpersona?usuario=' + this.state.trabajador.value + '&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin + '&tipo=' + this.state.selectedOption2.value)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response.listCargas;
        let lista3 = res.data.response.listTotal[0].total;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                idlabor: data.nid,
                idarea: data.nid_area,
                oficina: data.oficina,
                producto: data.producto,
                horas: data.horas + " Hrs",
                horas2: data.horas,
                actividad: data.actividad,
                comentario: data.comentario,
                tipo: data.tipo,
                estado: data.estado == '' ? 'PENDIENTE' : data.estado,
                aprobador: data.aprobador,
                fecha: data.dfec_registro,
                fecha2: Calendar.calendario9(data.dfec_modificacion),
            })
          })
          this.setState({minutos: lista3, labores: lista, isLoading: false})
        }else{
          this.setState({labores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  conversorHoras(minutos){
    var hours = Math.floor( minutos / 60);          
    var minutes = (minutos % 60);
    return hours + ":" + minutes;
  }

  async verificar(){
    var row = [];
    row.push(
       { jefe: this.state.area.text,
         datos: this.state.trabajador.label,
         fechaini: Calendar.calendario6(this.state.fechaini),
         fechafin: Calendar.calendario6(this.state.fechafin),
         tipo: this.state.selectedOption2.label,
         minutos: this.conversorHoras(this.state.minutos)
     })
    const blob = pdf(PdfDocument(this.state.labores, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data});
          }
        }
        return result;
    }); 
  }

  async regresar(){
    this.setState({base: ""})
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "producto", text: "PRODUCTO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "actividad", text: "ACTIVIDAD", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "aprobador", text: "APROBADO POR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA DE APROBACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.labores.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.labores }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area, trabajador, selectedOption2 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Carga de Labores"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                    <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>LISTA DE ÁREAS</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                            <Select value={trabajador} onChange= {this.handleTrabajador} options={this.state.trabajadores} />
                        </div>
                    </div>
                  </div>}
              />
            </Col>
          </Row>
          {this.state.base == "" ? (
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Listado de Labores</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE HORARIO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoHorario2}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 150, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarLabores() }}>Buscar Labores</Button>
                        <Button style={{width: 150, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.verificar() }}>Generar PDF</Button>
                    </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          ) : (
          <Row>
          <Col md={12}>
            <Card
                title="Ver Documento"
                content={
                  <div>
                    <iframe src={this.state.base} style={{height: 1200, width: "100%"}}></iframe>
                    <Button style={{width: 150, marginTop: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                 }
              />
            </Col>
        </Row>
        )}
        </Grid>
      </div>
    );
  }
}

export default Remoto;