import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Adicionales extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7, 
        selectedOption8, selectedOption9, selectedOption10 } = this.state;
    return (
      <div className="content">
        <Tabs style={{marginTop: 30}}>
        <TabList>
            <Tab>DATOS ADICIONALES</Tab>
            </TabList>
            <TabPanel>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>GRADO ACADÉMICO</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.grados} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>ENTIDAD DE FORMACIÓN</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoentidadformacion} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CENTRO DE FORMACIÓN</ControlLabel>
                    <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.tipoentidadformacion} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CARRERA</ControlLabel>
                    <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.carreras} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AÑO DE EGRESADO</ControlLabel>
                    <FormControl type="text" value={this.state.anioegresado} onChange= {this.handleAnioEgresado} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE SANGRE</ControlLabel>
                    <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.tiposangre} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>NACIONALIDAD</ControlLabel>
                    <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.pais} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PAÍS</ControlLabel>
                    <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.pais} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>DEPARTAMENTO</ControlLabel>
                    <Select value={selectedOption8} onChange={this.handleChange8} options={this.state.departamentos} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PROVINCIA</ControlLabel>
                    <Select value={selectedOption9} onChange={this.handleChange9} options={this.state.provincias} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>DISTRITO</ControlLabel>
                    <Select value={selectedOption10} onChange={this.handleChange10} options={this.state.distritos} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>REFERENCIA</ControlLabel>
                    <FormControl type="text" value={this.state.referencian} onChange= {this.handleReferencia} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE NACIMIENTO</ControlLabel> 
                    <FormControl type="date" value={this.state.fechanacimiento} onChange= {this.handleFechaNacimiento} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>APELLIDO DE CASADO(A)</ControlLabel>
                    <FormControl type="text" value={this.state.apecasado} onChange= {this.handleApeCasado} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE HIJOS</ControlLabel>
                    <FormControl type="text" value={this.state.numerohijos} onChange= {this.handleNumeroHijos} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>¿TIENE RESPONSABILIDAD FAMILIAR?</ControlLabel><br></br>
                    <input type="radio" value="0" name="responsabilidad" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="responsabilidad" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>OBSERVACIÓN</ControlLabel>
                    <FormControl type="text" value={this.state.observacion} onChange= {this.handleObservacion} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row" style={{marginBottom: 20}}>
                {this.state.editable == true ? (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                    </div>
                    ) : (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                    </div>
                )}
            </div>
            </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default Adicionales;