import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import logo from "assets/img/icon.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      opera: JSON.parse(localStorage.getItem('operaciones'))
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div className="logo">
          <a className="simple-text logo-mini">
            <div className="logo-img">
              <img style={{height: 35, width: 32}} src={logo} alt="logo_image" />
            </div>
          </a>
          <a className="simple-text logo-normal"
            style={{letterSpacing: 1}}>
            SISPER
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.state.opera !== null ?
            this.state.opera.map((data, key) => {
                return (
                  <li className={/*data.descripcion == 'CHAT TRIBUNAL' ? "active active-pro" : */ this.activeRoute(data.enlaceWeb)} key={key}>
                    <NavLink
                      style={{letterSpacing: 1}}
                      to={data.enlaceWeb}
                      className="nav-link"
                      activeClassName="active">
                      <i className={data.icono} />
                      <p>{data.descripcion}</p>
                    </NavLink>
                  </li>
                );
            }) : null }

          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;