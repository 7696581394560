import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';

import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AgregarAcademico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'historicoAcademico',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: "", selectedOption2: "", selectedOption3: "", selectedOption4: "", selectedOption5: "", selectedOption6: "",
      tipos: [], areas: [], trabajadores: [], grado: [], tipoformacion: [], centroestudios: [],
      descripcion: "", titulo: "", fechainicio: "", fechatermino: "", observaciones: "", folios: "", curso: "", horas: "", creditos: "", 
      nota: "", observaciones2: "", folios2: "", fechainicio2: "", fechatermino2: "",
      estado: 0,
      isLoading: true,
      show: true,
      archivo: "",
      file: [],
      archivo2: "",
      file2: []
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFechaInicio = this.handleFechaInicio.bind(this);
    this.handleFechaTermino = this.handleFechaTermino.bind(this);
    this.handleObservaciones = this.handleObservaciones.bind(this);
    this.handleFolios = this.handleFolios.bind(this);
    this.handleCurso = this.handleCurso.bind(this);
    this.handleHoras = this.handleHoras.bind(this);
    this.handleCreditos = this.handleCreditos.bind(this);
    this.handleNota = this.handleNota.bind(this);
    this.handleObservaciones2 = this.handleObservaciones2.bind(this);
    this.handleFolios2 = this.handleFolios2.bind(this);
    this.handleFechaInicio2 = this.handleFechaInicio2.bind(this);
    this.handleFechaTermino2 = this.handleFechaTermino2.bind(this);

  }

    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFechaInicio(event) {this.setState({fechainicio: event.target.value});}
    handleFechaTermino(event) {this.setState({fechatermino: event.target.value});}
    handleObservaciones(event) {this.setState({observaciones: event.target.value});}
    handleFolios(event) {this.setState({folios: event.target.value});}
    handleCurso(event) {this.setState({curso: event.target.value});}
    handleHoras(event) {this.setState({horas: event.target.value});}
    handleCreditos(event) {this.setState({creditos: event.target.value});}
    handleNota(event) {this.setState({nota: event.target.value});}
    handleObservaciones2(event) {this.setState({observaciones2: event.target.value});}
    handleFolios2(event) {this.setState({folios2: event.target.value});}
    handleFechaInicio2(event) {this.setState({fechainicio2: event.target.value});}
    handleFechaTermino2(event) {this.setState({fechatermino2: event.target.value});}

    handleChange = selectedOption => {this.setState({ selectedOption },() => this.trabajadores());};
    handleChange2 = selectedOption2 => {this.setState({ selectedOption2 },) };
    handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },) };
    handleChange4 = selectedOption4 => {this.setState({ selectedOption4 },) };
    handleChange5 = selectedOption5 => {this.setState({ selectedOption5 },) };
    handleChange6 = selectedOption6 => {this.setState({ selectedOption6 },) };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.areas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async areas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listarareas')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({areas: lista2})
          this.listatipos();
      }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar áreas!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listatipos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listartiposacademico')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({tipos: lista2, isLoading: false})
      }else{
        this.setState({tipos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar listado!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async trabajadores() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listartrabajadores?codigoArea=' + this.state.selectedOption.value)
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({trabajadores: lista2, isLoading: false})
      }else{
        this.setState({trabajadores: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar trabajadores!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async validar(param){
    var folios = ""; var folios2 = ""; var creditos = ""; var nota = "";
    if(this.state.folios == ''){
        folios = "0";
    }else{
        folios = this.state.folios;
    }
    if(this.state.folios2 == ''){
        folios2 = "0";
    }else{
        folios2 = this.state.folios2;
    }
    if(this.state.creditos == ''){
        creditos = "0";
    }else{
        creditos = this.state.creditos;
    }
    if(this.state.nota == ''){
        nota = "0";
    }else{
        nota = this.state.nota;
    }
    this.verificar(param,folios,folios2,creditos,nota)
  }

  async verificar(param,folios,folios2,creditos,nota){
      if(param == 0){
        if(this.state.descripcion != '' || this.state.titulo != '' || this.state.fechainicio != '' || this.state.fechatermino != '' ||  this.state.observaciones != ''
        ||  this.state.archivo != ''){
          this.agregar(folios);
        }else{
          this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
        }
      }else{
        if(this.state.curso != '' || this.state.fechainicio2 != '' || this.state.fechatermino2 != '' || this.state.observaciones2 != '' ||  this.state.archivo2 != ''){
          this.agregar2(folios2,creditos,nota);
        }else{
          this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
        }
      }
  }

  async agregar(folios){   
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('archivo', this.state.archivo);
    fromData.append('grado', this.state.selectedOption3.value);
    fromData.append('tipoformacion', this.state.selectedOption4.value);
    fromData.append('centroestudios', this.state.selectedOption5.value);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('titulo', this.state.titulo);
    fromData.append('fechainicio', this.state.fechainicio);
    fromData.append('fechatermino', this.state.fechatermino);
    fromData.append('observaciones', this.state.observaciones);
    fromData.append('folios', folios);
    fromData.append('nidpersona', this.state.selectedOption2.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/formacion/agregarformacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Formación registrada correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async agregar2(folios2,creditos,nota){   
    let fromData = new FormData();
    fromData.append('file', this.state.file2);
    fromData.append('archivo', this.state.archivo2);
    fromData.append('centroestudios', this.state.selectedOption6.value);
    fromData.append('curso', this.state.curso);
    fromData.append('fechainicio', this.state.fechainicio2);
    fromData.append('fechatermino', this.state.fechatermino2);
    fromData.append('horas', this.state.horas);
    fromData.append('creditos', creditos);
    fromData.append('nota', nota);
    fromData.append('observaciones', this.state.observaciones2);
    fromData.append('folios', folios2);
    fromData.append('nidpersona', this.state.selectedOption2.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/formacion/agregarformacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Formación registrada correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/historicoAcademico');
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  subir2 = (files2) => {
    if(files2.length !== 0){
        this.setState({file2: files2[0], archivo2: files2[0].name});
    }
  }

  async limpiar(){
    this.setState({archivo: "", file: []})
  }

  async limpiar2(){
    this.setState({archivo2: "", file2: []})
  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Agregar Nuevo Registro Académico</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.areas}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.trabajadores}/>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <Tabs style={{marginTop: 30}}>
                        <TabList>
                        <Tab>ESTUDIOS DE FORMACIÓN PROFESIONAL</Tab>
                        <Tab>CURSOS, CONOCIMIENTOS Y ESPECIALIZACIONES</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>GRADO ACADÉMICO</ControlLabel>
                                <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.grado}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CARRERA PROFESIONAL</ControlLabel>
                                <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.tipoformacion}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.centroestudios}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN DEL GRADO</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO RECIBIDO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INICIO</ControlLabel>
                                <FormControl type="date" value={this.state.fechainicio} onChange= {this.handleFechaInicio}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                                <FormControl type="date" value={this.state.fechatermino} onChange= {this.handleFechaTermino}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>OBSERVACIONES</ControlLabel>
                                <FormControl type="text" value={this.state.observaciones} onChange= {this.handleObservaciones}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE FOLIOS</ControlLabel>
                                <FormControl type="text" value={this.state.folios} onChange= {this.handleFolios}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                        </div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar(0) }}>Guardar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                        </TabPanel>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.centroestudios}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CURSO O ESPECIALIZACIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.curso} onChange= {this.handleCurso}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INICIO</ControlLabel>
                                <FormControl type="date" value={this.state.fechainicio2} onChange= {this.handleFechaInicio2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                                <FormControl type="date" value={this.state.fechatermino2} onChange= {this.handleFechaTermino2}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE HORAS</ControlLabel>
                                <FormControl type="text" value={this.state.horas} onChange= {this.handleHoras}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CREDITOS</ControlLabel>
                                <FormControl type="text" value={this.state.creditos} onChange= {this.handleCreditos}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>NOTA EMITIDA</ControlLabel>
                                <FormControl type="text" value={this.state.nota} onChange= {this.handleNota}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>OBSERVACIONES</ControlLabel>
                                <FormControl type="text" value={this.state.observaciones2} onChange= {this.handleObservaciones2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE FOLIOS</ControlLabel>
                                <FormControl type="text" value={this.state.folios2} onChange= {this.handleFolios2}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir2(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo2 != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}                            
                            </div>
                        </div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar(1) }}>Guardar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                        </TabPanel>
                    </Tabs>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AgregarAcademico;