import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos, canaltv } from 'store/Apis'

import moment from 'moment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Canales extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'canales',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: "",
      canales: [],
      principal: [],
      file1: [],
      file2: [],
      canal: "",
      descripcion: "",
      oldarchivo1: "",
      oldarchivo2: "",
      archivo1: "",
      archivo2: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);

    this.handleCanal = this.handleCanal.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);

  }

    handleCanal(event) {
        this.setState({canal: event.target.value});
    }

    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.cargaView();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${canaltv}` + 'private/combocanales')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        lista.push(
            { value: "0",
              label: "SIN CANAL PRINCIPAL",
          })
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid,
                label: data.ctxt_titulo,
            })
          });
          this.setState({principal: lista, selectedOption: {value: lista[0].value, label: lista[0].label}})
          this.listarCanales();
      }else{
        this.setState({tiposconcurso: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarCanales() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${canaltv}` + 'private/listcanalesad')
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidcanal: data.nid,
                titulo: data.ctxt_titulo,
                descripcion: data.ctxt_descripcion,
                imagen1: data.cnom_imagen1,
                imagen2: data.cnom_imagen2,
                ruta1: data.ctxt_ruta1,
                ruta2: data.ctxt_ruta2,
                menu: data.nid_menu,
                principal: data.ctxt_principal
            })
          })
          this.setState({canales: lista, isLoading: false})
        }else{
          this.setState({canales: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${canaltv}` + 'private/anularcanal?nidcanal=' + data.nidcanal)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarCanales();
        this.props.handleClick("tl", "info", "El canal ha sido anulado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.listarCanales();
    this.cancelar();
    this.props.handleClick("tl", "info", "El canal ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: "0", label: "Sin Opción Principal"}, canal: "", descripcion: "", nidcarga: "", archivo1: "", file1: [], archivo2: "", file2: []})
  }

  async update(param1, param2){
    let fromData = new FormData();
    fromData.append('file1', this.state.file1);
    fromData.append('file2', this.state.file2);
    fromData.append('titulo', this.state.canal);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('oldimagen1', this.state.oldarchivo1);
    fromData.append('oldimagen2', this.state.oldarchivo2);
    fromData.append('newimagen1', param1);
    fromData.append('newimagen2', param2);
    fromData.append('nidmenu', this.state.selectedOption.value)
    fromData.append('nidcanal', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${canaltv}` + 'private/editarcanal', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      let fromData = new FormData();
      fromData.append('file1', this.state.file1);
      fromData.append('file2', this.state.file2);
      fromData.append('titulo', this.state.canal);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('imagen1', this.state.archivo1);
      fromData.append('imagen2', this.state.archivo2);
      fromData.append('nidmenu', this.state.selectedOption.value);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${canaltv}` + 'private/insertarcanal', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    if(datos.menu == '0'){
        this.setState({selectedOption: {value: '0', label:"Sin Opción Principal"}})
    }else{
        this.setState({selectedOption: {value: ''+datos.menu, label: datos.principal}})
    }
    this.setState({
      canal: datos.titulo,
      descripcion: datos.descripcion,
      nidcarga: datos.nidcanal,
      oldarchivo1: datos.imagen1,
      oldarchivo2: datos.imagen2
    })
  }

  async detalle(data){
    localStorage.setItem('canal', JSON.stringify(data))
    this.props.history.push('/videos');
  }

  async save(){
    if(this.state.nidcarga == ''){
      if(this.state.canal != "" && this.state.descripcion != "" && this.state.archivo1 != "" && this.state.archivo2 != ""){
        this.guardar()
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      var param1 = "";
      var param2 = "";
      if(this.state.canal != "" && this.state.descripcion != ""){
        if(this.state.archivo1 == ""){
          param1 = "--";
        }else{
          param1 = this.state.archivo1;
        }
        if(this.state.archivo2 == ""){
          param2 = "--"
        }else{
          param2 = this.state.archivo2;
        }
        this.update(param1, param2)
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Videos" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-folder-open-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Editar Canal" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Canal" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    return (
          <div>
              {row.ruta1 == null || row.ruta1 == ""? (
              <p style={{fontSize: 14}}>SIN IMAGEN</p>) : (
              <Button data-tip="Ver Imagen 1" bsStyle="success" type="submit" onClick={() => { this.mostrar(row.ruta1) }}>{row.imagen1}<ReactTooltip /></Button>
              )}
          </div>
      );
  }

  GetActionFormat3(cell, row) {
    return (
          <div>
              {row.ruta2 == null || row.ruta2 == "" ? (
              <p style={{fontSize: 14}}>SIN IMAGEN</p>) : (
              <Button data-tip="Ver Imagen 2" bsStyle="success" type="submit" onClick={() => { this.mostrar(row.ruta2) }}>{row.imagen2}<ReactTooltip /></Button>
              )}
          </div>
      );
  }

  async mostrar(imagen){
    // window.open("https://tctv.sedetc.gob.pe/RECURSOS/"  + imagen, "_blank")
    window.open(imagen, "_blank")
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file1: files[0], archivo1: files[0].name});
    }
  }

  subir2 = (files) => {
    if(files.length !== 0){
        this.setState({file2: files[0], archivo2: files[0].name});
    }
  }

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "titulo", text: "TITULO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "DESCRIPCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "imagen1", text: "IMAGEN 1", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "imagen2", text: "IMAGEN 2", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "principal", text: "CANAL PRINCIPAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.canales.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.canales }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Canales Públicados</h4>
              <div className="row">
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>CANAL</ControlLabel>
                        <FormControl type="text" value={this.state.canal} onChange= {this.handleCanal}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>CANAL PRINCIPAL</ControlLabel>
                        <Select value={selectedOption} onChange={this.handleChange} options={this.state.principal}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>IMAGEN 1 (Header)</ControlLabel>
                        {this.state.archivo1 !== "" ? (
                          <Alert bsStyle="warning">
                            <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo1}</span>
                          </Alert>
                          ) : (
                          <Button style={{width: '100%'}} bsStyle="success" pullRight fill type="submit">
                            <InputFiles onChange={files => this.subir(files)}>Seleccionar la imagen</InputFiles>
                          </Button>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel>DESCRIPCION</ControlLabel>
                      <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel>IMAGEN 2 (Banner)</ControlLabel>
                      {this.state.archivo2 !== "" ? (
                        <Alert bsStyle="warning">
                        <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                        </Alert>
                        ) : (
                        <Button style={{width: '100%'}} bsStyle="success" pullRight fill type="submit">
                            <InputFiles onChange={files => this.subir2(files)}>Seleccionar la imagen</InputFiles>
                        </Button>
                      )}
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel>ACCIONES</ControlLabel><br></br>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar Canal</Button><br></br>
                      <Button style={{width: 150}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar o Cancelar</Button>
                    </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Canales;