import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {canaltv} from 'store/Apis'
import moment from 'moment'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Videos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'canales',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      selectedOption: {value: "1", label: "GRABADO"},
      videos: [],
      tiposvideos: [{value: "1", label: "GRABADO"},{value: "2", label: "EN VIVO"}],
      titulo: "",
      descripcion: "",
      palabras: "",
      fecha: "",
      fecha2: "",
      codigo: "",
      nidcarga: "",
      estado: 0,
      canal: '',
      nidcanal: '',
      anio: '',
      mes: '',
      date: moment().format('YYYY-MM-DD'),
      datevis: false,
      botonvis: 'Buscar',
      isLoading: true,
      isGoing: false
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);

    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handlePalabras = this.handlePalabras.bind(this);
    this.handleCodigo = this.handleCodigo.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleFecha2 = this.handleFecha2.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

    handleTitulo(event) {
        this.setState({titulo: event.target.value});
    }
    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }
    handlePalabras(event) {
        this.setState({palabras: event.target.value});
    }
    handleCodigo(event) {
        this.setState({codigo: event.target.value});
    }
    handleFecha(event) {
        this.setState({fecha: event.target.value});
    }
    handleFecha2(event) {
        this.setState({fecha2: event.target.value},() => this.calcularMes2());
    }
    handleDate(event) {
        this.setState({date2: event.target.value});
    }
    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };
    handleChange2 = date => {
        this.setState(
        { date },() => this.listvideos()
        );
    };
    handleCheck(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("canal") !== null){
        this.setState({nidcanal: JSON.parse(localStorage.getItem('canal')).nidcanal, 
                       canal: JSON.parse(localStorage.getItem('canal')).titulo
                     });
        this.calcularMes();
    }else{
      this.props.history.push('/canales');
    }
  }

  formatoMes(mes){
    if(mes <= 9){
      mes = '0' + mes
    }else if(mes >= 13){
      mes = '01'
    }
    return mes
  }

  async calcularMes(){
    let anio = new Date().getFullYear();
    let mes = new Date().getMonth() + 1;
    if(mes == 13){
      mes = this.formatoMes(1);
    }else{
      mes = this.formatoMes(mes);
    }
    this.setState({fecha2: anio + '-' + mes})
    this.listvideos(anio, mes);
  }

  async calcularMes2(){
    this.setState({datevis: false, botonvis: 'Buscar'})
    var periodo = this.state.fecha2;
    var anio = periodo.substring(0,4);
    var mes = periodo.substring(5);
    this.listvideos(anio, mes);
  }

  async listvideos(anio, mes) {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${canaltv}` + 'private/listvideosad?nidcanal=' + this.state.nidcanal + '&anio=' + anio + '&mes=' + mes)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nid: data.nid,
                titulo: data.ctxt_titulo,
                descripcion: data.ctxt_descripcion,
                clave: data.ctxt_clave,
                codigo: data.ctxt_ruta,
                principal: data.flg_principal,
                nidtipo: data.nidtipo,
                tipo: data.tipo,
                fecha: Calendar.calendario12(data.dfec_publicacion)
            })
          })
          this.setState({videos: lista, isLoading: false})
          this.cancelar();
        }else{
          this.setState({videos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${canaltv}` + 'private/anularvideo?nidvideo=' + data.nid)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.calcularMes2();
        this.props.handleClick("tl", "info", "El video ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.calcularMes2();
    this.cancelar();
    this.props.handleClick("tl", "info", "El video ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: "1", label: "GRABADO"}, titulo: "", descripcion: "", palabras: "", fecha: "", codigo: "", nidcarga: "", isGoing: false})
  }

  async volver(){
    this.props.history.push('/canales');
  }

  async update(){
    var check = 0;
    if(this.state.isGoing == true){
      check = 1;
    }
    let fromData = new FormData();
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('clave', this.state.palabras);
    fromData.append('ruta', this.state.codigo);
    fromData.append('nidcanal', this.state.nidcanal);
    fromData.append('publicacion', this.state.fecha);
    fromData.append('principal', check);
    fromData.append('tipo', this.state.selectedOption.value);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidvideo', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${canaltv}` + 'private/editarvideo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      var check = 0;
      if(this.state.isGoing == true){
        check = 1;
      }
      let fromData = new FormData();
      fromData.append('titulo', this.state.titulo);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('clave', this.state.palabras);
      fromData.append('ruta', this.state.codigo);
      fromData.append('nidcanal', this.state.nidcanal);
      fromData.append('publicacion', this.state.fecha);
      fromData.append('principal', check);
      fromData.append('tipo', this.state.selectedOption.value);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${canaltv}` + 'private/insertarvideo', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    if(datos.principal == 1){
      this.setState({isGoing: true})
    }else{
      this.setState({isGoing: false})
    }
    this.setState({
      selectedOption: {value: datos.nidtipo, label: datos.tipo},
      titulo: datos.titulo,
      descripcion: datos.descripcion,
      palabras: datos.clave,
      codigo: datos.codigo,
      fecha: Calendar.calendario11(datos.fecha),
      nidcarga: datos.nid
    })
  }

  async save(){
    if(this.state.titulo != "" && this.state.descripcion && this.state.palabras != "" != "" && this.state.codigo != "" && this.state.fecha != ""){
      if(this.state.nidcarga == ""){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Video" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Video" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    return (
        <div>
            {row.nidtipo == 1 ? (
            <Button bsStyle="info" type="submit">{row.tipo}</Button>
            ) : (
            <Button bsStyle="success" type="submit">{row.tipo}</Button>
            )}
        </div>
    );
  }

  GetActionFormat3(cell, row) {
    return (
        <div>
            {row.principal == 1 ? (
            <Button bsStyle="warning" type="submit">PRINCIPAL</Button>
            ) : (null)}
        </div>
    );
  }

  async buscar(){
    if(this.state.datevis == false){
        this.setState({datevis: true, botonvis: 'Ocultar'})
    }else{
        this.setState({datevis: false, botonvis: 'Buscar'})
    }
  }

  render() {
    const { selectedOption, datevis, botonvis, classvis } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "titulo", text: "TÍTULO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "clave", text: "PALABRA CLAVE", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "codigo", text: "CÓDIGO DE YOUTUBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE PUBLICACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "principal", text: "PRINCIPAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.videos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.videos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Videos del Canal: {this.state.canal}</h4>
                <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>TÍTULO</ControlLabel>
                        <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                    </div>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>DESCRIPCIÓN</ControlLabel>
                        <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PALABRAS CLAVE</ControlLabel>
                        <FormControl type="text" value={this.state.palabras} onChange= {this.handlePalabras}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>CÓDIGO DE YOTUBE</ControlLabel>
                        <FormControl type="text" value={this.state.codigo} onChange= {this.handleCodigo}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE PUBLICACIÓN</ControlLabel>
                        <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPOS DE VIDEO</ControlLabel>
                        <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposvideos}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8" style={{letterSpacing: 1, marginBottom: 20}}>
                        <ControlLabel>VIDEO PRINCIPAL</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}}/>
                            <a>Al marcar este video como principal aparecerá en la página de TC en Vivo.</a>
                        </div>
                    </div>
                    <div className="col-md-4" style={{marginTop: 10, marginBottom: 18}}>
                        <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.buscar() }}>{botonvis}</Button>
                        <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                        <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                        {datevis == true ? (
                            <FormControl type="month" value={this.state.fecha2} onChange= {this.handleFecha2}/>
                        ) : (null)}
                    </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Videos;