import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.min.js';
// import $ from 'jquery';
// import Popper from 'popper.js';

import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import AdminLayout from "layouts/Admin.jsx";

import Login from "login/Login.jsx";

import { Provider } from 'react-redux';

ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/" render={props => <AdminLayout {...props} />} />
      </Switch>
    </BrowserRouter>,
  document.getElementById("root")
);