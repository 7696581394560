import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Informacion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7 } = this.state;
    return (
      <div className="content">
        <Tabs style={{marginTop: 30}}>
        <TabList>
            <Tab>INFORMACIÓN GENERAL</Tab>
            </TabList>
            <TabPanel>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CÓDIGO HM</ControlLabel>
                    <FormControl type="text" value={this.state.codigohm} onChange= {this.handleCodigoHm} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CÓDIGO SISPER</ControlLabel>
                    <FormControl type="text" value={this.state.codigosisper} onChange= {this.handleCodigoSisper} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CÓDIGO TRABAJADOR</ControlLabel>
                    <FormControl type="text" value={this.state.codigotrabajador} onChange= {this.handleCodigoTrabajador} disabled/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{marginBottom: 18}}>
                    <ControlLabel>DATOS COMPLETOS</ControlLabel>
                    <FormControl type="text" value={this.state.datoscompletos} onChange= {this.handleDatosCompletos} disabled/>
                </div>
            </div>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>APELLIDO PATERNO</ControlLabel>
                    <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>APELLIDO MATERNO</ControlLabel>
                    <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PRIMER NOMBRE</ControlLabel>
                    <FormControl type="text" value={this.state.primernombre} onChange= {this.handlePrimerNombre}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>SEGUNDO NOMBRE</ControlLabel>
                    <FormControl type="text" value={this.state.segundonombre} onChange= {this.handleSegundoNombre}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PAÍS EMISOR</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.listpaises}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.listtiposdocumento}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE DOCUMENTO</ControlLabel>
                    <FormControl type="text" value={this.state.numerodocumento} onChange= {this.handleNumeroDocumento}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE SEXO</ControlLabel>
                    <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.listtiposexo}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>ESTADO CIVIL</ControlLabel>
                    <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.listestadocivil}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE RÉGIMEN</ControlLabel>
                    <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.listtiporegimen}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AREA LABORAL</ControlLabel>
                    <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.listareas}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CARGO ESTRUCTURAL</ControlLabel>
                    <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.cargo}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>¿DISCAPACITADO?</ControlLabel><br></br>
                    <input type="radio" value="0" name="discapacitado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="discapacitado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue2}>
                    <ControlLabel>¿DOMICILIADO?</ControlLabel><br></br>
                    <input type="radio" value="0" name="domiciliado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="domiciliado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
            </div>
            <div className="row">
                {this.state.editable == true ? (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                    </div>
                    ) : (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                    </div>
                )}
            </div>
            </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default Informacion;