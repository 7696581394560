import moment from 'moment'

export default class Calendar {

    static calendario(timestamp){
        var fecha = new Date(timestamp * 1000)
        var semana = fecha.getDay()
        var dia = fecha.getDate()
        var mes = fecha.getMonth()
        var anio = fecha.getFullYear()
        var hora = fecha.getHours()
        var min = fecha.getMinutes()
      
        switch(semana) {
          case 1:semana = 'Lunes' 
          break;
          case 2:semana = 'Martes'
          break;
          case 3:semana = 'Miercoles'
          break;
          case 4:semana = 'Jueves'
          break;
          case 5:semana = 'Viernes'
          break;
          case 6:semana = 'Sabado'
          break;
          case 7:semana = 'Domingo'
          break;
          default:
          //   Alert.alert("Error al mostrar fecha");
        }
        switch(mes) {
          case 0:mes = 'Enero' 
          break;
          case 1:mes = 'Febrero'
          break;
          case 2:mes = 'Marzo'
          break;
          case 3:mes = 'Abril'
          break;
          case 4:mes = 'Mayo'
          break;
          case 5:mes = 'Junio'
          break;
          case 6:mes = 'Julio'
          break;
          case 7:mes = 'Agosto'
          break;
          case 8:mes = 'Septiembre'
          break;
          case 9:mes = 'Octubre'
          break;
          case 10:mes = 'Noviembre'
          break;
          case 11:mes = 'Diciembre'
          break;
          default: 
        }
        var dfec = semana + ', ' + dia + ' de ' + mes + ' del ' + anio + ' a las ' + hora + ':' + min
        return dfec
      }

      static calendario2(timestamp){
        var fecha = new Date(timestamp * 1000)
        var dfec = {
           dia: fecha.getDate(),
           mes: fecha.getMonth(),
           anio: fecha.getFullYear(),
           hora: fecha.getHours(),
           min: fecha.getMinutes()
        }
        return dfec
      }

      static calendario3(fecha){
        var anio = fecha.substr(0,4)
        var mes = fecha.substr(5,2) - 1
        var dia = fecha.substr(8,2)
        var hora = fecha.substr(11,2)
        var min = fecha.substr(14,2)
        var dfec = new Date(anio, mes, dia, hora, min);
        return dfec
      }

      static calendario4(timestamp){
        var fecha = new Date(timestamp * 1000)
        var dfec = {
           dia: fecha.getDate(),
           mes: fecha.getMonth() + 1,
           anio: fecha.getFullYear(),
           hora: fecha.getHours(),
           min: fecha.getMinutes()
        }
        if(dfec.mes < 10){
          dfec.mes = '0' + dfec.mes
        }
        if(dfec.dia < 10){
          dfec.dia = '0' + dfec.dia
        }
        if(dfec.hora < 10){
          dfec.hora = '0' + dfec.hora
        }
        if(dfec.min < 10){
          dfec.min = '0' + dfec.min
        }
        var fech = dfec.anio + '-' + dfec.mes + '-' + dfec.dia + ' ' + dfec.hora + ':' + dfec.min;
        return fech
      }

      static calendario5(timestamp){
        var actual = moment(new Date()).format('YYYY-MM-DD HH:mm')
        var fecha = new Date(timestamp)
        var dfec = {
           dia: fecha.getDate(),
           mes: fecha.getMonth() + 1,
           anio: fecha.getFullYear(),
           hora: fecha.getHours(),
           min: fecha.getMinutes()
        }
        if(dfec.mes < 10){
          dfec.mes = '0' + dfec.mes
        }
        if(dfec.dia < 10){
          dfec.dia = '0' + dfec.dia
        }
        if(dfec.hora < 10){
          dfec.hora = '0' + dfec.hora
        }
        if(dfec.min < 10){
          dfec.min = '0' + dfec.min
        }
        fecha = dfec.anio + '-' + dfec.mes + '-' + dfec.dia + ' ' + dfec.hora + ':' + dfec.min;
        if(fecha > actual){
           return false
        }else{
          return true
        }
      }

      static calendario6(fecha){
        var anio = fecha.substr(0,4)
        var mes = fecha.substr(5,2)
        var dia = fecha.substr(8,2)
        switch(mes) {
            case '01':mes = 'Enero' 
            break;
            case '02':mes = 'Febrero'
            break;
            case '03':mes = 'Marzo'
            break;
            case '04':mes = 'Abril'
            break;
            case '05':mes = 'Mayo'
            break;
            case '06':mes = 'Junio'
            break;
            case '07':mes = 'Julio'
            break;
            case '08':mes = 'Agosto'
            break;
            case '09':mes = 'Septiembre'
            break;
            case '10':mes = 'Octubre'
            break;
            case '11':mes = 'Noviembre'
            break;
            case '12':mes = 'Diciembre'
            break
            default: 
          }
        var dfec = dia + ' de ' + mes + ' del ' + anio
        return dfec
      }

      static calendario7(timestamp){
        var pref = JSON.stringify(timestamp).substr(0,1);
        if(timestamp != null){
          if(pref != '-'){
            var fecha = new Date(timestamp)
            var dfec = {
               dia: fecha.getDate(),
               mes: fecha.getMonth() + 1,
               anio: fecha.getFullYear(),
               hora: fecha.getHours(),
               min: fecha.getMinutes()
            }
            if(dfec.mes < 10){
              dfec.mes = '0' + dfec.mes
            }
            if(dfec.dia < 10){
              dfec.dia = '0' + dfec.dia
            }
            if(dfec.hora < 10){
              dfec.hora = '0' + dfec.hora
            }
            if(dfec.min < 10){
              dfec.min = '0' + dfec.min
            }
            var fech = dfec.hora + ':' + dfec.min;
            return fech
          }else{
            var fech = '';
            return fech
          }
          }
      }

      static calendario8(fecha){
        var dia = fecha.substr(0,2)
        var mes = fecha.substr(3,2)
        var anio = fecha.substr(6,10)
        var dfec = anio + mes + dia
        return dfec
      }

      static calendario9(timestamp){
        var pref = JSON.stringify(timestamp).substr(0,1);
        if(timestamp != null){
          if(pref != '-'){
            var fecha = new Date(timestamp)
            var dfec = {
               dia: fecha.getDate(),
               mes: fecha.getMonth() + 1,
               anio: fecha.getFullYear(),
               hora: fecha.getHours(),
               min: fecha.getMinutes()
            }
            if(dfec.mes < 10){
              dfec.mes = '0' + dfec.mes
            }
            if(dfec.dia < 10){
              dfec.dia = '0' + dfec.dia
            }
            if(dfec.hora < 10){
              dfec.hora = '0' + dfec.hora
            }
            if(dfec.min < 10){
              dfec.min = '0' + dfec.min
            }
            var fech = dfec.dia + '-' + dfec.mes + '-' + dfec.anio + ' ' + dfec.hora + ':' + dfec.min;
            return fech
          }else{
            var fech = '';
            return fech
          }
          }
      }

      static calendario10(timestamp){
            var fecha = new Date(timestamp)
            var dfec = {
               dia: fecha.getDate(),
               mes: fecha.getMonth() + 1,
               anio: fecha.getFullYear()
            }
            if(dfec.mes < 10){
              dfec.mes = '0' + dfec.mes
            }
            if(dfec.dia < 10){
              dfec.dia = '0' + dfec.dia
            }
            if(dfec.hora < 10){
              dfec.hora = '0' + dfec.hora
            }
            if(dfec.min < 10){
              dfec.min = '0' + dfec.min
            }
            var fech = dfec.anio + '-' + dfec.mes + '-' + dfec.dia
            return fech
      }

      static calendario11(fecha){
        var dia = fecha.substr(0,2)
        var mes = fecha.substr(3,2)
        var anio = fecha.substr(6,4)
        var dfec = anio + '-' + mes + '-' + dia
        return dfec
      }

      static calendario12(fecha){
        var anio = fecha.substr(0,4)
        var mes = fecha.substr(5,2)
        var dia = fecha.substr(8,2)
        var dfec = dia + '-' + mes + '-' + anio
        return dfec
      }
}