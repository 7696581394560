import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment from 'moment'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Entrevistas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'concursos',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: {value: "1", label: "EN VIVO"},
      entrevistas: [],
      tiposentrevistas: [{value: "1", label: "EN VIVO"},{value: "2", label: "GRABADA"}],
      entrevista: "",
      nidcarga: "",
      estado: 0,
      concurso: '',
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleEntrevista = this.handleEntrevista.bind(this);
    this.handleCodigo = this.handleCodigo.bind(this);
  }

    handleEntrevista(event) {
        this.setState({entrevista: event.target.value});
    }

    handleCodigo(event) {
        this.setState({codigo: event.target.value});
    }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("concurso") !== null){
        this.setState({concurso: JSON.parse(localStorage.getItem('concurso')).nidconcurso, 
                       tipo: JSON.parse(localStorage.getItem('concurso')).tipo
                     });
        this.listentrevistasxconcurso();
    }else{
      this.props.history.push('/concursos');
    }
  }

  async listentrevistasxconcurso() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listentrevistasxconcurso?nid=' + this.state.concurso)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nid: data.nid,
                descripcion: data.ctxt_descripcion,
                tipo: data.ctxt_tipo,
                link: data.ctxt_link,
                fecha: Calendar.calendario9(data.dfec_creacion)
            })
          })
          this.setState({entrevistas: lista, isLoading: false})
        }else{
          this.setState({entrevistas: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/removerentrevista?nidentrevista=' + data.nid)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listentrevistasxconcurso();
        this.props.handleClick("tl", "info", "La entrevista ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.listentrevistasxconcurso();
    this.cancelar();
    this.props.handleClick("tl", "info", "La entrevista ha sido registrada!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: "1", label: "EN VIVO"}, entrevista: "", codigo: "", nidcarga: ""})
  }

  async volver(){
    this.props.history.push('/concursos');
  }

  async update(){
    let fromData = new FormData();
    fromData.append('descripcion', this.state.entrevista);
    fromData.append('tipo', this.state.selectedOption.label);
    fromData.append('link', this.state.codigo);
    fromData.append('nidentrevista', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/editarentrevista', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      let fromData = new FormData();
      fromData.append('descripcion', this.state.entrevista);
      fromData.append('tipo', this.state.selectedOption.label);
      fromData.append('link', this.state.codigo);
      fromData.append('nidconcurso', this.state.concurso);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${concursos}` + 'private/insertarentrevista', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    this.setState({
      selectedOption: {value: "", label: datos.tipo},
      entrevista: datos.descripcion,
      codigo: datos.link,
      nidcarga: datos.nid
    })
  }

  async save(){
    if(this.state.entrevista != "" && this.state.codigo != ""){
      if(this.state.nidcarga == ""){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Entrevista" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Entrevista" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "ENTREVISTA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "link", text: "CÓDIGO DE YOUTUBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.entrevistas.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.entrevistas }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Entrevistas del Concurso</h4>
              <div className="row">
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>ENTREVISTA</ControlLabel>
                    <FormControl type="text" value={this.state.entrevista} onChange= {this.handleEntrevista}/>
                </div>
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>TIPOS DE ENTREVISTA</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposentrevistas}/>
                </div>
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>CÓDIGO DE YOTUBE</ControlLabel>
                    <FormControl type="text" value={this.state.codigo} onChange= {this.handleCodigo}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" style={{marginBottom: 18}}>
                    <Button style={{width: 150}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Entrevistas;