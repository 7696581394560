import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { style } from "variables/Variables.jsx";

import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

import routes from "routes.js";

import PageNotFound from "otros/PageNotFound.jsx";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      usuario: localStorage.getItem('nickname'),
      _notificationSystem: null,
      image: '',
      color: '',
      hasImage: null,
      fixedClasses: ""
    };
  }

  handleNotificationClick = (position, level, texto, icono) => {
    // level = "success";
    // level = "warning";
    // level = "error";
    // level = "info";
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={icono} />,
      message: (
        <div>
          Sistema de <b>Personal</b> - {texto}
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 5
    });
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          this.getBrandText() != 'NOTFOUND' ? (
            <Route
              path={prop.path}
              render={props => (
                <prop.component
                  {...props}
                  handleClick={this.handleNotificationClick}
                />
              )}
              key={key}
            />) : (<Route exact component={PageNotFound}/>)
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "NOTFOUND";
  };

  handleImageClick = (image, nidColor, nidImagen, visible) => {
    if(visible){visible = 1 }else{visible = 0}
    this.setState({ image: image });
    let fromData = new FormData();
    fromData.append('color', nidColor);
    fromData.append('imagen', nidImagen);
    fromData.append('visible', visible);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/actualizar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {})
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  };

  handleColorClick = (color, nidColor, nidImagen, visible) => {
    if(visible){visible = 1 }else{visible = 0}
    this.setState({ color: color });
    let fromData = new FormData();
    fromData.append('color', nidColor);
    fromData.append('imagen', nidImagen);
    fromData.append('visible', visible);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/actualizar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {})
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  };

  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  /*
  detailPerson() {
      let datos = [{
        "Person": "000000",
        "area": "NO DEFINIDO",
        "cargo": "NO DEFINIDO",
        "item": 1,
        "nombre": "NO DEFINIDO"
      }];
      localStorage.setItem('datos', JSON.stringify(datos))
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'personalizado/listPersonalizado?usuario=' + this.state.usuario)
      .then((res) => {
        this.setState({
          color: res.data.response[0].color,
          image: require('assets/img/' + res.data.response[0].imagen),
          hasImage: res.data.response[0].flg_visible
        })
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({
            isLoading: false
          })
        }
      });
  }
  */
  detailPerson() {
    let dni =  localStorage.getItem('dni')
    axios.get(sisperplus + 'perfil/detailperson?dni=' + dni, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('AccessToken')
      }
    }).then((response) => {
      let datos = response.data.response;
      localStorage.setItem('datos', JSON.stringify(datos))
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'personalizado/listPersonalizado?usuario=' + this.state.usuario)
      .then((res) => {
        this.setState({
          color: res.data.response[0].color,
          image: require('assets/img/' + res.data.response[0].imagen),
          hasImage: res.data.response[0].flg_visible
        })
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({
            isLoading: false
          })
        }
      });
    },(error) => {
      console.log(error)
    });
  }
  
  async componentDidMount() {
    let fromData = new FormData();
    fromData.append('color', '1');
    fromData.append('imagen', '6');
    fromData.append('visible', '1');
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/registrar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {
      this.detailPerson();
      this.setState({ _notificationSystem: this.refs.notificationSystem });
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={routes} 
        image={this.state.image}
        color={this.state.color}
        hasImage={this.state.hasImage}/>
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer />
          <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            bgHasImage={this.state["hasImage"]}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          />
        </div>
      </div>
    );
  }
}

export default Admin;
