import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import { regExpLiteral } from "@babel/types";
import Calendar from "components/Calendar/Calendar.jsx";
import moment from 'moment';
import 'moment/locale/es';

import { NumeroALetras } from "./conversor";

const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    author: {
      fontSize: 15,
      textAlign: 'center',
      marginBottom: 40,
      marginTop: 10
    },
    subtitle: {
      fontSize: 13,
      margin: 12,
    },
    subtitle2: {
        fontSize: 13,
        margin: 12,
        textAlign: 'right',
    },
    subtitle3: {
        fontSize: 13,
        margin: 12,
        textAlign: 'right',
        marginTop: 80
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginBottom: 30 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "100%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableCol: { 
        width: "30%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCol2: { 
        width: "70%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 15,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 13
      }
});

export function PdfDocument(dni, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    var recibo = row[0].recibo;
    if(recibo == ''){
       recibo = '(Se generará al guardar)';
    }
    var monto = NumeroALetras(row[0].monto);
    return (
        <Document>
        <Page style={styles.body}>
          {/* <Text style={styles.header} fixed>Elaborado por</Text> */}
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author}>Dirección General de Administración</Text>
          <Text style={styles.subtitle2}>
            Recibo N° {recibo}
          </Text>
          <Text style={styles.subtitle}>
            Yo, {row[0].datos}, identificado con DNI {dni}, recibí del Encargado del Fondo Para Pagos en Efectivo la Cantidad de 
             {' ' + monto} (S/. {row[0].monto}), por concepto de {row[0].concepto}.
          </Text>
          <Text style={styles.subtitle3}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}