import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-accessible-accordion/dist/fancy-example.css';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarFamiliar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarFamiliares',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      detalle: JSON.parse(localStorage.getItem('detalle')),
      editar: JSON.parse(localStorage.getItem('editar')),
      nidregistro: "", niddireccion: "", niddireccion2: "", selectedOption3: "", selectedOption4: "", selectedOption5: "", selectedOption6: "", selectedOption7: "",
      selectedOption8: "", selectedOption9: "", selectedOption10: "", selectedOption11: "", selectedOption12: "", selectedOption13: "", selectedOption14: "",
      selectedOption15: "", selectedOption16: "", selectedOption17: "", selectedOption18: "", selectedOption19: "", selectedOption20: "", selectedOption21: "",
      selectedOption22: "", selectedOption23: "", selectedOption24: "", selectedOption25: "", selectedOption26: "", selectedOption27: "", selectedOption28: "", 
      tipos: [], pais: [], tipodocumento: [], ocupacion: [], vinculofamiliar: [], parentesco: [], estadocivil: [], departamentos: [], provincias: [], distritos: [], 
      departamentos2: [], provincias2: [], distritos2: [], departamentos3: [], provincias3: [], distritos3: [], grado: [], tipoformacion: [], centroestudios: [], tipovia: [], tipozona: [],
      apepaterno: "", apematerno: "", primernombre: "", segundonombre: "", apecasado: "", numerodocumento: "", fechanacimiento: "", cartamedica: "", numerocontacto: "", 
      correoelectronico: "", via: "", zona: "", codigopostal: "", referencia: "", apartamento: "", interior: "", manzana: "", lote: "", kilometro: "", bloque: "", 
      via2: "", zona2: "", codigopostal2: "", referencia2: "", apartamento2: "", interior2: "", manzana2: "", lote2: "", kilometro2: "", bloque2: "",
      dependiente: "", dependienteeps: "", generapdt: "", vive: "", sexo: "", etapa: "", etapa2: "", essalud: "", essalud2: "", areatrabajador: "", trabajador: "",
      estado: 0,
      isLoading: true,
      show: true,
      archivo: "",
      archivo2: "",
      ruta: "",
      file: [],
      editable: false,
      editable2: false,
      editable3: false,
      editable4: false,
      editable5: false,
      editable6: false
    }

    this.handleAreaTrabajador = this.handleAreaTrabajador.bind(this);
    this.handleTrabajador = this.handleTrabajador.bind(this);
    this.handleApePaterno = this.handleApePaterno.bind(this);
    this.handleApeMaterno = this.handleApeMaterno.bind(this);
    this.handlePrimerNombre = this.handlePrimerNombre.bind(this);
    this.handleSegundoNombre = this.handleSegundoNombre.bind(this);
    this.handleApeCasado = this.handleApeCasado.bind(this);
    this.handleNumeroDocumento = this.handleNumeroDocumento.bind(this);
    this.handleFechaNacimiento = this.handleFechaNacimiento.bind(this);
    this.handleCartaMedica = this.handleCartaMedica.bind(this);
    this.handleNumeroContacto = this.handleNumeroContacto.bind(this);
    this.handleCorreoElectronico = this.handleCorreoElectronico.bind(this);
    this.handleVia = this.handleVia.bind(this);
    this.handleZona = this.handleZona.bind(this);
    this.handleCodigoPostal = this.handleCodigoPostal.bind(this);
    this.handleReferencia = this.handleReferencia.bind(this);
    this.handleApartamento = this.handleApartamento.bind(this);
    this.handleInterior = this.handleInterior.bind(this);
    this.handleManzana = this.handleManzana.bind(this);
    this.handleLote = this.handleLote.bind(this);
    this.handleKilometro = this.handleKilometro.bind(this);
    this.handleBloque = this.handleBloque.bind(this);
    this.handleEtapa = this.handleEtapa.bind(this);
    this.handleVia2 = this.handleVia2.bind(this);
    this.handleZona2 = this.handleZona2.bind(this);
    this.handleCodigoPostal2 = this.handleCodigoPostal2.bind(this);
    this.handleReferencia2 = this.handleReferencia2.bind(this);
    this.handleApartamento2 = this.handleApartamento2.bind(this);
    this.handleInterior2 = this.handleInterior2.bind(this);
    this.handleManzana2 = this.handleManzana2.bind(this);
    this.handleLote2 = this.handleLote2.bind(this);
    this.handleKilometro2 = this.handleKilometro2.bind(this);
    this.handleBloque2 = this.handleBloque2.bind(this);
    this.handleEtapa2 = this.handleEtapa2.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeValue2 = this.onChangeValue2.bind(this);
    this.onChangeValue3 = this.onChangeValue3.bind(this);
    this.onChangeValue4 = this.onChangeValue4.bind(this);
    this.onChangeValue5 = this.onChangeValue5.bind(this);
    this.onChangeValue6 = this.onChangeValue6.bind(this);
    this.onChangeValue7 = this.onChangeValue7.bind(this);
  }

    handleAreaTrabajador(event) {this.setState({areatrabajador: event.target.value});}
    handleTrabajador(event) {this.setState({trabajador: event.target.value});}
    handleApePaterno(event) {this.setState({apepaterno: event.target.value});}
    handleApeMaterno(event) {this.setState({apematerno: event.target.value});}
    handlePrimerNombre(event) {this.setState({primernombre: event.target.value});}
    handleSegundoNombre(event) {this.setState({segundonombre: event.target.value});}
    handleApeCasado(event) {this.setState({apecasado: event.target.value});}
    handleNumeroDocumento(event) {this.setState({numerodocumento: event.target.value});}
    handleFechaNacimiento(event) {this.setState({fechanacimiento: event.target.value});}
    handleCartaMedica(event) {this.setState({cartamedica: event.target.value});}
    handleNumeroContacto(event) {this.setState({numerocontacto: event.target.value});}
    handleCorreoElectronico(event) {this.setState({correoelectronico: event.target.value});}
    handleVia(event) {this.setState({via: event.target.value});}
    handleZona(event) {this.setState({zona: event.target.value});}
    handleCodigoPostal(event) {this.setState({codigopostal: event.target.value});}
    handleReferencia(event) {this.setState({referencia: event.target.value});}
    handleApartamento(event) {this.setState({apartamento: event.target.value});}
    handleInterior(event) {this.setState({interior: event.target.value});}
    handleManzana(event) {this.setState({manzana: event.target.value});}
    handleLote(event) {this.setState({lote: event.target.value});}
    handleKilometro(event) {this.setState({kilometro: event.target.value});}
    handleBloque(event) {this.setState({bloque: event.target.value});}
    handleEtapa(event) {this.setState({etapa: event.target.value});}
    handleVia2(event) {this.setState({via2: event.target.value});}
    handleZona2(event) {this.setState({zona2: event.target.value});}
    handleCodigoPostal2(event) {this.setState({codigopostal2: event.target.value});}
    handleReferencia2(event) {this.setState({referencia2: event.target.value});}
    handleApartamento2(event) {this.setState({apartamento2: event.target.value});}
    handleInterior2(event) {this.setState({interior2: event.target.value});}
    handleManzana2(event) {this.setState({manzana2: event.target.value});}
    handleLote2(event) {this.setState({lote2: event.target.value});}
    handleKilometro2(event) {this.setState({kilometro2: event.target.value});}
    handleBloque2(event) {this.setState({bloque2: event.target.value});}
    handleEtapa2(event) {this.setState({etapa2: event.target.value});}
    handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },) };
    handleChange4 = selectedOption4 => {this.setState({ selectedOption4 },) };
    handleChange5 = selectedOption5 => {this.setState({ selectedOption5 },) };
    handleChange6 = selectedOption6 => {this.setState({ selectedOption6 },) };
    handleChange7 = selectedOption7 => {this.setState({ selectedOption7 },) };
    handleChange8 = selectedOption8 => {this.setState({ selectedOption8 },) };
    handleChange9 = selectedOption9 => {this.setState({ selectedOption9 },) };
    handleChange10 = selectedOption10 => {this.setState({ selectedOption10 },() => this.departamentos());};
    handleChange11 = selectedOption11 => {this.setState({ selectedOption11 },() => this.provincias());};
    handleChange12 = selectedOption12 => {this.setState({ selectedOption12 },() => this.distritos());};
    handleChange13 = selectedOption13 => {this.setState({ selectedOption13 },) };
    handleChange14 = selectedOption14 => {this.setState({ selectedOption14 },) };
    handleChange15 = selectedOption15 => {this.setState({ selectedOption15 },) };
    handleChange16 = selectedOption16 => {this.setState({ selectedOption16 },) };
    handleChange17 = selectedOption17 => {this.setState({ selectedOption17 },() => this.departamentos2());};
    handleChange18 = selectedOption18 => {this.setState({ selectedOption18 },() => this.provincias2());};
    handleChange19 = selectedOption19 => {this.setState({ selectedOption19 },() => this.distritos2());};
    handleChange20 = selectedOption20 => {this.setState({ selectedOption20 },) };
    handleChange21 = selectedOption21 => {this.setState({ selectedOption21 },) };
    handleChange22 = selectedOption22 => {this.setState({ selectedOption22 },) };
    handleChange23 = selectedOption23 => {this.setState({ selectedOption23 },() => this.departamentos3());};
    handleChange24 = selectedOption24 => {this.setState({ selectedOption24 },() => this.provincias4());};
    handleChange25 = selectedOption25 => {this.setState({ selectedOption25 },() => this.distritos5());};
    handleChange26 = selectedOption26 => {this.setState({ selectedOption26 },) };
    handleChange27 = selectedOption27 => {this.setState({ selectedOption27 },) };
    handleChange28 = selectedOption28 => {this.setState({ selectedOption28 },) };
    onChangeValue(event) {this.setState({dependiente: event.target.value});}
    onChangeValue2(event) {this.setState({dependienteeps: event.target.value});}
    onChangeValue3(event) {this.setState({generapdt: event.target.value});}
    onChangeValue4(event) {this.setState({vive: event.target.value});}
    onChangeValue5(event) {this.setState({sexo: event.target.value});}
    onChangeValue6(event) {this.setState({essalud: event.target.value});}
    onChangeValue7(event) {this.setState({essalud2: event.target.value});}

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(this.state.detalle !== null){
        localStorage.removeItem('detalle')
        this.setState({editable: false, editable2: false, editable3: false, editable4: false, editable5: false, editable6: false})
        this.obtenerDatosFamiliar();
    }else if(this.state.editar !== null){    
        localStorage.removeItem('editar')
        this.setState({editable: true, editable2: true, editable3: true, editable4: true, editable5: true, editable6: true})
        this.obtenerDatosFamiliar();   
    }else{
      this.props.history.push('/administrarFamiliares');
    }
  }

  async obtenerDatosFamiliar(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/familiares/obtenerfamiliar?nidregistro=' + this.state.detalle.nid)
    .then((res) => {
        let lista = res.data.response;
        if(lista != null){
          this.setState({
            nidregistro: lista.nid,
            apepaterno: lista.apematerno,
            apematerno: lista.apematerno,
            primernombre: lista.primernombre,
            segundonombre: lista.segundonombre,
            apecasado: lista.apecasado,
            selectedOption3: {value: lista.nidpaisemisor, label: lista.paisemisor},
            selectedOption4: {value: lista.nidtipodocumento, label: lista.tipodocumento},
            selectedOption5: {value: lista.nidocupacion, label: lista.ocupacion},
            numerodocumento: lista.numerodocumento,
            selectedOption6: {value: lista.nidvinculofamiliar, label: lista.vinculofamiliar},
            selectedOption7: {value: lista.nidparentesco, label: lista.parentesco},
            selectedOption8: {value: lista.nidestadocivil, label: lista.estadocivil},
            dependiente: lista.dependiente,
            dependienteeps: lista.dependienteeps,
            generapdt: lista.generapdt,
            vive: lista.vive,
            selectedOption9: {value: lista.nidpaisnacionalidad, label: lista.paisnacionalidad},
            fechanacimiento: Calendar.calendario11(lista.fechanacimiento),
            cartamedica: lista.cartamedica,
            sexo: lista.sexo,
            selectedOption10: {value: lista.nidpaisnacimiento, label: lista.paisnacimiento},
            selectedOption11: {value: lista.niddepartamento, label: lista.departamento},
            selectedOption12: {value: lista.nidprovincia, label: lista.provincia},
            selectedOption13: {value: lista.niddistrito, label: lista.distrito},
            numerocontacto: lista.numerocontacto,
            correoelectronico: this.state.correoelectronico,
            selectedOption14: {value: lista.nidgradoacademico, label: lista.gradoacademico},
            selectedOption15: {value: lista.nidtipoformacion, label: lista.tipoformacion},
            selectedOption16: {value: lista.nidcentroestudios, label: lista.centroestudios},
            areatrabajador: lista.areatrabajador,
            trabajador: lista.trabajador
          });
          this.listadirecciones();
        }else{
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Error al obtener informacion!", "pe-7s-info")
        }
      })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listadirecciones(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/direcciones/obtenerdirecciones?nidregistro=' + this.state.detalle.nid)
    .then((res) => {
        let lista = res.data.response;
        if(lista != null){
            lista.map((data) => {
                if(data.item == 1){
                    this.setState({
                        niddireccion: data.nid,
                        selectedOption17: {value: data.niddirpais, label: data.dirpais},
                        selectedOption18: {value: data.niddirdepartamento, label: data.dirdepartamento},
                        selectedOption19: {value: data.niddirprovincia, label: data.dirprovincia},
                        selectedOption20: {value: data.niddirdistrito, label: data.dirdistrito},
                        selectedOption21: {value: data.nidtipovia, label: data.tipovia},
                        numerovia: data.via,
                        selectedOption22: {value: data.nidtipozona, label: data.tipozona},
                        numerozona: data.zona,
                        codigopostal: data.codigopostal,
                        referencia: data.referencia,
                        apartamento: data.apartamento,
                        interior: data.interior,
                        manzana: data.manzana,
                        lote: data.lote,
                        kilometro: data.kilometro,
                        bloque: data.bloque,
                        etapa: data.etapa,
                        essalud: data.essalud
                    });
                }else{
                    this.setState({
                        niddireccion2: data.nid,
                        selectedOption23: {value: data.niddirpais, label: data.dirpais},
                        selectedOption24: {value: data.niddirdepartamento, label: data.dirdepartamento},
                        selectedOption25: {value: data.niddirprovincia, label: data.dirprovincia},
                        selectedOption26: {value: data.niddirdistrito, label: data.dirdistrito},
                        selectedOption27: {value: data.nidtipovia, label: data.tipovia},
                        numerovia2: data.via,
                        selectedOption28: {value: data.nidtipozona, label: data.tipozona},
                        numerozona2: data.zona,
                        codigopostal2: data.codigopostal,
                        referencia2: data.referencia,
                        apartamento2: data.apartamento,
                        interior2: data.interior,
                        manzana2: data.manzana,
                        lote2: data.lote,
                        kilometro2: data.kilometro,
                        bloque2: data.bloque,
                        etapa2: data.etapa,
                        essalud2: data.essalud,
                        contador: 1
                    });
                }
            })
          this.listatipos();
        }else{
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Error al obtener informacion!", "pe-7s-info")
        }
      })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listatipos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listartiposfamiliar')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({tipos: lista2, isLoading: false})
      }else{
        this.setState({tipos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar listado!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async departamentos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardepartamentos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({departamentos: lista2})
      }else{
        this.setState({departamentos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar departamentos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async provincias() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listarprovincias')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({provincias: lista2})
      }else{
        this.setState({provincias: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar provincias!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async distritos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardistritos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({distritos: lista2})
      }else{
        this.setState({distritos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar distritos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async departamentos2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardepartamentos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({departamentos2: lista2})
      }else{
        this.setState({departamentos2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar departamentos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async provincias2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listarprovincias')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({provincias2: lista2})
      }else{
        this.setState({provincias2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar provincias!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async distritos2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardistritos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({distritos2: lista2})
      }else{
        this.setState({distritos2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar distritos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async departamentos3() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardepartamentos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({departamentos3: lista2})
      }else{
        this.setState({departamentos3: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar departamentos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async provincias3() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listarprovincias')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({provincias3: lista2})
      }else{
        this.setState({provincias3: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar provincias!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async distritos3() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardistritos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({distritos3: lista2})
      }else{
        this.setState({distritos3: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar distritos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async validar(){
    var dependiente = ""; var dependienteeps = ""; var generapdt = ""; var vive = "";
    if(this.state.dependiente == ''){
        dependiente = "0";
    }else{
        dependiente = this.state.dependiente;
    }
    if(this.state.dependienteeps == ''){
        dependienteeps = "0";
    }else{
        dependienteeps = this.state.dependienteeps;
    }
    if(this.state.generapdt == ''){
        generapdt = "0";
    }else{
        generapdt = this.state.generapdt;
    }
    if(this.state.vive == ''){
        vive = "0";
    }else{
        vive = this.state.vive;
    }
    this.verificar(dependiente,dependienteeps,generapdt,vive)
  }

  async validar2(){
    var cartamedica = ""; 
    if(this.state.cartamedica == ''){
        cartamedica = "--";
    }else{
      cartamedica = this.state.cartamedica;
    }
    this.verificar2(cartamedica)
  }

  async validar3(){
    this.verificar3()
  }

  async validar4(){
    this.verificar4()
  }

  async validar5(){
    var essalud = ""; var apartamento = ""; var interior = ""; var manzana = ""; var lote = ""; var kilometro = ""; var bloque = ""; var etapa = "";
    if(this.state.essalud == ''){
        essalud = "0";
    }else{
      essalud = this.state.essalud;
    }
    if(this.state.apartamento == ''){
        apartamento = "--";
    }else{
      apartamento = this.state.apartamento;
    }
    if(this.state.interior == ''){
        interior = "--";
    }else{
      interior = this.state.interior;
    }
    if(this.state.manzana == ''){
        manzana = "--";
    }else{
      manzana = this.state.manzana;
    }
    if(this.state.lote == ''){
        lote = "--";
    }else{
      lote = this.state.lote;
    }
    if(this.state.kilometro == ''){
        kilometro = "--";
    }else{
      kilometro = this.state.kilometro;
    }
    if(this.state.bloque == ''){
        bloque = "--";
    }else{
      bloque = this.state.bloque;
    }
    if(this.state.etapa == ''){
        etapa = "--";
    }else{
        etapa = this.state.etapa;
    }
    this.verificar5(essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
  }

  async validar6(){
    var essalud = ""; var apartamento = ""; var interior = ""; var manzana = ""; var lote = ""; var kilometro = ""; var bloque = ""; var etapa = "";
    if(this.state.essalud2 == ''){
        essalud = "0";
    }else{
      essalud = this.state.essalud2;
    }
    if(this.state.apartamento2 == ''){
        apartamento = "--";
    }else{
      apartamento = this.state.apartamento2;
    }
    if(this.state.interior2 == ''){
        interior = "--";
    }else{
      interior = this.state.interior2;
    }
    if(this.state.manzana2 == ''){
        manzana = "--";
    }else{
      manzana = this.state.manzana2;
    }
    if(this.state.lote2 == ''){
        lote = "--";
    }else{
      lote = this.state.lote2;
    }
    if(this.state.kilometro2 == ''){
        kilometro = "--";
    }else{
      kilometro = this.state.kilometro2;
    }
    if(this.state.bloque2 == ''){
        bloque = "--";
    }else{
      bloque = this.state.bloque2;
    }
    if(this.state.etapa2 == ''){
        etapa = "--";
    }else{
        etapa = this.state.etapa2;
    }
    this.verificar6(essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
  }

  async verificar(dependiente,dependienteeps,generapdt,vive){
    if(this.state.apepaterno != '' || this.state.apematerno != '' || this.state.primernombre != '' || this.state.segundonombre != '' || this.state.apecasado != ''
    || this.state.numerodocumento != ''){
      this.actualizar(dependiente,dependienteeps,generapdt,vive);
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
    }
  }

  async verificar2(cartamedica){
    if(this.state.fechanacimiento != '' || this.state.sexo != ''){
      this.actualizar2(cartamedica);
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
    }
  }

  async verificar3(){
    if(this.state.numerocontacto != '' || this.state.correoelectronico != ''){
      this.actualizar3();
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
    }
  }

  async verificar4(){
    this.actualizar4();
  }

  async verificar5(essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
    if(this.state.via != '' || this.state.zona != '' || this.state.codigopostal != '' || this.state.referencia != ''){
      var via = this.state.via; var zona = this.state.zona; var codigopostal = this.state.codigopostal; var referencia = this.state.referencia;
      this.actualizar5(0, via, zona, codigopostal, referencia, essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa);
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
    }
  }

  async verificar6(essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
    if(this.state.via2 != '' || this.state.zona2 != '' || this.state.codigopostal2 != '' || this.state.referencia2 != ''){
      var via = this.state.via2; var zona = this.state.zona2; var codigopostal = this.state.codigopostal2; var referencia = this.state.referencia2;
      this.actualizar5(1, via, zona, codigopostal, referencia, essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa);
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
    }
  }

  async actualizar(dependiente,dependienteeps,generapdt,vive){  
    this.setState({isLoading: true}) 
    let fromData = new FormData();
    fromData.append('apepaterno', this.state.apematerno);
    fromData.append('apematerno', this.state.apematerno);
    fromData.append('primernombre', this.state.primernombre);
    fromData.append('segundonombre', this.state.segundonombre);
    fromData.append('apecasado', this.state.apecasado);
    fromData.append('paisemisor', this.state.selectedOption3.value);
    fromData.append('tipodocumento', this.state.selectedOption4.value);
    fromData.append('numerodocumento', this.state.numerodocumento);
    fromData.append('ocupacion', this.state.selectedOption5.value);
    fromData.append('vinculofamiliar', this.state.selectedOption6.value);
    fromData.append('parentesco', this.state.selectedOption7.value);
    fromData.append('estadocivil', this.state.selectedOption8.value);
    fromData.append('dependiente', dependiente);
    fromData.append('dependienteeps', dependienteeps);
    fromData.append('generapdt', generapdt);
    fromData.append('vive', vive);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/familiares/editarfamiliar', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable: false, isLoading: false})
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar2(cartamedica){   
    this.setState({isLoading: true}) 
    let fromData = new FormData();
    fromData.append('paisnacionalidad', this.state.selectedOption9.value);
    fromData.append('fechanacimiento', this.state.fechanacimiento);
    fromData.append('cartamedica', cartamedica);
    fromData.append('sexo', this.state.sexo);
    fromData.append('paisnacimiento', this.state.selectedOption10.value);
    fromData.append('departamento', this.state.selectedOption11.value);
    fromData.append('provincia', this.state.selectedOption12.value);
    fromData.append('distrito', this.state.selectedOption13.value);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/familiares/editarfamiliar2', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable2: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar3(){   
    let fromData = new FormData();
    fromData.append('numerocontacto', this.state.numerocontacto);
    fromData.append('correoelectronico', this.state.correoelectronico);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/familiares/editarfamiliar3', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable3: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar4(){   
    let fromData = new FormData();
    fromData.append('gradoacademico', this.state.selectedOption14.value);
    fromData.append('tipoformacion', this.state.selectedOption15.value);
    fromData.append('centroestudios', this.state.selectedOption16.value);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/familiares/editarfamiliar4', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable4: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar5(param, via, zona, codigopostal, referencia, essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){ 
    var dirpais = "";
    var dirdepartamento = "";
    var dirprovincia = "";
    var dirdistrito = "";
    var tipovia = "";
    var tipozona = "";
    var niddireccion = ""
    if(param == 0){
        dirpais = this.state.selectedOption17.value;
        dirdepartamento = this.state.selectedOption18.value;
        dirprovincia = this.state.selectedOption19.value;
        dirdistrito = this.state.selectedOption20.value;
        tipovia = this.state.selectedOption21.value;
        tipozona = this.state.selectedOption22.value;
        niddireccion = this.state.niddireccion;
        this.actualizardireccion(param, niddireccion, dirpais, dirdepartamento, dirprovincia, dirdistrito, tipovia, tipozona, via, zona, codigopostal, referencia, 
            essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
    }else{
        dirpais = this.state.selectedOption23.value;
        dirdepartamento = this.state.selectedOption24.value;
        dirprovincia = this.state.selectedOption25.value;
        dirdistrito = this.state.selectedOption26.value;
        tipovia = this.state.selectedOption27.value;
        tipozona = this.state.selectedOption28.value;
        niddireccion = this.state.niddireccion2;
        if(niddireccion == ""){
            this.insertardireccion(dirpais, dirdepartamento, dirprovincia, dirdistrito, tipovia, tipozona, via, zona, codigopostal, referencia, 
                essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
        }else{
            this.actualizardireccion(param, niddireccion, dirpais, dirdepartamento, dirprovincia, dirdistrito, tipovia, tipozona, via, zona, codigopostal, referencia, 
                essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
        }
    }
  }

  async actualizardireccion(param, niddireccion, dirpais, dirdepartamento, dirprovincia, dirdistrito, tipovia, tipozona, via, zona, codigopostal, referencia, 
    essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
    let fromData = new FormData();
    fromData.append('dirpais', dirpais);
    fromData.append('dirdepartamento', dirdepartamento);
    fromData.append('dirprovincia', dirprovincia);
    fromData.append('dirdistrito', dirdistrito);
    fromData.append('tipovia', tipovia);
    fromData.append('numerovia', via);
    fromData.append('tipozona', tipozona);
    fromData.append('numerozona', zona);
    fromData.append('codigopostal', codigopostal);
    fromData.append('referencia', referencia);
    fromData.append('apartamento', apartamento);
    fromData.append('interior', interior);
    fromData.append('manzana', manzana);
    fromData.append('lote', lote);
    fromData.append('kilometro', kilometro);
    fromData.append('bloque', bloque);
    fromData.append('etapa', etapa);
    fromData.append('essalud', essalud);
    fromData.append('niddireccion', niddireccion);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/direcciones/editardireccion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          if(param == 0){
            this.setState({editable5: false, isLoading: false})
          }else{
            this.setState({editable6: false, isLoading: false})
          }
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async insertardireccion(dirpais, dirdepartamento, dirprovincia, dirdistrito, tipovia, tipozona, via, zona, codigopostal, referencia, 
    essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
    let fromData = new FormData();
    fromData.append('dirpais', dirpais);
    fromData.append('dirdepartamento', dirdepartamento);
    fromData.append('dirprovincia', dirprovincia);
    fromData.append('dirdistrito', dirdistrito);
    fromData.append('tipovia', tipovia);
    fromData.append('numerovia', via);
    fromData.append('tipozona', tipozona);
    fromData.append('numerozona', zona);
    fromData.append('codigopostal', codigopostal);
    fromData.append('referencia', referencia);
    fromData.append('apartamento', apartamento);
    fromData.append('interior', interior);
    fromData.append('manzana', manzana);
    fromData.append('lote', lote);
    fromData.append('kilometro', kilometro);
    fromData.append('bloque', bloque);
    fromData.append('etapa', etapa);
    fromData.append('essalud', essalud);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/direcciones/insertardireccion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro insertado correctamente!", "pe-7s-close")
          this.setState({editable6: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/administrarFamiliares');
  }

  async editar(){
      if(this.state.editable == false){
        this.setState({editable: true});
      }else{
        this.setState({editable: false});
      }
  }

  async editar2(){
    if(this.state.editable2 == false){
      this.setState({editable2: true});
    }else{
      this.setState({editable2: false});
    }
  }

  async editar3(){
    if(this.state.editable3 == false){
      this.setState({editable3: true});
    }else{
      this.setState({editable3: false});
    }
  }

  async editar4(){
    if(this.state.editable4 == false){
      this.setState({editable4: true});
    }else{
      this.setState({editable4: false});
    }
  }

  async editar5(){
    if(this.state.editable5 == false){
      this.setState({editable5: true});
    }else{
      this.setState({editable5: false});
    }
  }

  async editar6(){
    if(this.state.editable6 == false){
      this.setState({editable6: true});
    }else{
      this.setState({editable6: false});
    }
  }

  render() {
    const { selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7, 
        selectedOption8, selectedOption9, selectedOption10, selectedOption11, selectedOption12, selectedOption13, selectedOption14,
        selectedOption15, selectedOption16, selectedOption17, selectedOption18, selectedOption19, selectedOption20, selectedOption21, 
        selectedOption22, selectedOption23, selectedOption24, selectedOption25, selectedOption26, selectedOption27, selectedOption28 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Detalle del Registro Familiar</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <FormControl type="text" value={this.state.areatrabajador} onChange= {this.handleAreaTrabajador} disabled/>

                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                    <FormControl type="text" value={this.state.trabajador} onChange= {this.handleTrabajador} disabled/>

                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <Tabs style={{marginTop: 30}}>
                        <TabList>
                        <Tab>INFORMACIÓN GENERAL</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO PATERNO</ControlLabel>
                                <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO MATERNO</ControlLabel>
                                <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PRIMER NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.primernombre} onChange= {this.handlePrimerNombre} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>SEGUNDO NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.segundonombre} onChange= {this.handleSegundoNombre} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO CASADO(A)</ControlLabel>
                                <FormControl type="text" value={this.state.apecasado} onChange= {this.handleApeCasado} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS EMISOR</ControlLabel>
                                <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.pais} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                                <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.tipodocumento} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE DOCUMENTO</ControlLabel>
                                <FormControl type="text" value={this.state.numerodocumento} onChange= {this.handleNumeroDocumento} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>OCUPACIÓN</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.ocupacion} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>VÍNCULO FAMILIAR</ControlLabel>
                                <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.vinculofamiliar} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PARENTESCO</ControlLabel>
                                <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.parentesco} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>ESTADO CIVIL</ControlLabel>
                                <Select value={selectedOption8} onChange={this.handleChange8} options={this.state.estadocivil} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                                <ControlLabel>DEPENDIENTE</ControlLabel><br></br>
                                <input type="radio" value="0" name="dependiente" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="dependiente" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue2}>
                                <ControlLabel>DEPENDIENTE EPS</ControlLabel><br></br>
                                <input type="radio" value="0" name="dependienteeps" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="dependienteeps" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue3}>
                                <ControlLabel>GENERA PDT</ControlLabel><br></br>
                                <input type="radio" value="0" name="generapdt" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="generapdt" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue4}>
                                <ControlLabel>¿VIVE?</ControlLabel><br></br>
                                <input type="radio" value="0" name="vive" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="vive" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                        </div>
                        {this.state.editable == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS DE NACIMIENTO</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS NACIONALIDAD</ControlLabel>
                                <Select value={selectedOption9} onChange={this.handleChange9} options={this.state.pais} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE NACIMIENTO</ControlLabel>
                                <FormControl type="date" value={this.state.fechanacimiento} onChange= {this.handleFechaNacimiento} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CARTA MÉDICA</ControlLabel>
                                <FormControl type="text" value={this.state.cartamedica} onChange= {this.handleCartaMedica} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue5}>
                                <ControlLabel>SEXO</ControlLabel><br></br>
                                <input type="radio" value="0" name="sexo" style={{marginRight: 5}}/><label style={{marginRight: 10}}>MASCULINO</label>
                                <input type="radio" value="1" name="sexo" style={{marginRight: 5}}/><label style={{marginRight: 10}}>FEMENINO</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS NACIMIENTO</ControlLabel>
                                <Select value={selectedOption10} onChange={this.handleChange10} options={this.state.pais} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DEPARTAMENTO</ControlLabel>
                                <Select value={selectedOption11} onChange={this.handleChange11} options={this.state.departamentos} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PROVINCIA</ControlLabel>
                                <Select value={selectedOption12} onChange={this.handleChange12} options={this.state.provincias} disabled={this.state.editable2}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DISTRITO</ControlLabel>
                                <Select value={selectedOption13} onChange={this.handleChange13} options={this.state.distritos} disabled={this.state.editable2}/>
                            </div>
                        </div>
                        {this.state.editable2 == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar2() }}>Editar Registro</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar2() }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar2() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS DE CONTACTO</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>NÚMERO DE CONTACTO</ControlLabel>
                                <FormControl type="text" value={this.state.numerocontacto} onChange= {this.handleNumeroContacto} disabled={this.state.editable3}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>CORREO ELECTRÓNICO</ControlLabel>
                                <FormControl type="text" value={this.state.correoelectronico} onChange= {this.handleCorreoElectronico} disabled={this.state.editable3}/>
                            </div>
                        </div>
                        {this.state.editable3 == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar3() }}>Editar Registro</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar3() }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar3() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS ACADÉMICOS</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>GRADO ACADÉMICO</ControlLabel>
                                <Select value={selectedOption14} onChange={this.handleChange14} options={this.state.grado} disabled={this.state.editable4}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE FORMACIÓN</ControlLabel>
                                <Select value={selectedOption15} onChange={this.handleChange15} options={this.state.tipoformacion} disabled={this.state.editable4}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption16} onChange={this.handleChange16} options={this.state.centroestudios} disabled={this.state.editable4}/>
                            </div>
                        </div>
                        {this.state.editable4 == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar4() }}>Editar Registro</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar4() }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar4() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DIRECCIONES</Tab>
                        </TabList>
                        <TabPanel>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Primera Dirección:
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PAÍS</ControlLabel>
                                        <Select value={selectedOption17} onChange={this.handleChange17} options={this.state.pais} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DEPARTAMENTO</ControlLabel>
                                        <Select value={selectedOption18} onChange={this.handleChange18} options={this.state.departamentos2} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PROVINCIA</ControlLabel>
                                        <Select value={selectedOption19} onChange={this.handleChange19} options={this.state.provincias2} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DISTRITO</ControlLabel>
                                        <Select value={selectedOption20} onChange={this.handleChange20} options={this.state.distritos2} disabled={this.state.editable5}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE VÍA</ControlLabel>
                                        <Select value={selectedOption21} onChange={this.handleChange21} options={this.state.tipovia} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE VÍA</ControlLabel>
                                        <FormControl type="text" value={this.state.via} onChange= {this.handleVia} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE ZONA</ControlLabel>
                                        <Select value={selectedOption22} onChange={this.handleChange22} options={this.state.tipozona} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE ZONA</ControlLabel>
                                        <FormControl type="text" value={this.state.zona} onChange= {this.handleZona} disabled={this.state.editable5}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>CÓDIGO POSTAL</ControlLabel>
                                        <FormControl type="text" value={this.state.codigopostal} onChange= {this.handleCodigoPostal} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-9" style={{marginBottom: 18}}>
                                        <ControlLabel>REFERENCIA</ControlLabel>
                                        <FormControl type="text" value={this.state.referencia} onChange= {this.handleReferencia} disabled={this.state.editable5}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE DEPARTAMENTO</ControlLabel>
                                        <FormControl type="text" value={this.state.apartamento} onChange= {this.handleApartamento} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>INTERIOR</ControlLabel>
                                        <FormControl type="text" value={this.state.interior} onChange= {this.handleInterior} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>MANZANA</ControlLabel>
                                        <FormControl type="text" value={this.state.manzana} onChange= {this.handleManzana} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>LOTE</ControlLabel>
                                        <FormControl type="text" value={this.state.lote} onChange= {this.handleLote} disabled={this.state.editable5}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>KILOMETRO</ControlLabel>
                                        <FormControl type="text" value={this.state.kilometro} onChange= {this.handleKilometro} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>BLOQUE</ControlLabel>
                                        <FormControl type="text" value={this.state.bloque} onChange= {this.handleBloque} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>ETAPA</ControlLabel>
                                        <FormControl type="text" value={this.state.etapa} onChange= {this.handleEtapa} disabled={this.state.editable5}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue6}>
                                        <ControlLabel>ATENCIÓN ESSALUD</ControlLabel><br></br>
                                        <input type="radio" value="0" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                        <input type="radio" value="1" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                                    </div>
                                </div>
                                {this.state.editable5 == false ? (
                                <div>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar5() }}>Editar Registro</Button>
                                </div>
                                ) : (
                                <div>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar5() }}>Actualizar Registro</Button>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar5() }}>Cancelar Edición</Button>
                                </div>
                                )}
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Segunda Dirección:
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PAÍS</ControlLabel>
                                        <Select value={selectedOption23} onChange={this.handleChange23} options={this.state.pais} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DEPARTAMENTO</ControlLabel>
                                        <Select value={selectedOption24} onChange={this.handleChange24} options={this.state.departamentos3} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PROVINCIA</ControlLabel>
                                        <Select value={selectedOption25} onChange={this.handleChange25} options={this.state.provincias3} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DISTRITO</ControlLabel>
                                        <Select value={selectedOption26} onChange={this.handleChange26} options={this.state.distritos3} disabled={this.state.editable6}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE VÍA</ControlLabel>
                                        <Select value={selectedOption27} onChange={this.handleChange27} options={this.state.tipovia} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE VÍA</ControlLabel>
                                        <FormControl type="text" value={this.state.via2} onChange= {this.handleVia2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE ZONA</ControlLabel>
                                        <Select value={selectedOption28} onChange={this.handleChange28} options={this.state.tipozona} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE ZONA</ControlLabel>
                                        <FormControl type="text" value={this.state.zona2} onChange= {this.handleZona2} disabled={this.state.editable6}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>CÓDIGO POSTAL</ControlLabel>
                                        <FormControl type="text" value={this.state.codigopostal2} onChange= {this.handleCodigoPostal2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-9" style={{marginBottom: 18}}>
                                        <ControlLabel>REFERENCIA</ControlLabel>
                                        <FormControl type="text" value={this.state.referencia2} onChange= {this.handleReferencia2} disabled={this.state.editable6}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE DEPARTAMENTO</ControlLabel>
                                        <FormControl type="text" value={this.state.apartamento2} onChange= {this.handleApartamento2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>INTERIOR</ControlLabel>
                                        <FormControl type="text" value={this.state.interior2} onChange= {this.handleInterior2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>MANZANA</ControlLabel>
                                        <FormControl type="text" value={this.state.manzana2} onChange= {this.handleManzana2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>LOTE</ControlLabel>
                                        <FormControl type="text" value={this.state.lote2} onChange= {this.handleLote2} disabled={this.state.editable6}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>KILOMETRO</ControlLabel>
                                        <FormControl type="text" value={this.state.kilometro2} onChange= {this.handleKilometro2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>BLOQUE</ControlLabel>
                                        <FormControl type="text" value={this.state.bloque2} onChange= {this.handleBloque2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>ETAPA</ControlLabel>
                                        <FormControl type="text" value={this.state.etapa2} onChange= {this.handleEtapa2} disabled={this.state.editable6}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue7}>
                                        <ControlLabel>ATENCIÓN ESSALUD</ControlLabel><br></br>
                                        <input type="radio" value="0" name="essalud2" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                        <input type="radio" value="1" name="essalud2" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                                    </div>
                                </div>
                                {this.state.editable6 == false ? (
                                <div>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar6() }}>Editar Registro</Button>
                                </div>
                                ) : (
                                <div>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar6() }}>Actualizar Registro</Button>
                                <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar6() }}>Cancelar Edición</Button>
                                </div>
                                )}
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        </TabPanel>
                    </Tabs>
                    </div>
                 )}
                 <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar a Listado</Button>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarFamiliar;