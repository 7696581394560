import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';

import { validator } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Colaboradores extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrador',
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      area: this.props.location.state.response.descripcion,
      id: this.props.location.state.response.id,
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      personal: [],
      isLoading: false,
      totaltempranez: '',
      totaltardanzas: '',
      totalfaltas: '',
      fechaini: Calendar.calendario10(new Date().setDate(new Date().getDate() -7)),
      fechafin: moment(new Date()).format('YYYY-MM-DD')
    }
    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
    this.GetActionFormat= this.GetActionFormat.bind(this);
  }

  handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
  }
  handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.cargaView();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  cambioFormat2(fecha){
    var dia = fecha.substr(0,2);
    var mes = fecha.substr(3,2);
    var anio = fecha.substr(6,4);
    var newfecha = mes + '/' + dia + '/' + anio
    return newfecha
  }

  async cargaView(){
    if(this.state.fechaini != '' && this.state.fechafin != ''){
      this.setState({isLoading: true})
      let cont = 1;  
      let fechainicial = this.cambioFormat(this.state.fechaini)
      let fechafinal = this.cambioFormat(this.state.fechafin)
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'administrador/listaPersonas?id=' + this.state.id)
      .then((res) => {
        let lista = res.data.response;
        let lista2 = [];
        let totalfaltas = 0;
        let totaltardanzas = 0;
        let totaltempranez = 0;
        let contlength2 = 0;
        if(lista.length != 0){
        lista.map((data) => {
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.get(`${sisperplus}` + 'marcaciones/lista?id=' + data.Person + '&fechaini=' + fechainicial + '&fechafin=' + fechafinal)
          .then((res) => {
            let marcas = res.data.response;
            let contlength = 0;
            let faltas = 0;
            let tardanzas = 0;
            let puntual = 0;
            marcas.map((data2) => {
              let fechacalc = new Date(this.cambioFormat2(data2.fecha)).getDay()
              if(fechacalc != 6 && fechacalc != 0){
                contlength = contlength + 1
                //Calculo Faltas
                if(data2.datebegin == null){
                  faltas = faltas + 1
                }//Calculo Puntualidad
                else{
                  let hora = Calendar.calendario7(data2.datebegin)
                  if(hora.substr(0,1) == 0 && hora.substr(1,1) <= 7){
                    puntual = puntual + 1
                  }
                }
                //Calculo Tardanzas
                if(data2.late > 0){
                  tardanzas = tardanzas + 1
                }
              }
            })
            if(marcas.length != 0){
              contlength2 = contlength2 + 1
              let porcentajefaltas = Math.trunc((faltas * 100) / contlength)
              totalfaltas = totalfaltas + porcentajefaltas
              let porcentajetardanzas = Math.trunc((tardanzas * 100) / contlength)
              totaltardanzas = totaltardanzas + porcentajetardanzas
              let porcentajepuntual = Math.trunc((puntual * 100) / contlength)
              totaltempranez = totaltempranez + porcentajepuntual
              lista2.push(
                { item: contlength2,
                  Person: data.Person,
                  dni: data.dni,
                  nombre: data.nombre,
                  temprano: marcas.length == 0 ? '' : porcentajepuntual + '%',
                  tardanza: marcas.length == 0 ? '' : porcentajetardanzas + '%',
                  falta: marcas.length == 0 ? '' : porcentajefaltas + '%'
              })
            }
            if(lista.length == cont++){
              this.setState({personal: lista2, 
                            isLoading: false, 
                            totalfaltas: JSON.stringify(Math.trunc(totalfaltas / contlength2)) + '%', 
                            totaltardanzas: JSON.stringify(Math.trunc(totaltardanzas / contlength2)) + '%', 
                            totaltempranez: JSON.stringify(Math.trunc(totaltempranez / contlength2)) + '%'
                          })
            }
          })
          .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
        });
        }else{
          this.setState({personal: [], isLoading: false})
          this.props.handleClick("tl", "error", "No existen registros para mostrar!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info")
    }
  }

  async mostrar(data){
    let lista = [{Person: data.Person,
                  nombre: data.nombre,
                  fechaini: this.state.fechaini,
                  fechafin: this.state.fechafin
                }]
    this.props.history.push('/asistencias', {response: lista});
  }

  GetActionFormat(cell, row) {
    return (
          <div>
              <span data-tip="Ver Marcaciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mostrar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-clock-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
  }

  regresar(){
    this.props.history.goBack()
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, footer: 'PROMEDIO', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "dni", text: "N° DE DNI", sort: true, footer: '', filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "APELLIDOS Y NOMBRES", sort: true, footer: '', filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "temprano", text: "% DE TEMPRANEZ", sort: true, footer: this.state.totaltempranez, style: {color: 'white', backgroundColor: '#52BE80'}, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "tardanza", text: "% DE TARNDANZAS", sort: true, footer: this.state.totaltardanzas, style: {color: 'white', backgroundColor: '#EB984E'}, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "falta", text: "% DE FALTAS", sort: true, footer: this.state.totalfaltas, style: {color: 'white', backgroundColor: '#EC7063'}, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "detail", text: "ACCIONES", footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.personal.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              noDataIndication="No existen registros para mostrar"
              data={ this.state.personal }
              columns={ columns }
              filter={ filterFactory() }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Búsqueda por Fechas"
                content={
                  <div style={{marginBottom: -20}}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        {label: "FECHA DE INICIO", type: "date", bsClass: "form-control", value: this.state.fechaini, onChange: this.handleFechaIni},
                        {label: "FECHA DE TÉRMINO", type: "date", bsClass: "form-control", value: this.state.fechafin, onChange: this.handleFechaFin}
                      ]}
                    />
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.cargaView() }}>Ver Resultados</Button>
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar</Button>
                  </div>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Colaboradores: {area}</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Colaboradores);