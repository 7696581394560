import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';

import { validator } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { sisperplus } from 'store/Apis'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Administrador extends Component {

    state = {
      path: 'administrador',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      vista: [],
      isLoading: true
    }

    async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.cargaView();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listAdministrador')
    .then((res) => {
       let lista = res.data.response
       this.setState({vistas: lista, isLoading: false})
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Administrador de Recursos"
                content={
                  <div className="album py-5 bg-light">
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row">
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                      ) : (
                      this.state.vistas.map((data, i) => {
                            return (
                              <NavLink key={i} to={data.path}>
                                <div className="col-md-4" href={data.path} to={data.path}>
                                  <div className="card mb-4 shadow-sm" style={{borderRadius: 0}}>
                                    <div className={data.style} style={{borderRadius: 0}}>
                                      <p className="textDes" style={{letterSpacing: 1, color: "white"}}>{data.title}</p>
                                    </div>
                                    <div className="card-body" style={{marginTop: -20, paddingBottom: 1}}>
                                      <p className="card-text textDes" style={{marginLeft: 10, marginRight: 10, color: "grey", letterSpacing: 1}}>{data.descripcion}</p>
                                    </div>
                                  </div>
                                </div>
                              </NavLink>
                            );
                        }))}
                      </div>
                    </div>
                  </div>
                }/>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Administrador;