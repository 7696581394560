import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Asistencia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    return (
      <div className="content">
        <Tabs style={{marginTop: 10}}>
            <TabList>
                <Tab>ASISTENCIA SOCIAL</Tab>
                </TabList>
                <TabPanel>
                <div className="row" style={{marginTop: 20}}>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>CARNET DE ESSALUD</ControlLabel>
                        <FormControl type="text" value={this.state.carnet} onChange= {this.handleCarnet} disabled={this.state.editable}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                        <ControlLabel>¿TIENE APORTE ESSALUD?</ControlLabel><br></br>
                        <input type="radio" value="0" name="aporteessalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                        <input type="radio" value="1" name="aporteessalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                    </div>
                </div>
                <div className="row">
                    {this.state.editable == true ? (
                        <div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                        </div>
                        ) : (
                        <div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                        </div>
                    )}
                </div>
            </TabPanel>
        </Tabs> 
      </div>
    );
  }
}

export default Asistencia;