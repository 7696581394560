import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Bandeja extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'bandeja',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      solicitudes: [],
      isLoading: true,
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarSolicitudes();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarSolicitudes() {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'retiros/listRetiros?usuario=' + this.state.usuario)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { nid: data.nid_recibo,
                recibo: data.cnum_recibo,
                fecha: Calendar.calendario9(data.dfec_creacion),
                ruta: data.ctxt_ruta,
                archivo: data.ctxt_nomarchivo,
                archivo2: data.ctxt_newarchivo,
                monto: data.cnum_monto,
                monto2: 'S/. ' + data.cnum_monto,
                concepto: data.ctxt_concepto,
                datos: data.ctxt_nombres,
                item: data.item
            })
          });
          this.setState({solicitudes: lista, isLoading: false})
        }else{
          this.setState({solicitudes: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async nuevo(){
    this.props.history.push('/emitir');
  }

  async delete(row){
    let fromData = new FormData();
    fromData.append('nid', row.nid);
    fromData.append('monto', row.monto);
    fromData.append('concepto', row.concepto);
    fromData.append('nombres', row.datos);
    fromData.append('oldArchivo', row.archivo2);
    fromData.append('nomArchivo', row.archivo);
    fromData.append('usuario', this.state.usuario);
    fromData.append('estado', 1);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'retiros/removerRetiro', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarSolicitudes();
        this.props.handleClick("tl", "info", "Tu solicitud ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  GetActionFormat(cell, row) {
      return (
          <div>
              <span data-tip="Editar Solicitud" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Solicitud" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
  }

  async editar(row){
    localStorage.setItem('solicitud', JSON.stringify(row))
    this.props.history.push('/editarSoli');
  }

  async mostrar(data){
    let file = data.ruta;
    window.open(file)
  }

  GetActionFormat2(cell, row) {
    return (
          <div>
              <Button data-tip="Ver Archivo" bsStyle="success" type="submit" onClick={() => { this.mostrar(row) }}>Descargar<ReactTooltip /></Button>
          </div>
      );
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "datos", text: "DATOS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "recibo", text: "RECIBO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "monto2", text: "MONTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concepto", text: "CONCEPTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ruta", text: "DESCARGAR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "fecha", text: "FECHA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.solicitudes.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.solicitudes }
              columns={ columns }
              filter={ filterFactory() }
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Bandeja de Pagos en Efectivo</h4>
              <Button style={{width: 150, marginTop: 10, marginBottom: 10}} bsStyle="success" pullLeft fill type="submit" onClick={() => { this.nuevo() }}>Nueva Solicitud</Button>
              <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Las solicitudes de pagos en efectivo se encuentran en la siguiente tabla:</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Bandeja;