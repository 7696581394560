import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class InfoTrabajador extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7, 
        selectedOption8, selectedOption9, selectedOption10, selectedOption11, selectedOption12, selectedOption13 } = this.state;
    return (
      <div className="content">
        <Tabs style={{marginTop: 30}}>
        <TabList>
            <Tab>INFORMACIÓN DE TRABAJADOR</Tab>
            </TabList>
            <TabPanel>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE INGRESO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaingreso} onChange= {this.handleFechaIngreso} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE INGRESO (SECTOR PÚBLICO)</ControlLabel>
                    <FormControl type="date" value={this.state.fechaingresopublico} onChange= {this.handleFechaIngresoPublico} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>ESTADO TRABAJADOR</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.tipoestadotrabajador} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CONDICIÓN LABORAL</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipocondicionlaboral} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE VINCULO</ControlLabel>
                    <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.tipovinculolaboral} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AREÁ DE TRABAJADOR DEL VINCULO</ControlLabel>
                    <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.areas} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TRABAJADOR CON VINCULO</ControlLabel>
                    <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.trabajadores} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MODALIDAD FORMATIVA</ControlLabel>
                    <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.tipomodalidadformativa} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PAÍS</ControlLabel>
                    <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.pais} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>REGIÓN</ControlLabel>
                    <Select value={selectedOption8} onChange={this.handleChange8} options={this.state.region} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>SUB REGIÓN</ControlLabel>
                    <Select value={selectedOption9} onChange={this.handleChange9} options={this.state.regionsub} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>NIVEL REMUNERATIVO</ControlLabel>
                    <Select value={selectedOption10} onChange={this.handleChange10} options={this.state.tiponivelremunerativo} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>NIVEL CARRERA</ControlLabel>
                    <Select value={selectedOption11} onChange={this.handleChange11} options={this.state.tiponivelcarrera} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE TRABAJADOR RTPS</ControlLabel>
                    <Select value={selectedOption12} onChange={this.handleChange12} options={this.state.tipotrabajadorrtps} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>OCUPACIÓN RTPS</ControlLabel>
                    <FormControl type="text" value={this.state.ocupacionrtps} onChange= {this.handleOcupacionRtps} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO PERSONA MGRH</ControlLabel>
                    <Select value={selectedOption13} onChange={this.handleChange13} options={this.state.tipopersonamgrh} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PLAZA MGRH</ControlLabel>
                    <FormControl type="text" value={this.state.plazamgrh} onChange= {this.handlePlazaMgrh} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>RESOLUCIÓN DE INGRESO</ControlLabel>
                    <FormControl type="text" value={this.state.resolucion} onChange= {this.handleResolucion} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>¿SINDICALIZADO?</ControlLabel><br></br>
                    <input type="radio" value="0" name="sindicalizado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="sindicalizado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue2}>
                    <ControlLabel>¿HORARIO NOCTURNO?</ControlLabel><br></br>
                    <input type="radio" value="0" name="nocturno" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="nocturno" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
            </div>
            <div className="row">
                {this.state.editable == true ? (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                    </div>
                    ) : (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                    </div>
                )}
            </div>
            </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default InfoTrabajador;