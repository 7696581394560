import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Contacto extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    return (
      <div className="content">
        <Tabs style={{marginTop: 10}}>
        <TabList>
            <Tab>DATOS DE CONTACTO</Tab>
            </TabList>
            <TabPanel>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE TELEFONO</ControlLabel>
                    <FormControl type="text" value={this.state.telefono} onChange= {this.handleTelefono} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE CELULAR</ControlLabel>
                    <FormControl type="text" value={this.state.celular} onChange= {this.handleCelular} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CORREO ELECTRÓNICO 1</ControlLabel>
                    <FormControl type="text" value={this.state.correoelectronico1} onChange= {this.handleCorreoElectronico1} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CORREO ELECTRÓNICO 2</ControlLabel>
                    <FormControl type="text" value={this.state.correoelectronico2} onChange= {this.handleCorreoElectronico2} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE RUC</ControlLabel>
                    <FormControl type="text" value={this.state.numeroruc} onChange= {this.handleNumeroRuc} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE ANEXO</ControlLabel>
                    <FormControl type="text" value={this.state.numeroanexo} onChange= {this.handleNumeroAnexo} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                {this.state.editable == true ? (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                    </div>
                    ) : (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                    </div>
                )}
            </div>
            </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default Contacto;