const validator = 'https://tomcat.sedetc.gob.pe/autentica-services-0.0.1/operaciones//app2/SISPER/';

const sisper = 'https://tomcat.sedetc.gob.pe/sisper-0.0.1/'
//const sisper = 'http://localhost:8081/sisper/';

const sisperplus = 'https://tomcat.sedetc.gob.pe/sisperplus-0.0.1/'
//const sisperplus = 'http://localhost:8082/sisperplus/';

const rrhh = 'http://localhost:8083/rrhh/';

const gestor = 'https://tomcat.sedetc.gob.pe/gestordoc-services-auth-0.0.1/';
//const gestor = 'http://localhost:8095/'

const marcaciones = 'https://tomcat.sedetc.gob.pe/marcador-0.0.1/';

const concursos =  'https://tomcat.sedetc.gob.pe/concursos-0.0.1/';
//const concursos =  'http://localhost:8082/concursos/';

const canaltv =  'https://tomcat.sedetc.gob.pe/canaltc-0.0.1/';
//const canaltv =  'http://localhost:8084/canaltc/';

export {
  validator,
  sisper,
  sisperplus,
  gestor,
  marcaciones,
  concursos,
  canaltv,
  rrhh
};