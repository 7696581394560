import React, { Component } from "react";
import { Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';

import { validator } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactExport from 'react-export-excel';
import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Marcas extends Component {

constructor(props) {
    super(props);
    this.state = {
        path: 'firma',
        usuario: localStorage.getItem('nickname'),
        token: 'Bearer ' + localStorage.getItem('AccessToken'),
        area: this.props.location.state.response.descripcion,
        id: this.props.location.state.response.id,
        fechaini: moment(new Date()).format('YYYY-MM-DD'),
        fechafin: moment(new Date()).format('YYYY-MM-DD'),
        personal: [],
        totTard: '',
        totExc: '',
        totRet: '',
        totSal: '',
        totPer: '',
        multiDataSet: [],
        isLoading: false
    }

    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
}

  handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
  }
  handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.cargaView();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  covertirHora(min){
    var h = min / 60 | 0
    var m = min % 60 | 0
    var time = h + ' h : ' + m + ' m'
    return time
  }

  async cargaView(){
    if(this.state.fechaini != '' && this.state.fechafin != ''){
      this.setState({isLoading: true})
      let cont = 0;  
      let cont2 = 0; 
      let cont3 = 0; 
      let fechaini = this.cambioFormat(this.state.fechaini)
      let fechafin = this.cambioFormat(this.state.fechafin)
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'administrador/listaPersonas?id=' + this.state.id)
      .then((res) => {
        var totTard = 0
        var totExc = 0
        var totRet = 0
        var totSal = 0
        var totPer = 0
        let lista2 = [];
        let lista = res.data.response;
        if(lista.length != 0){
            lista.map((data) => {
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.get(`${sisperplus}` + 'marcaciones/lista?id=' + data.Person + '&fechaini=' + fechaini + '&fechafin=' + fechafin)
                .then((res) => {
                    let marcas = res.data.response;
                    if(marcas.length != 0){
                        cont = cont + 1;
                        marcas.map((data2) => {
                            cont3 = cont3 + 1;
                            totTard = totTard + data2.late
                            totExc = totExc + data2.latelounch
                            totRet = totRet + data2.previoushours
                            totSal = totSal + data2.hoursnotautorized
                            totPer = totPer + data2.restextrahours
                            lista2.push(
                              { dni: data.dni,
                                nombre: data.nombre,
                                fecha: data2.fecha,
                                comments: data2.comments,
                                datebegin: Calendar.calendario7(data2.datebegin),
                                datebeginlounch: Calendar.calendario7(data2.datebeginlounch),
                                dateend: Calendar.calendario7(data2.dateend),
                                dateendlounch: Calendar.calendario7(data2.dateendlounch),
                                detail: data2.detail,
                                hoursnotautorized: data2.hoursnotautorized,
                                item: cont3,
                                late: data2.late,
                                latelounch: data2.latelounch,
                                previoushours: data2.previoushours,
                                restextrahours: data2.restextrahours
                            })
                        })
                    }else{
                        cont2 = cont2 + 1;
                    }
                    if(lista.length - cont2 == cont){
                        this.generarExcel(lista2, this.covertirHora(totTard), this.covertirHora(totExc), this.covertirHora(totRet), this.covertirHora(totSal), this.covertirHora(totPer))
                    }
                })
                .catch((error) => { 
                    if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                    }else{
                    console.log(error);
                    this.setState({isLoading: false})
                    }
                });
            })
        }else{
            this.setState({personal: [], isLoading: false})
            this.props.handleClick("tl", "error", "No existen registros para mostrar!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info")
    }
  }

  generarExcel(lista, totTard, totExc, totRet, totSal, totPer){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [
          {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}, alignment: {horizontal: "center"}}},
          {value: data.fecha, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.dni, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.nombre, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.datebegin == null ? '' : data.datebegin, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.datebeginlounch == null ? '' : data.datebeginlounch, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.dateendlounch == null ? '' : data.dateendlounch, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.dateend == null ? '' : data.dateend, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.late == null ? '' : JSON.stringify(data.late), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.latelounch == null ? '' : JSON.stringify(data.latelounch), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.previoushours == null ? '' : JSON.stringify(data.previoushours), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.hoursnotautorized == null ? '' : JSON.stringify(data.hoursnotautorized), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.restextrahours == null ? '' : JSON.stringify(data.restextrahours), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.comments == null ? '' : data.comments, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.detail == null ? '' : data.detail, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
      ]
      )
    });
    const dataSet = [
      {
        xSteps: 5,
        columns: ["REPORTE DE MARCACIONES"],
        data: []
      },
      {
          columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],
          data: [
              [{value: "RUC: 20217267618", style: {font: {sz: "10.5", bold: true}}}],
              [{value: "ÁREA: " + this.state.area, style: {font: {sz: "10.5", bold: true}}}],
              [{value: "FECHA: " + "Del " + Calendar.calendario6(this.state.fechaini) + " hasta el " + Calendar.calendario6(this.state.fechafin), style: {font: {sz: "10.5", bold: true}}}]
          ]
      },
      {
          columns: ["ITEM", "FECHA", "DNI", "APELLIDOS Y NOMBRES", "HORA ING.", "H.I.REF.", "H.F.REF.", "HORA SAL.", "TARD", "EXC.REF.", "RET.ANT.", "SAL.N.A.", "PERM.ADI.", "OBSERVACION", "DETALLE SAL.NO AUTORIZADAS"],
          data: dataExcel
      },
      {
          columns: ["TOTAL", "", "", "", "", "", "", "", totTard, totExc, totRet, totSal, totPer, "", ""],
          data: []
        },
    ];
    this.setState({
        personal: lista, 
        multiDataSet: dataSet,
        totTard: totTard, 
        totExc: totExc, 
        totRet: totRet, 
        totSal: totSal, 
        totPer: totPer, 
        isLoading: false
    })
  }

  regresar(){
    this.props.history.goBack()
  }

  GetActionFormat(cell, row) {
    return (
        <span data-tip={row.nombre} style={{cursor: 'pointer', fontSize: 14}}>
            {row.dni}
            <ReactTooltip />
        </span>
      );
  }

  render() {
    const columns = [
        {dataField: "item", text: "ITEM", sort: true, footer: 'TOTAL', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "fecha", text: "FECHA", sort: true, footer: '', filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dni", text: "N° DE DNI", sort: true, footer: '', filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat},
        {dataField: "datebegin", text: "INGRESO", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "datebeginlounch", text: "INI.REF.", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dateendlounch", text: "FIN.REF.", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dateend", text: "SALIDA", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "late", text: "TARDANZA", sort: true, footer: this.state.totTard, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "latelounch", text: "EXC.REF.", sort: true, footer: this.state.totExc, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "previoushours", text: "RET.ANT.", sort: true, footer: this.state.totRet, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "hoursnotautorized", text: "SAL.NAUT.", sort: true, footer: this.state.totSal, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "restextrahours", text: "PER.ADI.", sort: true, footer: this.state.totPer, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
        {dataField: "comments", text: "OBSERVACIÓN", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},,
        {dataField: "detail", text: "DET.SAL.NO AUTORIZADAS", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}
    ]
      const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.personal.length
      };
      const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                noDataIndication="No existen registros para mostrar"
                data={ this.state.personal }
                columns={ columns }
                filter={ filterFactory() }
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    const { area } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Ingresa día de Búsqueda"
                content={
                  <div style={{marginBottom: -20}}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        {label: "FECHA DE INICIO", type: "date", bsClass: "form-control", value: this.state.fechaini, onChange: this.handleFechaIni},
                        {label: "FECHA DE TÉRMINO", type: "date", bsClass: "form-control", value: this.state.fechafin, onChange: this.handleFechaFin}
                      ]}
                    />
                    {this.state.multiDataSet != '' ? (
                      <ExcelFile element={<Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                        <ExcelSheet dataSet={this.state.multiDataSet} name="Organization"/>
                    </ExcelFile>
                    ) : (null)}
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.cargaView() }}>Ver Resultados</Button>
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar</Button>
                  </div>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Marcaciones por Día: {area}</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para descargar el reporte en excel primero deberás realizar la consulta de las marcaciones.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          <Row>
          <h5 style={{marginLeft: 15}}>Leyenda</h5>
              <Col md={6} style={{letterSpacing: 0.5}}>
                <Alert bsStyle="info"><span>INI.REF. = Salida por Refrigerio.</span></Alert>
                <Alert bsStyle="info"><span>FIN.REF. = Retorno de Refrigerio.</span></Alert>
                <Alert bsStyle="info"><span>EXC.REF. = Exceso de tiempo en Refrigerio (Lo permitido es una hora máximo).</span></Alert>
                <Alert bsStyle="info"><span>RET.ANT. = Retiro antes de horario de salida.</span></Alert>
              </Col>
              <Col md={6} style={{letterSpacing: 0.5}}>
                <Alert bsStyle="warning"><span>SAL.NAUT. = Periodo acumulado por salidas no autorizadas (Salidas no sustentadas con papeletas).</span></Alert>
                <Alert bsStyle="warning"><span>PER.ADL. = Permanencia adicional.</span></Alert>
                <Alert bsStyle="warning"><span>DET.SAL. NO AUTORIZADAS = Detalle en caso tenga salidas no autorizadas.</span></Alert>
              </Col>
            </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Marcas);