import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { pdf, PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import moment from 'moment'
import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Cargar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'labores',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      dni: localStorage.getItem('dni'),
      labores: [],
      producto: "",
      comentario: "",
      actividad: "",
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      tipoHorario: [{value: "0", label: "JORNADA LABORAL"},{value: "1", label: "HORAS ADICIONALES"}],
      tipoHorario2: [{value: "0", label: "JORNADA LABORAL"},{value: "1", label: "HORAS ADICIONALES"}, {value: "2", label: "TODAS"}],
      selectedOption: {value: "0", label: "JORNADA LABORAL"},
      selectedOption2: {value: "0", label: "JORNADA LABORAL"},
      horas: "07:45",
      minutos: 0,
      nidcarga: "",
      estado: 0,
      isLoading: false,
      base: "",
      persona: "",
      jefe: ""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleProducto = this.handleProducto.bind(this);
    this.handleActividad = this.handleActividad.bind(this);
    this.handleComentario = this.handleComentario.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    this.handleHoras = this.handleHoras.bind(this);

  }

    handleProducto(event) {
        this.setState({producto: event.target.value});
    }

    handleHoras(event) {
        this.setState({horas: event.target.value});
    }

    handleActividad(event) {
        this.setState({actividad: event.target.value});
    }

    handleComentario(event) {
      this.setState({comentario: event.target.value});
    }

    handleFecha(event) {
      this.setState({fecha: event.target.value});
    }
    
    handleFechaini(event) {
      this.setState({fechaini: event.target.value});
    }

    handleFechafin(event) {
      this.setState({fechafin: event.target.value});
    }

    handleChange = selectedOption => {
      this.setState(
      { selectedOption },
      () => this.cambiarHora()
      );
    };

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarDatos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarLabores() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'labores/listCargasxpersona?usuario=' + this.state.usuario + '&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin + '&tipo=' + this.state.selectedOption2.value)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response.listCargas;
        let lista3 = res.data.response.listTotal[0].total;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                idlabor: data.nid,
                idarea: data.nid_area,
                oficina: data.oficina,
                producto: data.producto,
                horas: data.horas + " Hrs",
                horas2: data.horas,
                actividad: data.actividad,
                comentario: data.comentario,
                tipo: data.tipo,
                estado: data.estado == '' ? 'PENDIENTE' : data.estado,
                aprobador: data.aprobador,
                fecha: data.dfec_registro,
                fecha2: Calendar.calendario9(data.dfec_modificacion),
            })
          })
          this.setState({minutos: lista3, labores: lista, isLoading: false})
        }else{
          this.setState({labores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    let fromData = new FormData();
    fromData.append('nid', data.idlabor);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/eliminarCarga', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarLabores();
        this.props.handleClick("tl", "info", "Tu labor ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.setState({producto: "", actividad: "", comentario: "", nidcarga: ""})
    this.listarLabores();
    this.props.handleClick("tl", "info", "Tu labor ha sido registrada!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({producto: "", actividad: "", comentario: "", nidcarga: ""})
  }

  async obtener(data){
    this.setState({
        producto: data.producto, 
        horas: data.horas2, 
        actividad: data.actividad, 
        comentario: data.comentario, 
        fecha: data.fecha,
        nidcarga: data.idlabor, 
        selectedOption: {value: '', label: data.tipo},
        estado: data.estado
    })
  }

  async save(){
    if(this.state.horas != 1){
      let param;
      if(this.state.selectedOption.label == "JORNADA LABORAL"){
        param = this.calcularHorasJor(this.state.horas);
      }else{
        param = this.calcularHorasExt(this.state.horas);
      }
      if(param != 'x'){
        if(this.state.nidcarga == ""){
           this.guardar(param)
        }else{
           this.editar(param)
        }
      }else{
        this.props.handleClick("tl", "error", "El tiempo ingresado excede lo establecido!", "pe-7s-close")
      }
    }else{
        this.props.handleClick("tl", "error", "El tiempo ingresado no es correcto!", "pe-7s-close")
    }

  }

  cambiarHora(){
    if(this.state.selectedOption.label == "JORNADA LABORAL"){
      this.setState({horas: "07:45"})
    }else{
      this.setState({horas: "02:00"})
    }    
  }

  calcularHorasJor(tiempo){
    let divisiones = tiempo.split(":", 2);
    let num1 = divisiones[0].substring(0,1);
    let num2 = divisiones[0].substring(1,2);
    if(num1 == 0){
      if(num2 <= 7){
        let min = ((num2 * 60) + parseInt(divisiones[1]));
        if(min <= 465){
          return min;
        }else{
          return 'x';
        }
      }else{
        return 'x';
      }
    }else{
      return 'x';
    }
  }

  calcularHorasExt(tiempo){
    let divisiones = tiempo.split(":", 2);
    let num1 = divisiones[0].substring(0,1);
    let num2 = divisiones[0].substring(1,2);
    if(num1 == 0){
      if(num2 <= 2){
        let min = ((num2 * 60) + parseInt(divisiones[1]));
        if(min <= 120){
          return min;
        }else{
          return 'x';
        }
      }else{
        return 'x';
      }
    }else{
      return 'x';
    }
  }

  async guardar(param){
    if(this.state.producto != "" && this.state.actividad != "" && this.state.comentario != ""){
        let fromData = new FormData();
        fromData.append('producto', this.state.producto);
        fromData.append('horas', this.state.horas);
        fromData.append('minutos', param);
        fromData.append('actividad', this.state.actividad);
        fromData.append('comentario', this.state.comentario);
        fromData.append('fecha', this.state.fecha);
        fromData.append('tipo', this.state.selectedOption.label);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'labores/insertarCarga', fromData)
        .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async editar(param){
    if(this.state.producto != "" && this.state.actividad != "" && this.state.comentario != "" && this.state.horas != ""){
      let fromData = new FormData();
      fromData.append('producto', this.state.producto);
      fromData.append('horas', this.state.horas);
      fromData.append('minutos', param);
      fromData.append('actividad', this.state.actividad);
      fromData.append('comentario', this.state.comentario);
      fromData.append('fecha', this.state.fecha);
      fromData.append('tipo', this.state.selectedOption.label);
      fromData.append('estado', 0);
      fromData.append('nid', this.state.nidcarga);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'labores/editarCarga', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.limpiar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async listarDatos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listDatos?usuario=' + this.state.usuario)
    .then((res) => {
      this.setState({
        persona: res.data.response[0].cnom_nombres + " " + res.data.response[0].cape_paterno + " " + res.data.response[0].cape_materno
      })
      this.naprob();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async naprob() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/naprob?dni=' + this.state.dni)
    .then((res) => {
      let listaAprob = res.data.response
      listaAprob.map((data) => {
         if(data.nivel == 1){
          this.setState({jefe: data.aprobadorprincipal})
         }
      });
      this.listarLabores();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  conversorHoras(minutos){
    var hours = Math.floor( minutos / 60);          
    var minutes = (minutos % 60);
    return hours + ":" + minutes;
  }

  async verificar(){
    var row = [];
    row.push(
       { jefe: this.state.jefe,
         datos: this.state.persona,
         fechaini: Calendar.calendario6(this.state.fechaini),
         fechafin: Calendar.calendario6(this.state.fechafin),
         tipo: this.state.selectedOption2.label,
         minutos: this.conversorHoras(this.state.minutos)
     })
    const blob = pdf(PdfDocument(this.state.labores, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data});
          }
        }
        return result;
    }); 
  }

  async regresar(){
    this.setState({base: ""})
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
        row.estado == 'PENDIENTE' ? (
          <div>
              <span data-tip="Editar Labor" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Labor" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
          ) : (null)
      );
    }else{}
  }

  render() {
    const { selectedOption, selectedOption2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "producto", text: "PRODUCTO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "actividad", text: "ACTIVIDAD", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "aprobador", text: "APROBADO POR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA DE APROBACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.labores.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.labores }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Carga de Labores"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>PRODUCTO</ControlLabel>
                            <FormControl type="text" value={this.state.producto} onChange= {this.handleProducto}/>
                        </div>

                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE HORARIO </ControlLabel><a></a>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.tipoHorario}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>HORAS </ControlLabel><a></a>
                            <FormControl type="time" value={this.state.horas} onChange= {this.handleHoras}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA</ControlLabel>
                            <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10" style={{marginBottom: 18}}>
                          <ControlLabel>ACTIVIDAD</ControlLabel>
                          <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.actividad} onChange= {this.handleActividad}/>
                          <ControlLabel style={{marginTop: 18}}>COMENTARIO</ControlLabel>
                          <FormControl type="text" value={this.state.comentario} onChange= {this.handleComentario}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                          <ControlLabel>ACCIONES</ControlLabel><br></br>
                          <Button style={{width: 150, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar Labor</Button><br></br>
                          <Button style={{width: 150}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar o Cancelar</Button>
                        </div>
                    </div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>NOTA: Solo se deberá agregar un registro por día de 7 Hrs y 45 Min como máximo para Jornada Laboral y 2 Hrs como máximo para Horas Adicionales.</h4>
                  </div>}
              />
            </Col>
          </Row>
          {this.state.base == "" ? (
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Listado de Labores</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE HORARIO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoHorario2}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 150, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarLabores() }}>Buscar Labores</Button>
                        <Button style={{width: 150, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.verificar() }}>Generar PDF</Button>
                    </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          ) : (
          <Row>
          <Col md={12}>
            <Card
                title="Ver Documento"
                content={
                  <div>
                    <iframe src={this.state.base} style={{height: 1200, width: "100%"}}></iframe>
                    <Button style={{width: 150, marginTop: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                 }
              />
            </Col>
        </Row>
        )}
        </Grid>
      </div>
    );
  }
}

export default Cargar;