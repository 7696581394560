import React, { Component } from "react";
import { Grid } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <p className="copyright pull-right" style={{letterSpacing: 1}}>
            &copy; {new Date().getFullYear()} - Desarrollado por la Oficina de TI - <a> Tribunal Constitucional del Perú </a>
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
