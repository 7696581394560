import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import CheckboxGroup from 'react-checkbox-group'
import { validator } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class newPapeleta extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'papeletas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname'),
    id: JSON.parse(localStorage.getItem('datos'))[0].Person,
    token: 'Bearer ' + localStorage.getItem('AccessToken'),
    fechaini: moment(new Date()).format('YYYY-MM-DD'),
    fechafin: moment(new Date()).format('YYYY-MM-DD'),
    selectedOption: {value: "LIMABGT 000000000002", label: "COMISION DE SERVICIO"},
    papeletas: [],
    file: [],
    fileName: '',
    type: '',
    motivo: '',
    sustento: '',
    isGoing: false,
    isLoading: false
  }

  this.handleFechaIni = this.handleFechaIni.bind(this);
  this.handleFechaFin = this.handleFechaFin.bind(this);
  this.handleMotivo = this.handleMotivo.bind(this);
  this.handleSustento = this.handleSustento.bind(this);
  this.handleCheck = this.handleCheck.bind(this);

}

handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
}

handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
}

handleMotivo(event) {
    this.setState({motivo: event.target.value});
}

handleSustento(event) {
    this.setState({sustento: event.target.value});
}

handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    // () => console.log(`Option selected:`, this.state.selectedOption)
    );
};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.cargaView();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletas/tiposPapeletas')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.tipopapeleta,
                label: data.descripcion,
            })
          });
          this.setState({papeletas: lista, isLoading: false})
      }else{
        this.setState({papeletas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

  cerrar(){
    this.setState({visFile: false});
  } 

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
    }
  }

  async cancelar(){
    this.props.history.push('/papeletas');
  }

  guardar(){
    if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
      if(this.state.fechaini != '' && this.state.fechafin != '' && this.state.motivo != ''){
        // if(this.state.selectedOption.value == 'LIMABGT 000000000005' && this.state.type == ''){
        //   this.props.handleClick("tl", "error", "Debes adjuntar una cita médica!", "pe-7s-close")
        // }else{
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('dni', this.state.dni);
          fromData.append('tipopapeleta', this.state.selectedOption.value);
          fromData.append('observaciones', this.state.motivo);
          fromData.append('fechaini', this.cambioFormat(this.state.fechaini));
          fromData.append('fechafin', this.cambioFormat(this.state.fechafin));
          fromData.append('userid', this.state.id);
          fromData.append('documentcomments', this.state.sustento);
          fromData.append('flagjustifica', this.cambioCheck(this.state.isGoing));
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'papeletas/insertarPapeleta', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response == null){
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.get(`${sisperplus}` + 'papeletas/listaTop?id=' + this.state.id)
              .then((res) => {
                let lista = res.data.response;
                  if(this.state.file != ''){
                    this.subirArchivo(lista[0].idpapeleta)
                  }else{
                    this.props.handleClick("tl", "info", "Tu papeleta ha sido registrada!", "pe-7s-smile")
                    this.props.history.push('/papeletas');
                  }
              })
              .catch((error) => { 
                if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                }else{
                  console.log(error);
                  this.setState({isLoading: false})
                }
              });
            }else{
              this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
              this.setState({isLoading: false})
            }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
        // }
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
    }
  }

  subirArchivo(idpape){
    let fromData = new FormData();
    fromData.append('pape', idpape);
    fromData.append('filename', this.state.fileName);
    fromData.append('nid', this.state.id);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletas/insertarArchivo', fromData)
    .then((response) => {
      var datos = response.data.response
      if(datos.archivo != ''){
        this.uploadArchivo(datos.archivo)
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  uploadArchivo(archivo){
        let anio = new Date().getFullYear()
        let mes = parseInt(new Date().getMonth()) + 1
        if(mes < 10){mes = '0'+ mes}
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('archivo_file', this.state.file);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', archivo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/subirArchivoPap', fromData)
        .then((response) => {
          if(response.data.result == null){
            this.props.handleClick("tl", "info", "Tu papeleta ha sido registrada!", "pe-7s-smile")
            this.props.history.push('/papeletas');
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
            this.setState({isLoading2: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
  }

  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  cambioCheck(check){
    if(check == true){
     return 'Y';
    }else{
     return 'N';
    }
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Crear Papeleta"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE PAPELETA</ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.papeletas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO</ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TERMINO</ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>Motivo</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.motivo} onChange= {this.handleMotivo}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO SUSTENTO (Opcional)</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.sustento} onChange= {this.handleSustento}/>
                        </div>
                        <div className="col-md-4" style={{letterSpacing: 1}}>
                        <ControlLabel>JUSTIFICA INGRESO FUERA DE HORARIO</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}}
                            /><a>Marque solamente cuando esta papeleta justificará un ingreso fuera de horario.</a>
                        </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                    <div className="col-md-3"></div>
                        {/* {this.state.visFile != false ? ( */}
                            <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS</ControlLabel>
                            <Alert bsStyle="warning">
                                {/* <button onClick={() => { this.cerrar() }} type="button" aria-hidden="true" className="close">&#x2715;</button> */}
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                        </div>
                        {/* ) : (null)} */}
                    </div>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Papeleta</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Papeleta</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(newPapeleta);