import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import 'react-accessible-accordion/dist/fancy-example.css';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class PriDireccion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      editable: true
    }

  }

  async editar(){
    if(this.state.editable == false){
      this.setState({editable: true});
    }else{
      this.setState({editable: false});
    }
  }

  async validar(){

  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6 } = this.state;
    return (
      <div className="content">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Primera Dirección:
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PAÍS</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.pais} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>DEPARTAMENTO</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.departamentos} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PROVINCIA</ControlLabel>
                    <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.provincias} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>DISTRITO</ControlLabel>
                    <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.distritos} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE VÍA</ControlLabel>
                    <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.tipovia} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE VÍA</ControlLabel>
                    <FormControl type="text" value={this.state.via} onChange= {this.handleVia} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE ZONA</ControlLabel>
                    <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.tipozona} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE ZONA</ControlLabel>
                    <FormControl type="text" value={this.state.zona} onChange= {this.handleZona} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>CÓDIGO POSTAL</ControlLabel>
                    <FormControl type="text" value={this.state.codigopostal} onChange= {this.handleCodigoPostal} disabled={this.state.editable}/>
                </div>
                <div className="col-md-9" style={{marginBottom: 18}}>
                    <ControlLabel>REFERENCIA</ControlLabel>
                    <FormControl type="text" value={this.state.referencia} onChange= {this.handleReferencia} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>N° DE DEPARTAMENTO</ControlLabel>
                    <FormControl type="text" value={this.state.apartamento} onChange= {this.handleApartamento} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>INTERIOR</ControlLabel>
                    <FormControl type="text" value={this.state.interior} onChange= {this.handleInterior} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MANZANA</ControlLabel>
                    <FormControl type="text" value={this.state.manzana} onChange= {this.handleManzana} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>LOTE</ControlLabel>
                    <FormControl type="text" value={this.state.lote} onChange= {this.handleLote} disabled={this.state.editable}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>KILOMETRO</ControlLabel>
                    <FormControl type="text" value={this.state.kilometro} onChange= {this.handleKilometro} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>BLOQUE</ControlLabel>
                    <FormControl type="text" value={this.state.bloque} onChange= {this.handleBloque} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>ETAPA</ControlLabel>
                    <FormControl type="text" value={this.state.etapa} onChange= {this.handleEtapa} disabled={this.state.editable}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>ATENCIÓN ESSALUD</ControlLabel><br></br>
                    <input type="radio" value="0" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                    <input type="radio" value="1" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                </div>
            </div>
            <div className="row">
                {this.state.editable == true ? (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                    </div>
                    ) : (
                    <div>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                    </div>
                )}
            </div>
            </AccordionItemPanel>
        </AccordionItem>
      </div>
    );
  }
}

export default PriDireccion;