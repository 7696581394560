import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { rrhh } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AdministrarTrabajadores extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarTrabajadores',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: {value: "0", label: "TODOS"},
      selectedOption2: {value: "0", label: "TODOS"},
      selectedOption3: {value: "1", label: "HABILITADO"},
      tiposregimen: [{value: "0", label: "TODOS"}],
      areas: [{value: "0", label: "TODOS"}],
      estados: [{value: "1", label: "HABILITADO"},{value: "2", label: "SUSPENDIDO"}],
      trabajadores: [],
      estado: 0,
      isLoading: true,
      show: true,
    }

    this.GetActionFormat= this.GetActionFormat.bind(this);
  }

  handleChange = selectedOption => {this.setState({ selectedOption },);};
  handleChange2 = selectedOption2 => {this.setState({ selectedOption2 },);};
  handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },);};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.tiposregimen();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async tiposregimen() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${rrhh}` + 'tipos/comboTiposRegimen')
    .then((res) => {
        let lista2 = []
        lista2.push(
            { value: "0",
              label: "TODOS",
        })
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({tiposregimen: lista2, selectedOption: {value: "0", label: "TODOS"}})
          this.areas();
      }else{
        this.setState({tiposregimen: [{value: "0", label: "TODOS"}], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar régimen!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async areas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${rrhh}` + 'tipos/comboAreas')
    .then((res) => {
        let lista2 = []
        lista2.push(
            { value: "0",
              label: "TODOS",
        })
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({areas: lista2, selectedOption: {value: "0", label: "TODOS"}})
          this.trabajadores('0');
      }else{
        this.setState({areas: [{value: "0", label: "TODOS"}], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar áreas!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async trabajadores(param) {
      if(param == '1'){
        this.setState({isLoading: true})
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${rrhh}` + 'trabajadores/listarTrabajadores?regimen=' + this.state.selectedOption.value + 
                                                                    '&area=' + this.state.selectedOption2.value +
                                                                    '&estado=' + this.state.selectedOption3.value)
      .then((res) => {
        let lista2 = [];
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { item: data.item,
                nid: data.nid,
                codigo: data.codigo,
                numero: data.numero,
                nombres: data.nombres,
                telefono: data.telefono,
                correo: data.correo,
                cargo: data.cargo,
                estado: data.estado
            })
          })
          this.setState({trabajadores: lista2, isLoading: false})
        }else{
          this.setState({trabajadores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Error al consultar trabajadores!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async verificar(param1, param2){
      if(param2 == '0'){
        confirmAlert({
            title: '¿Desea eliminar el trabajador?',
            message: 'Se eliminará el trabajador seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.manejar(param1, param2)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }else if(param2 == '2'){
        confirmAlert({
            title: '¿Desea anular el trabajador?',
            message: 'Se anulará el trabajador seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.manejar(param1, param2)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }else{
        confirmAlert({
            title: '¿Desea habilitar el trabajador?',
            message: 'Se habilitará el trabajador seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.manejar(param1, param2)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }
  }

  async manejar(param1, param2){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', param1);
    fromData.append('estado', param2);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${rrhh}` + 'trabajadores/manejarTrabajador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 0){
          if(param2 == '0'){
            this.props.handleClick("tl", "info", "El trabajador ha sido eliminado!", "pe-7s-smile")
          }else if(param2 == '1'){
            this.props.handleClick("tl", "info", "El trabajador ha sido habilitado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "info", "El trabajador ha sido anulado!", "pe-7s-smile")
          }
          this.trabajadores('1');
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async buscar(){
     this.trabajadores('1');
  }

  async detalle(data){
    localStorage.setItem('detalle', JSON.stringify(data))
    this.props.history.push('/editarPersonal');
  }

  async agregar(){
    this.props.history.push('/agregarTrabajador');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Trabajador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-external-link fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.estado == '1' || row.estado == '2'? (
              <span data-tip="Eliminar Trabajador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row.nid, '0') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '1' ? (
              <span data-tip="Anular Trabajador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row.nid, '2') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-low-vision fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '2' ? (
              <span data-tip="Habilitar Trabajador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row.nid, '1') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "codigo", text: "CODIGO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "N° DE DOCUMENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "NOMBRES Y APELLIDOS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "telefono", text: "TELEFONO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "correo", text: "CORREO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cargo", text: "CARGO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.trabajadores.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.trabajadores }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Trabajadores</h4>
              <div className="row">
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>TIPOS DE RÉGIMEN</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposregimen}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.areas}/>
                </div>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>ESTADO DEL TRABAJADOR</ControlLabel>
                    <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.estados}/>
                </div>
              </div>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.buscar() }}>Buscar Trabajador</Button>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.agregar() }}>Agregar Nuevo Trabajador</Button>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AdministrarTrabajadores;