import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import { regExpLiteral } from "@babel/types";
import Calendar from "components/Calendar/Calendar.jsx";
import moment from 'moment'

const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    author: {
      fontSize: 15,
      textAlign: 'center',
      marginBottom: 40,
      marginTop: 10
    },
    subtitle: {
      fontSize: 13,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginBottom: 30 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "100%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableCol: { 
        width: "30%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCol2: { 
        width: "70%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 15,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 13
      }
});

export function PdfDocument(nombre, dni, datos, archivo) {
    const localIpUrl = require('local-ip-url');
    const ipPub = localIpUrl('public', 'ipv4')
    const codigo = archivo.replace(".pdf", "");
    const fecha = moment(new Date()).format('DD-MM-YYYY HH:mm');
    return (
        <Document>
        <Page style={styles.body}>
          {/* <Text style={styles.header} fixed>Elaborado por</Text> */}
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author}>Papeleta de Salida</Text>
          <Text style={styles.subtitle}>
            Fecha de Registro: {datos.registro}
          </Text>
            <View style={styles.table}> 
                <View style={styles.tableRow}> 
                <View style={styles.tableColHeader}> 
                    <Text style={styles.tableCellHeader}>Historial de Observaciones</Text> 
                </View> 
                </View>
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Colaborador</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{datos.trabajador}</Text> 
                </View> 
                </View> 
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Tipo de Papeleta</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{datos.tipopapeleta}</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Fecha/Hora</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>Del {datos.fechaini} al {datos.fechatermino}</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Motivo</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{datos.motivo}</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Documento Sustento</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}></Text> 
                </View>  
                </View>  
            </View>
            <View style={styles.table}> 
                <View style={styles.tableRow}> 
                <View style={styles.tableColHeader}> 
                    <Text style={styles.tableCellHeader}>Registro de Autorización</Text> 
                </View> 
                </View>
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Apellidos y Nombres</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{nombre}</Text> 
                </View> 
                </View> 
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>DNI</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{dni}</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Motivo</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>AUTORIZACIÓN</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Ubicación</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{ipPub}</Text> 
                </View>  
                </View>  
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Código de Operación</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{codigo}</Text> 
                </View>  
                </View> 
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Fecha de Firma</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{fecha}</Text> 
                </View>  
                </View>   
            </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}