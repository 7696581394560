import React, { Component } from "react";
import { FormControl, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { rrhh } from 'store/Apis'
import auth0Client from 'login/Auth';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AgreTrabajador extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      selectedOption: "", selectedOption2: "", selectedOption3: "", selectedOption4: "", selectedOption5: "", selectedOption6: "", selectedOption7: "", 
      apepaterno: "", apematerno: "", primernombre: "", segundonombre: "", numerodocumento: "",
      listareas: [], listestadocivil: [], listpaises: [], listtiporegimen: [], listtiposdocumento: [], listtiposexo: [], listcargos: [],
      isLoading: false
    }

    this.handleApePaterno = this.handleApePaterno.bind(this);
    this.handleApeMaterno = this.handleApeMaterno.bind(this);
    this.handlePrimerNombre = this.handlePrimerNombre.bind(this);
    this.handleSegundoNombre = this.handleSegundoNombre.bind(this);
    this.handleNumeroDocumento = this.handleNumeroDocumento.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeValue2 = this.onChangeValue2.bind(this);

  }

  handleApePaterno(event) {this.setState({apepaterno: event.target.value});}
  handleApeMaterno(event) {this.setState({apematerno: event.target.value});}
  handlePrimerNombre(event) {this.setState({primernombre: event.target.value});}
  handleSegundoNombre(event) {this.setState({segundonombre: event.target.value});}
  handleNumeroDocumento(event) {this.setState({numerodocumento: event.target.value});}
  handleChange = selectedOption => {this.setState({ selectedOption },) };
  handleChange2 = selectedOption2 => {this.setState({ selectedOption2 },) };
  handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },) };
  handleChange4 = selectedOption4 => {this.setState({ selectedOption4 },) };
  handleChange5 = selectedOption5 => {this.setState({ selectedOption5 },) };
  handleChange6 = selectedOption6 => {this.setState({ selectedOption6 },() => this.cargos());};
  handleChange7 = selectedOption7 => {this.setState({ selectedOption7 },) };
  onChangeValue(event) {this.setState({discapacitado: event.target.value});}
  onChangeValue2(event) {this.setState({domiciliado: event.target.value});}

  async componentDidMount() {
    this.cargarTipos();
  }

  async cargarTipos(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${rrhh}` + 'tipos/comboTrabajadorInfoGen')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        lista.listareas.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
        });
        this.setState({listareas: lista2, selectedOption6: {value: lista2[0].value, label: lista2[0].label}})
        lista2 = [];
        lista.listestadocivil.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
        });
        this.setState({listestadocivil: lista2, selectedOption4: {value: lista2[0].value, label: lista2[0].label}})
        lista2 = [];
        lista.listpaises.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.pais,
            })
        });
        this.setState({listpaises: lista2, selectedOption: {value: lista2[0].value, label: lista2[0].label}})
        lista2 = [];
        lista.listtiporegimen.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
        });
        this.setState({listtiporegimen: lista2, selectedOption5: {value: lista2[0].value, label: lista2[0].label}})
        lista2 = [];
        lista.listtiposdocumento.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
        });
        this.setState({listtiposdocumento: lista2, selectedOption2: {value: lista2[0].value, label: lista2[0].label}})
        lista2 = [];
        lista.listtiposexo.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
        });
        this.setState({listtiposexo: lista2, selectedOption3: {value: lista2[0].value, label: lista2[0].label}})
        this.cargos()        
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.handler3();
      }else{
        this.setState({isLoading: false})
      }
    });
  }

  async cargos(){
     axios.defaults.headers.common['Authorization'] = this.state.token
     axios.get(`${rrhh}` + 'tipos/comboCargos?nid=' + this.state.selectedOption6.value)
     .then((res) => {
         let lista2 = [];
         let lista = res.data.response;
         lista.map((data) => {
             lista2.push(
               { value: data.nid,
                 label: data.descripcion,
             })
         });
         this.setState({listcargos: lista2, selectedOption7: {value: lista2[0].value, label: lista2[0].label}})
         this.setState({isLoading: false})
     })
     .catch((error) => { 
       if(auth0Client.isAuthenticated() === true){
         auth0Client.signOut()
         this.props.handler3();
       }else{
         this.setState({isLoading: false})
       }
     });
  }

  async validar(){
    var param1= 0; var param2= 0;
    if(this.state.discapacitado == undefined){
       param1 = 0
    }else{
       param1 = this.state.discapacitado
    }
    if(this.state.domiciliado == undefined){
       param2 = 0
    }else{
       param2 = this.state.domiciliado
    }
    this.verificar(param1, param2)
  }

  async verificar(param1, param2){
    if(this.state.apepaterno != '' || this.state.apematerno != '' || this.state.primernombre != '' || this.state.segundonombre != '' ||  this.state.numerodocumento != ''){
      this.guardar(param1, param2);
    }else{
        this.props.handler();
    }
  }

  async regresar(){
    this.props.handler2();
  }

  async guardar(param1, param2){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('apepaterno', this.state.apematerno);
    fromData.append('apematerno', this.state.apematerno);
    fromData.append('primernombre', this.state.primernombre);
    fromData.append('segundonombre', this.state.segundonombre);
    fromData.append('pais', this.state.selectedOption.value);
    fromData.append('tipodoc', this.state.selectedOption2.value);
    fromData.append('numdocumento', this.state.numerodocumento);
    fromData.append('tiposexo', this.state.selectedOption3.value);
    fromData.append('estadocivil', this.state.selectedOption4.value);
    fromData.append('tiporegimen', this.state.selectedOption5.value);
    fromData.append('area', this.state.selectedOption6.value);
    fromData.append('cargo', this.state.selectedOption7.value);
    fromData.append('discapacitado', param1);
    fromData.append('domiciliado', param2);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${rrhh}` + 'trabajadores/insertarTrabajador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 0){
          this.props.handler4();
        }else if(datos.response.estado == 2){
          this.props.handler6();
          this.setState({isLoading: false})
        }else{
          this.props.handler5();
          this.setState({isLoading: false})
        }
      }else{
          this.props.handler5();
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.handler3();
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7 } = this.state;
    return (
      <div className="content">
        <Tabs style={{marginTop: 30}}>
        <TabList>
            <Tab>INFORMACIÓN GENERAL</Tab>
            </TabList>
            <TabPanel>
            {this.state.isLoading ? (
                <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={50}
                color={'#123abc'}
                loading={this.state.loading}
                />
            ) : (
            <div>
                <div className="row" style={{marginTop: 20}}>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>APELLIDO PATERNO</ControlLabel>
                        <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>APELLIDO MATERNO</ControlLabel>
                        <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PRIMER NOMBRE</ControlLabel>
                        <FormControl type="text" value={this.state.primernombre} onChange= {this.handlePrimerNombre}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>SEGUNDO NOMBRE</ControlLabel>
                        <FormControl type="text" value={this.state.segundonombre} onChange= {this.handleSegundoNombre}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PAÍS EMISOR</ControlLabel>
                        <Select value={selectedOption} onChange={this.handleChange} options={this.state.listpaises}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.listtiposdocumento}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>N° DE DOCUMENTO</ControlLabel>
                        <FormControl type="text" value={this.state.numerodocumento} onChange= {this.handleNumeroDocumento}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE SEXO</ControlLabel>
                        <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.listtiposexo}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>ESTADO CIVIL</ControlLabel>
                        <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.listestadocivil}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE RÉGIMEN</ControlLabel>
                        <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.listtiporegimen}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>AREA LABORAL</ControlLabel>
                        <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.listareas}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>CARGO ESTRUCTURAL</ControlLabel>
                        <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.cargo}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                        <ControlLabel>¿DISCAPACITADO?</ControlLabel><br></br>
                        <input type="radio" value="0" name="discapacitado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                        <input type="radio" value="1" name="discapacitado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue2}>
                        <ControlLabel>¿DOMICILIADO?</ControlLabel><br></br>
                        <input type="radio" value="0" name="domiciliado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                        <input type="radio" value="1" name="domiciliado" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                    </div>
                </div>
                <div className="row" style={{marginBottom: 20}}>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                    <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar a Listado</Button>
                </div>
            </div>
            )}
            </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default AgreTrabajador;