import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { validator } from 'store/Apis'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Calendar from "components/Calendar/Calendar.jsx";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Aprobar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'aprobar',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      area: {value: "1", label: JSON.parse(localStorage.getItem('datos'))[0].area},
      url: '',
      areas: [],
      listado: [],
      isLoading: false
    }
  }

  handleArea = area => {
    this.setState(
    { area },
     () => this.listar()
    );
  };

  submit = () => {
    let cuenta = this.node.selectionContext.selected
    if(cuenta.length >= 1){
      confirmAlert({
        title: '¿Seguro de aprobar?',
        message: 'Se aprobarán las labores seleccionadas.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.procesar()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar algún registro para aprobar!", "pe-7s-info")
    }
  };

  async componentDidMount() {
  if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
      headers: {
          Authorization: this.state.token
      }
      }).then((response) => {
          let permisos = response.data;
          if(permisos.codError != 1){
              this.props.history.goBack()
          }else{
              this.cargaView();
          }
      },(error) => {
          if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          }else{
          this.props.history.goBack()
          }
      });
  }else{
      auth0Client.signOut();
      this.props.history.replace('/');
  }
  }

  async listar(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listCargasxarea?nid=' + this.state.area.value)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              idlabor: data.nid,
              oficina: data.oficina,
              producto: data.producto,
              horas: data.horas + " Hrs",
              horas2: data.horas,
              actividad: data.actividad,
              comentario: data.comentario,
              fecha: data.dfec_registro,
              trabajador: data.persona,
          })
        });
        this.setState({listado: lista, isLoading: false})
      }else{
        this.setState({listado: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/areas?dni=' + this.state.dni)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label},})
          this.listar();
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async procesar(){
    this.setState({isLoading: true});
    let lista = this.node.selectionContext.selected;
    this.setState({url: 'labores/editarCarga'})
    setTimeout(
      function() {
      let cont = 1;  
      lista.map((data) => {
          for(var i = 0; i < this.state.listado.length; i++){
            if(this.state.listado[i].item == data){
                let fromData = new FormData();
                let pape = this.state.listado[i].trabajador;
                fromData.append('producto', this.state.listado[i].producto);
                fromData.append('horas', this.state.listado[i].horas2);
                fromData.append('actividad', this.state.listado[i].actividad);
                fromData.append('comentario', this.state.listado[i].comentario);
                fromData.append('fecha', this.state.listado[i].fecha);
                fromData.append('estado', 1);
                fromData.append('nid', this.state.listado[i].idlabor);
                fromData.append('usuario', this.state.usuario);
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.post(`${sisperplus}` + this.state.url, fromData)
                .then((response) => {
                  var datos = response.data
                  if(datos.response != null){
                    var noty = "La labor de " + pape + " ha sido aprobada!"
                    this.props.handleClick("tl", "info", noty, "pe-7s-smile")
                    if(lista.length == cont++){
                      this.listar();
                    }
                  }else{
                    this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                  }else{
                    console.log(error);
                    this.setState({isLoading: false})
                  }
                });
            }
          }
      });
      }
      .bind(this),
      3000
    );
  }

  render() {
    const columns = [
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "trabajador", text: "TRABAJADOR", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "producto", text: "PRODUCTO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "actividad", text: "ACTIVIDAD", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const columns2 = [{
      dataField: 'CheckDate',
      headerAlign: (column, colIndex) => 'center',
      text: 'Fecha'
    }, {
      dataField: 'descripcion',
      headerAlign: (column, colIndex) => 'center',
      text: 'Observación'
    }];
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listado }
              columns={ columns }
              selectRow={ selectRow }
              tabIndexCell
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Aprobar Labores de Personal"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ÁREAS DE APLICACIÓN</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                    </div>
                   <Button style={{width: 300, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.submit() }}>Aprobar</Button>
                  </div>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Labores Pendientes</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para autorizar las labores deberás seleccionar los registros y luego pulsar el boton Aprobar para iniciar el proceso.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Aprobar;