import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

class PageNotFound extends Component {

  async componentDidMount() {
    this.props.history.goBack()
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default withRouter(PageNotFound);