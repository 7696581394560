import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import ReactDom from 'react-dom';
import zxcvbn from 'zxcvbn';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Configuracion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      idPersona: localStorage.getItem('idPersona'),
      tokenSec: '',
      resultScore: '',
      warning: '',
      suggestions:'',
      password1: '',
      password2: '',
      isLoading: false,
      type: 'password',
      valida: 'Verificar'
    }
    this.handleInput = this.handleInput.bind(this);
    this.handleInput2 = this.handleInput2.bind(this);
  }

  handleInput(event){
    event.preventDefault();
    let { strength } = this.props;
    strength = (strength) ? strength : {
      0: "Muy Inseguro",
      1: "Inseguro",
      2: "Poco Seguro",
      3: "Seguro",
      4: "Muy Seguro"
    }

    const password = ReactDom.findDOMNode(this.refs.password);
    const meter = ReactDom.findDOMNode(this.refs.passwordStrengthMeter);
    const text = ReactDom.findDOMNode(this.refs.passwordStrengthText);

    let val = password.value;
    let result = zxcvbn(val);
    meter.value = result.score;

    if(val !== "") {
        this.setState({
          password1: val,
          resultScore:strength[result.score],
          warning:result.feedback.warning,
          suggestions:result.feedback.suggestions
        });
    }
    else {
      this.setState({
        resultScore:'',
        warning:'',
        suggestions:''
      })
    }

    if(typeof this.props.onChange === 'function'){
      this.props.onChange(event);
    }
  }

  handleInput2(event){
    const password = ReactDom.findDOMNode(this.refs.password2);
    let val = password.value;
    if(val !== "") {
        this.setState({
          password2: val,
        });
    }

  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.authCarga();
      } 
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  authCarga(){
    let data =       
    {
      'client_id': '220zQX3VslroUhMSZ4zmfgPi0hdyqnij',
      'client_secret': 'q_RvIeyPcCoYYcQvu91pcvNTT6EELoA427agvI64NeqzO5lXD_dt6zsbYCCvSmx6',
      'audience': 'https://tcperu.auth0.com/api/v2/',
      'grant_type': 'client_credentials'
    }
    axios.post('https://tcperu.auth0.com/oauth/token', data, {header: 'content-type: application/json', crossDomain: true
    }).then((response) => {
      let token = response.data.access_token;
      this.setState({tokenSec: 'Bearer ' + token})
    })
  }

  async actualizar(){
    if(this.state.password1 != "" && this.state.password2!= ""){
      if(this.state.password1 == this.state.password2){
        if(this.state.resultScore == "Seguro"  || this.state.resultScore == "Muy Seguro"){
          this.setState({isLoading: true})
          axios.defaults.headers.common['Authorization'] = this.state.tokenSec
          axios.patch('https://tcperu.auth0.com/api/v2/users/' + this.state.idPersona,
              {
                "blocked": false,
                "password": this.state.password1,
                "user_metadata": {},
                "app_metadata": {},
                "connection": "TC-BD",
                "client_id": "MxzsgeU7uo8lTkIaKYgpwyKrbELw7I5n"
              }
            ).then(response => {
              if(response.data.username == this.state.usuario){
                this.props.handleClick("tl", "info", "La contraseña ha sido actualizada correctamente!", "pe-7s-smile")
                this.setState({isLoading: false})
              }else{
                this.props.handleClick("tl", "error", "Hubo un problema de validación!", "pe-7s-close")
                this.setState({isLoading: false})
              }
            })
            .catch(error => {
              if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
              }else{
                console.log(error);
                this.setState({isLoading: false})
              }
            })

        }else{
          this.props.handleClick("tl", "warning", "La contraseña ingresada no es segura!", "pe-7s-info")
        }
      }else{
        this.props.handleClick("tl", "warning", "Las contraseñas no coinciden!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info")
    }

  }

  async verificar(){
    if(this.state.type == 'password'){
      this.setState({type: 'text', valida: 'Proteger'});
    }else{
      this.setState({type: 'password', valida: 'Verificar'});
    }
  }

  render() {
    const { passwordText } = this.props;
    const passwordHeader = (passwordText) ? passwordText : 'Enter Password';
    const {resultScore, warning, suggestions, type, valida} = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Cambio de Contraseña"
                content={
                  <div style={{marginBottom: -10}}>
                  {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>Escribe tu Nueva Contraseña</ControlLabel><br></br>
                            <input onInput={this.handleInput} type={type} name="password" id="password" ref="password" className="form-control"/>
                            <br></br>
                            <meter max="4" id="password-strength-meter" ref="passwordStrengthMeter" style={{width: '100%'}}></meter>
                            <p id="password-strength-text" ref="passwordStrengthText">
                              {/* {resultScore && "Strength: "} <strong>{resultScore}</strong><span className="feedback">{warning + " " + suggestions}</span> */}
                              {resultScore && "Nivel de Seguridad: "} <strong>{resultScore}</strong>
                            </p>
                        </div>
                        <div class="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>Repite tu Nueva Contraseña</ControlLabel>
                            <input onInput={this.handleInput2} type={type} name="password2" id="password2" ref="password2" className="form-control"/>                    
                        </div>
                    </div>
                    )}
                    <h4 className="category" style={{fontSize: 15, marginTop: 1, marginBottom: 10}}>Recuerda que esta contraseña es unica para todos las aplicaciones internas del Tribunal Constitucional, al cambiarla también cambiará para las aplicaciones tales como el STD, Intranet, Gobierno Abierto y Asistencia Única.</h4>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.verificar() }}>{valida}</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar</Button>
                  </div>}
              />
            </Col>
          </Row>
          <Row>
          <h5 style={{marginTop: 20, marginLeft: 15}}>Instrucciones</h5>
              <Col md={6} style={{letterSpacing: 0.5}}>
                <Alert bsStyle="info"><span>Debe contener números aleatorios.</span></Alert>
                <Alert bsStyle="info"><span>Debe contener mínimo 8 caracteres.</span></Alert>
              </Col>
              <Col md={6} style={{letterSpacing: 0.5}}>
                <Alert bsStyle="warning"><span>Utilice caracteres en mayúsculas.</span></Alert>
                <Alert bsStyle="warning"><span>Tambien puedes usar solo estos simbolos $#_-</span></Alert>
              </Col>
            </Row>
        </Grid>
      </div>
    );
  }
}

export default Configuracion;