import Inicio from "views/inicio/Inicio.jsx";
import Marcaciones from "views/marcaciones/Marcaciones.jsx";
import Papeletas from "views/papeletas/Papeletas.jsx";
import verPapeleta from "views/papeletas/verPapeleta.jsx";
import newPapeleta from "views/papeletas/newPapeleta.jsx";
import Boletas from "views/boletas/Boletas.jsx";
import Salidas from "views/salidas/Salidas.jsx";
import VisorBoleta from "views/visor/VisorBoleta.jsx";
import VisorSalida from "views/visor/VisorSalida.jsx";
import VisorAutorizacion from "views/visor/VisorAutorizacion.jsx";
import VisorDetalle from "views/visor/VisorDetalle.jsx";
import Firma from "views/Firma.jsx";
import Autorizar from "views/autorizar/Autorizar.jsx";
import Administrador from "views/administrador/Administrador.jsx";
import Usuarios from "views/administrador/Usuarios.jsx";
import Asistencias from "views/administrador/Asistencias.jsx";
import Areas from "views/administrador/Areas.jsx";
import Marcas from "views/administrador/Marcas.jsx";
import Colaboradores from "views/administrador/Colaboradores.jsx";
import Trabajadores from "views/administrador/Trabajadores.jsx";
import Chat from "views/Chat.jsx";
import Perfil from "views/perfil/Perfil.jsx";
import Noticias from "views/Noticias.jsx";
import Mensajes from "views/Mensajes.jsx";
import Calendario from "views/Calendario.jsx";
import Configuracion from "views/configuracion/Configuracion.jsx";
import Cargar from "views/labores/Cargar.jsx";
import Aprobar from "views/labores/Aprobar.jsx";
import Remoto from "views/labores/Remoto.jsx";
import Emitir from "views/efectivo/Emitir.jsx";
import EditarSoli from "views/efectivo/EditarSoli.jsx";
import Bandeja from "views/efectivo/Bandeja.jsx";
import Concursos from "views/concursos/Concursos.jsx";
import Programas from "views/programas/Programas.jsx";
import Documentacion from "views/programas/Documentacion";
import DetPrograma from "views/programas/DetPrograma";
import PostulantesPas from "views/programas/Postulantes.jsx";
import FichaPostulantePas from "views/programas/FichaPostulante.jsx";
import DetConcurso from "views/concursos/DetConcurso.jsx";
import Entrevistas from "views/concursos/Entrevistas.jsx";
import Postulantes from "views/concursos/Postulantes.jsx";
import Detalle from "views/concursos/Detalle.jsx";
import FichaPostulante from "views/concursos/FichaPostulante.jsx";
import Auditoria from "views/auditoria/Auditoria.jsx";
import AudiPostulantes from "views/auditoria/AudiPostulantes.jsx";
import AudiDetalle from "views/auditoria/AudiDetalle.jsx";
import AudiFicha from "views/auditoria/AudiFicha.jsx";
import Canales from "views/canaltv/Canales.jsx";
import Videos from "views/canaltv/Videos.jsx";

//Legajos - Trabajadores
import AdministrarTrabajadores from 'views/Legajos/Trabajadores/Administrar.jsx'
import ListarPensionistas from 'views/Legajos/Trabajadores/Listado.jsx'
import AgregarTrabajador from 'views/Legajos/Trabajadores/Agregar.jsx'
import EditarPersonal from 'views/Legajos/Trabajadores/Editar.jsx'

//Legajos - Personal
import DetallePersonal from 'views/Legajos/Personal/Detalle.jsx'
import ConsultarPersonal from 'views/Legajos/Personal/Listado.jsx'

//Legajos - Legajo - Familiares

import AdministrarFamiliares from 'views/Legajos/Legajo/Familiares/Listado.jsx'
import AgregarFamiliar from 'views/Legajos/Legajo/Familiares/Agregar.jsx'
import EditarFamiliar from 'views/Legajos/Legajo/Familiares/Editar.jsx'

//Legajos - Legajo - Academico

import HistoricoAcademico from 'views/Legajos/Legajo/Academico/Listado.jsx'
import AgregarAcademico from 'views/Legajos/Legajo/Academico/Agregar.jsx'
import EditarAcademico from 'views/Legajos/Legajo/Academico/Editar.jsx'

const dashboardRoutes = [
  {
    path: "/inicio",
    name: "INICIO",
    icon: "pe-7s-home",
    component: Inicio,
    layout: "/admin"
  },
  {
    path: "/marcaciones",
    name: "MARCACIONES",
    icon: "pe-7s-display2",
    component: Marcaciones,
    layout: "/admin"
  },
  {
    path: "/papeletas",
    name: "PAPELETAS",
    icon: "pe-7s-note2",
    component: Papeletas,
    layout: "/admin"
  },
  {
    path: "/visorautorizacion",
    name: "VISOR DE AUTORIZACIONES",
    icon: "",
    component: VisorAutorizacion,
    layout: "/admin"
  },
  {
    path: "/nueva",
    name: "NUEVA PAPELETA",
    icon: "",
    component: newPapeleta,
    layout: "/admin"
  },
  {
    path: "/detalle",
    name: "DETALLE DE PAPELETA",
    icon: "",
    component: verPapeleta,
    layout: "/admin"
  },
  {
    path: "/visordetalle",
    name: "VISOR DE ADJUNTOS",
    icon: "",
    component: VisorDetalle,
    layout: "/admin"
  },
  {
    path: "/boletas",
    name: "BOLETAS DE PAGO",
    icon: "pe-7s-mail-open-file",
    component: Boletas,
    layout: "/admin"
  },
  {
    path: "/salidas",
    name: "OTRAS SALIDAS",
    icon: "pe-7s-copy-file",
    component: Salidas,
    layout: "/admin"
  },
  {
    path: "/visorboletas",
    name: "VISOR DE BOLETAS",
    icon: "",
    component: VisorBoleta,
    layout: "/admin"
  },
  {
    path: "/visorsalidas",
    name: "VISOR DE SALIDAS",
    icon: "",
    component: VisorSalida,
    layout: "/admin"
  },
  {
    path: "/firma",
    name: "FIRMAR BOLETAS",
    icon: "pe-7s-pen",
    component: Firma,
    layout: "/admin"
  },
  {
    path: "/autorizar",
    name: "AUTORIZAR PAPELETAS",
    icon: "pe-7s-check",
    component: Autorizar,
    layout: "/admin"
  },
  {
    path: "/administrador",
    name: "ADMINISTRADOR",
    icon: "pe-7s-config",
    component: Administrador,
    layout: "/admin"
  },
  {
    path: "/usuarios",
    name: "USUARIOS",
    icon: "",
    component: Usuarios,
    layout: "/admin"
  },
  {
    path: "/asistencias",
    name: "ASISTENCIA",
    icon: "",
    component: Asistencias,
    layout: "/admin"
  },
  {
    path: "/areas",
    name: "AREAS",
    icon: "",
    component: Areas,
    layout: "/admin"
  },
  {
    path: "/marcas",
    name: "MARCACIONES POR DÍA",
    icon: "",
    component: Marcas,
    layout: "/admin"
  },
  {
    path: "/colaboradores",
    name: "COLABORADORES",
    icon: "",
    component: Colaboradores,
    layout: "/admin"
  },
  {
    path: "/trabajadores",
    name: "TRABAJADORES",
    icon: "",
    component: Trabajadores,
    layout: "/admin"
  },
  {
    path: "/labores",
    name: "CARGAR LABORES",
    icon: "",
    component: Cargar,
    layout: "/admin"
  },
  {
    path: "/aprobar",
    name: "APROBAR LABORES",
    icon: "",
    component: Aprobar,
    layout: "/admin"
  },
  {
    path: "/emitir",
    name: "SOLICITAR EFECTIVO",
    icon: "",
    component: Emitir,
    layout: "/admin"
  },
  {
    path: "/editarSoli",
    name: "EDITAR SOLICITUD",
    icon: "",
    component: EditarSoli,
    layout: "/admin"
  },
  {
    path: "/bandeja",
    name: "BANDEJA DE PAGOS EN EFECTIVO",
    icon: "",
    component: Bandeja,
    layout: "/admin"
  },
  {
    path: "/programas",
    name: "PROGRAMAS DE EXTENSIÓN PUBLICADAS",
    icon: "",
    component: Programas,
    layout: "/admin"
  },
  {
    path: "/documentacion",
    name: "DOCUMENTACIÓN DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: Documentacion,
    layout: "/admin"
  },
  {
    path: "/detPrograma",
    name: "DETALLE DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: DetPrograma,
    layout: "/admin"
  },
  {
    path: "/postulantesPas",
    name: "POSTULANTES DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: PostulantesPas,
    layout: "/admin"
  },
  {
    path: "/fichaPostulantePas",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: FichaPostulantePas,
    layout: "/admin"
  },
  {
    path: "/concursos",
    name: "CONCURSOS PUBLICADOS",
    icon: "",
    component: Concursos,
    layout: "/admin"
  },
  {
    path: "/detConcurso",
    name: "DETALLE DEL CONCURSO",
    icon: "",
    component: DetConcurso,
    layout: "/admin"
  },
  {
    path: "/entrevistas",
    name: "ENTREVISTAS DEL CONCURSO",
    icon: "",
    component: Entrevistas,
    layout: "/admin"
  },
  {
    path: "/postulantes",
    name: "POSTULANTES DEL CONCURSO",
    icon: "",
    component: Postulantes,
    layout: "/admin"
  },
  {
    path: "/detallePostulante",
    name: "DETALLE DEL POSTULANTE",
    icon: "",
    component: Detalle,
    layout: "/admin"
  },
  {
    path: "/fichaPostulante",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: FichaPostulante,
    layout: "/admin"
  },
  {
    path: "/auditoria",
    name: "CONCURSOS PUBLICADOS",
    icon: "",
    component: Auditoria,
    layout: "/admin"
  },
  {
    path: "/audiPostulantes",
    name: "POSTULANTES DEL CONCURSO",
    icon: "",
    component: AudiPostulantes,
    layout: "/admin"
  },
  {
    path: "/audiDetalle",
    name: "DETALLE DEL POSTULANTE",
    icon: "",
    component: AudiDetalle,
    layout: "/admin"
  },
  {
    path: "/audiFicha",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: AudiFicha,
    layout: "/admin"
  },
  {
    path: "/canales",
    name: "CANALES TV",
    icon: "",
    component: Canales,
    layout: "/admin"
  },
  {
    path: "/videos",
    name: "VIDEOS PUBLICADOS",
    icon: "",
    component: Videos,
    layout: "/admin"
  },
  {
    path: "/remoto",
    name: "TRABAJO REMOTO",
    icon: "",
    component: Remoto,
    layout: "/admin"
  },
  {
    path: "/chat",
    name: "CHAT TRIBUNAL",
    icon: "pe-7s-chat",
    component: Chat,
    layout: "/admin"
  },
  {
    path: "/perfil",
    name: "PERFIL",
    icon: "",
    component: Perfil,
    layout: "/admin"
  },
  {
    path: "/noticias",
    name: "NOTICIAS",
    icon: "",
    component: Noticias,
    layout: "/admin"
  },
  {
    path: "/mensajes",
    name: "MENSAJES",
    icon: "",
    component: Mensajes,
    layout: "/admin"
  },
  {
    path: "/calendario",
    name: "CALENDARIO",
    icon: "",
    component:  Calendario,
    layout: "/admin"
  },
  {
    path: "/configuracion",
    name: "CONFIGURACIÓN",
    icon: "",
    component: Configuracion,
    layout: "/admin"
  },
  //Nuevas Rutas
  {
    path: "/administrarTrabajadores",
    name: "ADMINISTRAR TRABAJADORES",
    icon: "",
    component: AdministrarTrabajadores,
    layout: "/admin"
  },
  {
    path: "/administrarPensionistas",
    name: "ADMINISTRAR PENSIONISTAS",
    icon: "",
    component: ListarPensionistas,
    layout: "/admin"
  },
  {
    path: "/agregarTrabajador",
    name: "AGREGAR TRABAJADOR",
    icon: "",
    component: AgregarTrabajador,
    layout: "/admin"
  },
  {
    path: "/editarPersonal",
    name: "EDITAR PERSONAL",
    icon: "",
    component: EditarPersonal,
    layout: "/admin"
  },
  {
    path: "/consultarPersonal",
    name: "CONSULTAR PERSONAL",
    icon: "",
    component: ConsultarPersonal,
    layout: "/admin"
  },
  {
    path: "/detallePersonal",
    name: "DETALLE DEL PERSONAL",
    icon: "",
    component: DetallePersonal,
    layout: "/admin"
  },
  {
    path: "/administrarFamiliares",
    name: "ADMINISTRAR FAMILIARES",
    icon: "",
    component: AdministrarFamiliares,
    layout: "/admin"
  },
  {
    path: "/agregarFamilia",
    name: "AGREGAR FAMILIAR",
    icon: "",
    component: AgregarFamiliar,
    layout: "/admin"
  },
  {
    path: "/editarFamiliar",
    name: "EDITAR FAMILIAR",
    icon: "",
    component: EditarFamiliar,
    layout: "/admin"
  },
  {
    path: "/historicoAcademico",
    name: "HISTÓRICO ACADÉMICO",
    icon: "",
    component: HistoricoAcademico,
    layout: "/admin"
  },
  {
    path: "/agregarAcademico",
    name: "AGREGAR DATOS ACADÉMICOS",
    icon: "",
    component: AgregarAcademico,
    layout: "/admin"
  },
  {
    path: "/editarAcademico",
    name: "EDITAR DATOS ACADÉMICOS",
    icon: "",
    component: EditarAcademico,
    layout: "/admin"
  }
];

export default dashboardRoutes;
