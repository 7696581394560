import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class HistoricoAcademico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'historicoAcademico',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: "",
      selectedOption2: "",
      areas: [],
      trabajadores: [],
      formacion: [],
      otros: [],
      estado: 0,
      isLoading: true,
      show: true,
    }

    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);
    this.GetActionFormat4= this.GetActionFormat4.bind(this);
  }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
         () => this.trabajadores()
        );
    };

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.areas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async areas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listarareas')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({areas: lista2, isLoading: false})
      }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar áreas!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async trabajadores() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listartrabajadores?codigoArea=' + this.state.selectedOption.value)
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({trabajadores: lista2, isLoading: false})
      }else{
        this.setState({trabajadores: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar trabajadores!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async formacion() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'private/academico/listarformacion?codigoTrabajador=' + this.state.selectedOption2.value)
      .then((res) => {
        let lista2 = [];
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { item: data.item,
                nid: data.nid,
                tipoestudio: data.tipoestudio,
                grado: data.grado,
                centro: data.centro,
                carrera: data.carrera,
                inicio: data.inicio,
                final: data.final,
                adjunto: data.adjunto,
                estado: data.estado,
                tipo: "F"
            })
          })
          this.setState({formacion: lista2})
          this.formacionotros();
        }else{
          this.setState({formacion: [], isLoading: false})
          this.props.handleClick("tl", "error", "Error al consultar registros!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async formacionotros() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/academico/listarformacionotros?codigoTrabajador=' + this.state.selectedOption2.value)
    .then((res) => {
      let lista2 = [];
      let lista = res.data.response;
      if(lista != null){
        lista.map((data) => {
          lista2.push(
            { item: data.item,
              nid: data.nid,
              centro: data.centro,
              curso: data.curso,
              inicio: data.inicio,
              final: data.final,
              adjunto: data.adjunto,
              estado: data.estado,
              tipo: "O"
          })
        })
        this.setState({otros: lista2, isLoading: false})
      }else{
        this.setState({otros: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar registros!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async verificar(datos, param){
      if(param == '0'){
        confirmAlert({
            title: '¿Desea eliminar el registro?',
            message: 'Se eliminará el registro seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.eliminar(datos)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }else if(param == '1'){
        confirmAlert({
            title: '¿Desea anular el registro?',
            message: 'Se anulará el registro seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.anular(datos)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }else{
        confirmAlert({
            title: '¿Desea habilitar el registro?',
            message: 'Se habilitará el registro seleccionado.',
            buttons: [
                {
                label: 'Aceptar',
                onClick: () => this.habilitar(datos)
                },
                {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
      }
  }

  async verificar2(datos, param){
    if(param == '0'){
      confirmAlert({
          title: '¿Desea eliminar el registro?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
              {
              label: 'Aceptar',
              onClick: () => this.eliminar2(datos)
              },
              {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
              }
          ]
      });
    }else if(param == '1'){
      confirmAlert({
          title: '¿Desea anular el registro?',
          message: 'Se anulará el registro seleccionado.',
          buttons: [
              {
              label: 'Aceptar',
              onClick: () => this.anular2(datos)
              },
              {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
              }
          ]
      });
    }else{
      confirmAlert({
          title: '¿Desea habilitar el registro?',
          message: 'Se habilitará el registro seleccionado.',
          buttons: [
              {
              label: 'Aceptar',
              onClick: () => this.habilitar2(datos)
              },
              {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
              }
          ]
      });
    }
  }

  async eliminar(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacion/eliminarregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async anular(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacion/anularregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async habilitar(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacion/habilitarregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido habilitado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al habilitar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async eliminar2(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacionotros/eliminarregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async anular2(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacionotros/anularregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async habilitar2(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/formacionotros/habilitarregistro?codigo=' + data.codigo)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.formacion();
        this.props.handleClick("tl", "info", "El registro ha sido habilitado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al habilitar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async buscar(){
     this.formacion();
  }

  async detalle(data){
    localStorage.setItem('detalle', JSON.stringify(data))
    this.props.history.push('/editarAcademico');
  }

  async editar(data){
    localStorage.setItem('editar', JSON.stringify(data))
    this.props.history.push('/editarAcademico');
  }

  async agregar(){
    this.props.history.push('/agregarAcademico');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-external-link fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Editar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.estado == '0' || row.estado == '1'? (
              <span data-tip="Eliminar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row, '0') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '0' ? (
              <span data-tip="Anular Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row, '1') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-low-vision fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '1' ? (
              <span data-tip="Habilitar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar(row, '2') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  GetActionFormat4(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-external-link fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Editar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.estado == '0' || row.estado == '1'? (
              <span data-tip="Eliminar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar2(row, '0') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '0' ? (
              <span data-tip="Anular Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar2(row, '1') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-low-vision fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.estado == '1' ? (
              <span data-tip="Habilitar Familiar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verificar2(row, '2') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    return (
          <div>
              {row.estado == '0' ? (
              <Button bsStyle="success" type="submit" >ACTIVO</Button>
              ) : (
              <Button bsStyle="danger" type="submit" >INACTIVO</Button>
              )}
          </div>
      );
  }

  GetActionFormat3(cell, row) {
    return (
          <div>
              <Button onClick={() => { this.veradjunto(row) }} bsStyle="info" type="submit" >VER ADJUNTO</Button>
          </div>
      );
  }

  async veradjunto(datos){
    window.open(datos.adjunto, "_blank")
  }

  render() {
    const { selectedOption, selectedOption2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipoestudio", text: "TIPO DE ESTUDIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "grado", text: "GRADO DE ESTUDIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "centro", text: "CENTRO DE ESTUDIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "carrera", text: "CARRERA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "inicio", text: "PERIODO INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "final", text: "PERIODO FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "adjunto", text: "ADJUNTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ];
    const columns2 = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "centro", text: "CENTRO DE ESTUDIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "curso", text: "CURSO O ESPECIALIZACIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "inicio", text: "PERIODO INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "final", text: "PERIODO FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "adjunto", text: "ADJUNTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
        {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat4}
    ];
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.formacion.length
    };
    const options2 = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.otros.length
      };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.formacion }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.otros }
                columns={ columns2 }
                filter={ filterFactory() }
                noDataIndication="No existen registros para mostrar"
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Datos Académicos</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.areas}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.trabajadores}/>
                </div>
              </div>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.buscar() }}>Buscar Datos Académicos</Button>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.agregar() }}>Agregar Nuevo Registro</Button>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>ESTUDIOS DE FORMACIÓN PROFESIONAL</Tab>
                        <Tab>CURSOS, CONOCIMIENTOS Y ESPECIALIZACIONES</Tab>
                        </TabList>
                        <TabPanel>
                        <PaginationProvider
                                pagination={
                                paginationFactory(options)
                                }
                            >
                                { contentTable }
                            </PaginationProvider>
                        </TabPanel>
                        <TabPanel>
                        <PaginationProvider
                                pagination={
                                paginationFactory(options2)
                                }
                            >
                                { contentTable2 }
                            </PaginationProvider>
                        </TabPanel>
                    </Tabs>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default HistoricoAcademico;