import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import AgreTrabajador from "./Componentes/AgreTrabajador";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AgregarTrabajador extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'agregarTrabajador',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      estado: 0,
      isLoading: true,
      show: true,
    }

    this.handler = this.handler.bind(this)
    this.handler2 = this.handler2.bind(this)
    this.handler3 = this.handler3.bind(this)
    this.handler4 = this.handler4.bind(this)
    this.handler5 = this.handler5.bind(this)
    this.handler6 = this.handler6.bind(this)

  }

  handler() {
    this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
  }

  handler2() {
    this.props.history.push('/administrarTrabajadores');
  }

  handler3() {
    this.props.history.replace('/');
  }

  handler4() {
    this.props.handleClick("tl", "success", "Trabajador registrado correctamente!", "pe-7s-close")
    this.props.history.push('/administrarTrabajadores');
  }

  handler5() {
    this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
  }

  handler6() {
    this.props.handleClick("tl", "error", "Ya existe un trabajador con el mismo número de documento!", "pe-7s-close")
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.setState({isLoading: false})
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Agregar Nuevo Trabajador </h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <Tabs style={{marginTop: 30}}>
                        <TabList>
                        <Tab>GENERAL</Tab>
                        </TabList>
                        <TabPanel>
                            <AgreTrabajador 
                                handler = {this.handler} 
                                handler2 = {this.handler2} 
                                handler3 = {this.handler3}
                                handler4 = {this.handler4} 
                                handler5 = {this.handler5}
                                handler6 = {this.handler6}
                            ></AgreTrabajador>   
                        </TabPanel>
                    </Tabs>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AgregarTrabajador;