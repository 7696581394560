import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AgregarFamiliar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarFamiliares',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      selectedOption: "", selectedOption2: "", selectedOption3: "", selectedOption4: "", selectedOption5: "", selectedOption6: "", selectedOption7: "",
      selectedOption8: "", selectedOption9: "", selectedOption10: "", selectedOption11: "", selectedOption12: "", selectedOption13: "", selectedOption14: "",
      selectedOption15: "", selectedOption16: "", selectedOption17: "", selectedOption18: "", selectedOption19: "", selectedOption20: "", selectedOption21: "",
      selectedOption22: "", selectedOption23: "",
      tipos: [],
      areas: [], trabajadores: [], pais: [], tipodocumento: [], ocupacion: [], vinculofamiliar: [], parentesco: [], estadocivil: [], departamentos: [],
      provincias: [], distritos: [], departamentos2: [], provincias2: [], distritos2: [], grado: [], tipoformacion: [], centroestudios: [], tipovia: [], tipozona: [],
      apepaterno: "", apematerno: "", primernombre: "", segundonombre: "", apecasado: "", numerodocumento: "", fechanacimiento: "", cartamedica: "", numerocontacto: "", 
      correoelectronico: "", via: "", zona: "", codigopostal: "", referencia: "", apartamento: "", interior: "", manzana: "", lote: "", kilometro: "", bloque: "",
      dependiente: "", dependienteeps: "", generapdt: "", vive: "", etapa: "", essalud: "",
      estado: 0,
      isLoading: true,
      show: true,
    }
    this.handleApePaterno = this.handleApePaterno.bind(this);
    this.handleApeMaterno = this.handleApeMaterno.bind(this);
    this.handlePrimerNombre = this.handlePrimerNombre.bind(this);
    this.handleSegundoNombre = this.handleSegundoNombre.bind(this);
    this.handleApeCasado = this.handleApeCasado.bind(this);
    this.handleNumeroDocumento = this.handleNumeroDocumento.bind(this);
    this.handleFechaNacimiento = this.handleFechaNacimiento.bind(this);
    this.handleCartaMedica = this.handleCartaMedica.bind(this);
    this.handleNumeroContacto = this.handleNumeroContacto.bind(this);
    this.handleCorreoElectronico = this.handleCorreoElectronico.bind(this);
    this.handleVia = this.handleVia.bind(this);
    this.handleZona = this.handleZona.bind(this);
    this.handleCodigoPostal = this.handleCodigoPostal.bind(this);
    this.handleReferencia = this.handleReferencia.bind(this);
    this.handleApartamento = this.handleApartamento.bind(this);
    this.handleInterior = this.handleInterior.bind(this);
    this.handleManzana = this.handleManzana.bind(this);
    this.handleLote = this.handleLote.bind(this);
    this.handleKilometro = this.handleKilometro.bind(this);
    this.handleBloque = this.handleBloque.bind(this);
    this.handleEtapa = this.handleEtapa.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeValue2 = this.onChangeValue2.bind(this);
    this.onChangeValue3 = this.onChangeValue3.bind(this);
    this.onChangeValue4 = this.onChangeValue4.bind(this);
    this.onChangeValue6 = this.onChangeValue6.bind(this);

  }

    handleApePaterno(event) {this.setState({apepaterno: event.target.value});}
    handleApeMaterno(event) {this.setState({apematerno: event.target.value});}
    handlePrimerNombre(event) {this.setState({primernombre: event.target.value});}
    handleSegundoNombre(event) {this.setState({segundonombre: event.target.value});}
    handleApeCasado(event) {this.setState({apecasado: event.target.value});}
    handleNumeroDocumento(event) {this.setState({numerodocumento: event.target.value});}
    handleFechaNacimiento(event) {this.setState({fechanacimiento: event.target.value});}
    handleCartaMedica(event) {this.setState({cartamedica: event.target.value});}
    handleNumeroContacto(event) {this.setState({numerocontacto: event.target.value});}
    handleCorreoElectronico(event) {this.setState({correoelectronico: event.target.value});}
    handleVia(event) {this.setState({via: event.target.value});}
    handleZona(event) {this.setState({zona: event.target.value});}
    handleCodigoPostal(event) {this.setState({codigopostal: event.target.value});}
    handleReferencia(event) {this.setState({referencia: event.target.value});}
    handleApartamento(event) {this.setState({apartamento: event.target.value});}
    handleInterior(event) {this.setState({interior: event.target.value});}
    handleManzana(event) {this.setState({manzana: event.target.value});}
    handleLote(event) {this.setState({lote: event.target.value});}
    handleKilometro(event) {this.setState({kilometro: event.target.value});}
    handleBloque(event) {this.setState({bloque: event.target.value});}
    handleEtapa(event) {this.setState({etapa: event.target.value});}
    handleChange = selectedOption => {this.setState({ selectedOption },() => this.trabajadores());};
    handleChange2 = selectedOption2 => {this.setState({ selectedOption2 },) };
    handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },) };
    handleChange4 = selectedOption4 => {this.setState({ selectedOption4 },) };
    handleChange5 = selectedOption5 => {this.setState({ selectedOption5 },) };
    handleChange6 = selectedOption6 => {this.setState({ selectedOption6 },) };
    handleChange7 = selectedOption7 => {this.setState({ selectedOption7 },) };
    handleChange8 = selectedOption8 => {this.setState({ selectedOption8 },) };
    handleChange9 = selectedOption9 => {this.setState({ selectedOption9 },) };
    handleChange10 = selectedOption10 => {this.setState({ selectedOption10 },() => this.departamentos());};
    handleChange11 = selectedOption11 => {this.setState({ selectedOption11 },() => this.provincias());};
    handleChange12 = selectedOption12 => {this.setState({ selectedOption12 },() => this.distritos());};
    handleChange13 = selectedOption13 => {this.setState({ selectedOption13 },) };
    handleChange14 = selectedOption14 => {this.setState({ selectedOption14 },) };
    handleChange15 = selectedOption15 => {this.setState({ selectedOption15 },) };
    handleChange16 = selectedOption16 => {this.setState({ selectedOption16 },) };
    handleChange17 = selectedOption17 => {this.setState({ selectedOption17 },() => this.departamentos2());};
    handleChange18 = selectedOption18 => {this.setState({ selectedOption18 },() => this.provincias2());};
    handleChange19 = selectedOption19 => {this.setState({ selectedOption19 },() => this.distritos2());};
    handleChange20 = selectedOption20 => {this.setState({ selectedOption20 },) };
    handleChange21 = selectedOption21 => {this.setState({ selectedOption21 },) };
    handleChange22 = selectedOption22 => {this.setState({ selectedOption22 },) };
    handleChange23 = selectedOption23 => {this.setState({ selectedOption23 },) };

    onChangeValue(event) {this.setState({dependiente: event.target.value});}
    onChangeValue2(event) {this.setState({dependienteeps: event.target.value});}
    onChangeValue3(event) {this.setState({generapdt: event.target.value});}
    onChangeValue4(event) {this.setState({vive: event.target.value});}
    onChangeValue6(event) {this.setState({essalud: event.target.value});}

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.areas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async areas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listarareas')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({areas: lista2})
          this.listatipos();
      }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar áreas!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listatipos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listartiposfamiliar')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({tipos: lista2, isLoading: false})
      }else{
        this.setState({tipos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar listado!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async trabajadores() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/combos/listartrabajadores?codigoArea=' + this.state.selectedOption.value)
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({trabajadores: lista2, isLoading: false})
      }else{
        this.setState({trabajadores: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar trabajadores!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async departamentos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardepartamentos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({departamentos: lista2})
      }else{
        this.setState({departamentos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar departamentos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async provincias() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listarprovincias')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({provincias: lista2})
      }else{
        this.setState({provincias: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar provincias!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async distritos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardistritos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({distritos: lista2})
      }else{
        this.setState({distritos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar distritos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async departamentos2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardepartamentos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({departamentos2: lista2})
      }else{
        this.setState({departamentos2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar departamentos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async provincias2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listarprovincias')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({provincias2: lista2})
      }else{
        this.setState({provincias2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar provincias!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async distritos2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listardistritos')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({distritos2: lista2})
      }else{
        this.setState({distritos2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar distritos!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async validar(){
    var dependiente = ""; var dependienteeps = ""; var generapdt = ""; var vive = ""; var cartamedica = ""; var essalud = ""; var apartamento = ""; var interior = "";
    var manzana = ""; var lote = ""; var kilometro = ""; var bloque = ""; var etapa = "";
    if(this.state.dependiente == ''){
        dependiente = "0";
    }else{
        dependiente = this.state.dependiente;
    }
    if(this.state.dependienteeps == ''){
        dependienteeps = "0";
    }else{
        dependienteeps = this.state.dependienteeps;
    }
    if(this.state.generapdt == ''){
        generapdt = "0";
    }else{
        generapdt = this.state.generapdt;
    }
    if(this.state.vive == ''){
        vive = "0";
    }else{
        vive = this.state.vive;
    }
    if(this.state.cartamedica == ''){
        cartamedica = "--";
    }else{
      cartamedica = this.state.cartamedica;
    }
    if(this.state.essalud == ''){
        essalud = "0";
    }else{
      essalud = this.state.essalud;
    }
    if(this.state.apartamento == ''){
        apartamento = "--";
    }else{
      apartamento = this.state.apartamento;
    }
    if(this.state.interior == ''){
        interior = "--";
    }else{
      interior = this.state.interior;
    }
    if(this.state.manzana == ''){
        manzana = "--";
    }else{
      manzana = this.state.manzana;
    }
    if(this.state.lote == ''){
        lote = "--";
    }else{
      lote = this.state.lote;
    }
    if(this.state.kilometro == ''){
        kilometro = "--";
    }else{
      kilometro = this.state.kilometro;
    }
    if(this.state.bloque == ''){
        bloque = "--";
    }else{
      bloque = this.state.bloque;
    }
    if(this.state.etapa == ''){
        etapa = "--";
    }else{
        etapa = this.state.etapa;
    }
    this.verificar(dependiente,dependienteeps,generapdt,vive,cartamedica,essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa)
  }

  async verificar(dependiente,dependienteeps,generapdt,vive,cartamedica,essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
      if(this.state.apepaterno != '' || this.state.apematerno != '' || this.state.primernombre != '' || this.state.segundonombre != '' || this.state.apecasado != ''
      || this.state.numerodocumento != '' || this.state.fechanacimiento != '' || this.state.sexo != '' || this.state.numerocontacto != '' || this.state.correoelectronico != ''
      || this.state.via != '' || this.state.zona != '' || this.state.codigopostal != '' || this.state.referencia != ''){
        this.agregar(dependiente,dependienteeps,generapdt,vive,cartamedica,essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa);
      }else{
        this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
      }
  }

  async agregar(dependiente,dependienteeps,generapdt,vive,cartamedica,essalud,apartamento,interior,manzana,lote,kilometro,bloque,etapa){
    let fromData = new FormData();
    fromData.append('apepaterno', this.state.apematerno);
    fromData.append('apematerno', this.state.apematerno);
    fromData.append('primernombre', this.state.primernombre);
    fromData.append('segundonombre', this.state.segundonombre);
    fromData.append('apecasado', this.state.apecasado);
    fromData.append('paisemisor', this.state.selectedOption3.value);
    fromData.append('tipodocumento', this.state.selectedOption4.value);
    fromData.append('numerodocumento', this.state.numerodocumento);
    fromData.append('ocupacion', this.state.selectedOption5.value);
    fromData.append('vinculofamiliar', this.state.selectedOption6.value);
    fromData.append('parentesco', this.state.selectedOption7.value);
    fromData.append('estadocivil', this.state.selectedOption8.value);
    fromData.append('dependiente', dependiente);
    fromData.append('dependienteeps', dependienteeps);
    fromData.append('generapdt', generapdt);
    fromData.append('vive', vive);
    fromData.append('paisnacionalidad', this.state.selectedOption9.value);
    fromData.append('fechanacimiento', this.state.fechanacimiento);
    fromData.append('cartamedica', cartamedica);
    fromData.append('sexo', this.state.sexo);
    fromData.append('paisnacimiento', this.state.selectedOption10.value);
    fromData.append('departamento', this.state.selectedOption11.value);
    fromData.append('provincia', this.state.selectedOption12.value);
    fromData.append('distrito', this.state.selectedOption13.value);
    fromData.append('numerocontacto', this.state.numerocontacto);
    fromData.append('correoelectronico', this.state.correoelectronico);
    fromData.append('gradoacademico', this.state.selectedOption14.value);
    fromData.append('tipoformacion', this.state.selectedOption15.value);
    fromData.append('centroestudios', this.state.selectedOption16.value);
    fromData.append('dirpais', this.state.selectedOption17.value);
    fromData.append('dirdepartamento', this.state.selectedOption18.value);
    fromData.append('dirprovincia', this.state.selectedOption19.value);
    fromData.append('dirdistrito', this.state.selectedOption20.value);
    fromData.append('tipovia', this.state.selectedOption21.value);
    fromData.append('numerovia', this.state.via);
    fromData.append('tipozona', this.state.selectedOption22.value);
    fromData.append('numerozona', this.state.zona);
    fromData.append('codigopostal', this.state.codigopostal);
    fromData.append('referencia', this.state.referencia);
    fromData.append('apartamento', apartamento);
    fromData.append('interior', interior);
    fromData.append('manzana', manzana);
    fromData.append('lote', lote);
    fromData.append('kilometro', kilometro);
    fromData.append('bloque', bloque);
    fromData.append('etapa', etapa);
    fromData.append('essalud', essalud);
    fromData.append('nidpersona', this.state.selectedOption2.value);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/familiares/agregarfamiliar', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Familiar registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
}

  async regresar(){
    this.props.history.push('/administrarFamiliares');
  }

  render() {
    const { selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7, 
        selectedOption8, selectedOption9, selectedOption10, selectedOption11, selectedOption12, selectedOption13, selectedOption14,
        selectedOption15, selectedOption16, selectedOption17, selectedOption18, selectedOption19, selectedOption20, selectedOption21, 
        selectedOption22, selectedOption23 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Agregar Nuevo Familiar</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.areas}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                    <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.trabajadores}/>
                </div>
              </div>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Familiar</Button>
              <Button style={{marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <Tabs style={{marginTop: 30}}>
                        <TabList>
                        <Tab>INFORMACIÓN GENERAL</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO PATERNO</ControlLabel>
                                <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO MATERNO</ControlLabel>
                                <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PRIMER NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.primernombre} onChange= {this.handlePrimerNombre}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>SEGUNDO NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.segundonombre} onChange= {this.handleSegundoNombre}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO CASADO(A)</ControlLabel>
                                <FormControl type="text" value={this.state.apecasado} onChange= {this.handleApeCasado}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS EMISOR</ControlLabel>
                                <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.pais}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                                <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.tipodocumento}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE DOCUMENTO</ControlLabel>
                                <FormControl type="text" value={this.state.numerodocumento} onChange= {this.handleNumeroDocumento}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>OCUPACIÓN</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.ocupacion}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>VÍNCULO FAMILIAR</ControlLabel>
                                <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.vinculofamiliar}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PARENTESCO</ControlLabel>
                                <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.parentesco}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>ESTADO CIVIL</ControlLabel>
                                <Select value={selectedOption8} onChange={this.handleChange8} options={this.state.estadocivil}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                                <ControlLabel>DEPENDIENTE</ControlLabel><br></br>
                                <input type="radio" value="0" name="dependiente" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="dependiente" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue2}>
                                <ControlLabel>DEPENDIENTE EPS</ControlLabel><br></br>
                                <input type="radio" value="0" name="dependienteeps" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="dependienteeps" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue3}>
                                <ControlLabel>GENERA PDT</ControlLabel><br></br>
                                <input type="radio" value="0" name="generapdt" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="generapdt" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue4}>
                                <ControlLabel>¿VIVE?</ControlLabel><br></br>
                                <input type="radio" value="0" name="vive" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                <input type="radio" value="1" name="vive" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS DE NACIMIENTO</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS NACIONALIDAD</ControlLabel>
                                <Select value={selectedOption9} onChange={this.handleChange9} options={this.state.pais}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE NACIMIENTO</ControlLabel>
                                <FormControl type="date" value={this.state.fechanacimiento} onChange= {this.handleFechaNacimiento}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CARTA MÉDICA</ControlLabel>
                                <FormControl type="text" value={this.state.cartamedica} onChange= {this.handleCartaMedica}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE SEXO</ControlLabel>
                                <Select value={selectedOption23} onChange={this.handleChange23} options={this.state.tiposexo}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PAÍS NACIMIENTO</ControlLabel>
                                <Select value={selectedOption10} onChange={this.handleChange10} options={this.state.pais}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DEPARTAMENTO</ControlLabel>
                                <Select value={selectedOption11} onChange={this.handleChange11} options={this.state.departamentos}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PROVINCIA</ControlLabel>
                                <Select value={selectedOption12} onChange={this.handleChange12} options={this.state.provincias}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DISTRITO</ControlLabel>
                                <Select value={selectedOption13} onChange={this.handleChange13} options={this.state.distritos}/>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS DE CONTACTO</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>NÚMERO DE CONTACTO</ControlLabel>
                                <FormControl type="text" value={this.state.numerocontacto} onChange= {this.handleNumeroContacto}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>CORREO ELECTRÓNICO</ControlLabel>
                                <FormControl type="text" value={this.state.correoelectronico} onChange= {this.handleCorreoElectronico}/>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DATOS ACADÉMICOS</Tab>
                        </TabList>
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>GRADO ACADÉMICO</ControlLabel>
                                <Select value={selectedOption14} onChange={this.handleChange14} options={this.state.grado}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE FORMACIÓN</ControlLabel>
                                <Select value={selectedOption15} onChange={this.handleChange15} options={this.state.tipoformacion}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption16} onChange={this.handleChange16} options={this.state.centroestudios}/>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>
                    <Tabs style={{marginTop: 10}}>
                        <TabList>
                        <Tab>DIRECCIONES</Tab>
                        </TabList>
                        <TabPanel>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Primera Dirección:
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PAÍS</ControlLabel>
                                        <Select value={selectedOption17} onChange={this.handleChange17} options={this.state.pais}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DEPARTAMENTO</ControlLabel>
                                        <Select value={selectedOption18} onChange={this.handleChange18} options={this.state.departamentos2}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>PROVINCIA</ControlLabel>
                                        <Select value={selectedOption19} onChange={this.handleChange19} options={this.state.provincias2}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>DISTRITO</ControlLabel>
                                        <Select value={selectedOption20} onChange={this.handleChange20} options={this.state.distritos2}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE VÍA</ControlLabel>
                                        <Select value={selectedOption21} onChange={this.handleChange21} options={this.state.tipovia}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE VÍA</ControlLabel>
                                        <FormControl type="text" value={this.state.via} onChange= {this.handleVia}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>TIPO DE ZONA</ControlLabel>
                                        <Select value={selectedOption22} onChange={this.handleChange22} options={this.state.tipozona}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE ZONA</ControlLabel>
                                        <FormControl type="text" value={this.state.zona} onChange= {this.handleZona}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>CÓDIGO POSTAL</ControlLabel>
                                        <FormControl type="text" value={this.state.codigopostal} onChange= {this.handleCodigoPostal}/>
                                    </div>
                                    <div className="col-md-9" style={{marginBottom: 18}}>
                                        <ControlLabel>REFERENCIA</ControlLabel>
                                        <FormControl type="text" value={this.state.referencia} onChange= {this.handleReferencia}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>N° DE DEPARTAMENTO</ControlLabel>
                                        <FormControl type="text" value={this.state.apartamento} onChange= {this.handleApartamento}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>INTERIOR</ControlLabel>
                                        <FormControl type="text" value={this.state.interior} onChange= {this.handleInterior}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>MANZANA</ControlLabel>
                                        <FormControl type="text" value={this.state.manzana} onChange= {this.handleManzana}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>LOTE</ControlLabel>
                                        <FormControl type="text" value={this.state.lote} onChange= {this.handleLote}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>KILOMETRO</ControlLabel>
                                        <FormControl type="text" value={this.state.kilometro} onChange= {this.handleKilometro}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>BLOQUE</ControlLabel>
                                        <FormControl type="text" value={this.state.bloque} onChange= {this.handleBloque}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>ETAPA</ControlLabel>
                                        <FormControl type="text" value={this.state.etapa} onChange= {this.handleEtapa}/>
                                    </div>
                                    <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue6}>
                                        <ControlLabel>ATENCIÓN ESSALUD</ControlLabel><br></br>
                                        <input type="radio" value="0" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>SI</label>
                                        <input type="radio" value="1" name="essalud" style={{marginRight: 5}}/><label style={{marginRight: 10}}>NO</label>
                                    </div>
                                </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        </TabPanel>
                    </Tabs>
                    </div>
                 )}
                 <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Familiar</Button>
                 <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AgregarFamiliar;