import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Select from 'react-select';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarAcademico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'historicoAcademico',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      detalle: JSON.parse(localStorage.getItem('detalle')),
      editar: JSON.parse(localStorage.getItem('editar')),
      nidregistro: "", selectedOption3: "", selectedOption4: "", selectedOption5: "",
      tipos: [], grado: [], tipoformacion: [], centroestudios: [],
      descripcion: "", titulo: "", fechainicio: "", fechatermino: "", observaciones: "", folios: "", curso: "", horas: "", creditos: "", 
      nota: "", areatrabajador: "", trabajador: "",
      estado: 0,
      isLoading: true,
      show: true,
      archivo: "",
      archivo2: "",
      ruta: "",
      file: [],
      tipo: 0,
      editable: false,
    }

    this.handleAreaTrabajador = this.handleAreaTrabajador.bind(this);
    this.handleTrabajador = this.handleTrabajador.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFechaInicio = this.handleFechaInicio.bind(this);
    this.handleFechaTermino = this.handleFechaTermino.bind(this);
    this.handleObservaciones = this.handleObservaciones.bind(this);
    this.handleFolios = this.handleFolios.bind(this);
    this.handleCurso = this.handleCurso.bind(this);
    this.handleHoras = this.handleHoras.bind(this);
    this.handleCreditos = this.handleCreditos.bind(this);
    this.handleNota = this.handleNota.bind(this);

  }

    handleAreaTrabajador(event) {this.setState({areatrabajador: event.target.value});}
    handleTrabajador(event) {this.setState({trabajador: event.target.value});}
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFechaInicio(event) {this.setState({fechainicio: event.target.value});}
    handleFechaTermino(event) {this.setState({fechatermino: event.target.value});}
    handleObservaciones(event) {this.setState({observaciones: event.target.value});}
    handleFolios(event) {this.setState({folios: event.target.value});}
    handleCurso(event) {this.setState({curso: event.target.value});}
    handleHoras(event) {this.setState({horas: event.target.value});}
    handleCreditos(event) {this.setState({creditos: event.target.value});}
    handleNota(event) {this.setState({nota: event.target.value});}

    handleChange3 = selectedOption3 => {this.setState({ selectedOption3 },) };
    handleChange4 = selectedOption4 => {this.setState({ selectedOption4 },) };
    handleChange5 = selectedOption5 => {this.setState({ selectedOption5 },) };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(this.state.detalle !== null){
        localStorage.removeItem('detalle')
        if(this.state.detalle.tipo == "F"){
            this.setState({tipo: 0, editable: false})
            this.obtenerDatosFormacion();
        }else{
            this.setState({tipo: 1, editable: false})
            this.obtenerDatosFormacionOtros();
        }
    }else if(this.state.editar !== null){    
        localStorage.removeItem('editar')
        if(this.state.editar.tipo == "F"){
            this.setState({tipo: 0, editable: true})
            this.obtenerDatosFormacion();
        }else{
            this.setState({tipo: 1, editable: true})
            this.obtenerDatosFormacionOtros();
        }    
    }else{
      this.props.history.push('/historicoAcademico');
    }
  }

  async obtenerDatosFormacion(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/academico/obtenerformacion?nidregistro=' + this.state.detalle.nid)
    .then((res) => {
        let lista = res.data.response;
        if(lista != null){
          this.setState({
            nidregistro: lista.nid,
            selectedOption3: {value: lista.nidgrado, label: lista.grado},
            selectedOption4: {value: lista.nidcarrera, label: lista.carrera},
            selectedOption5: {value: lista.nidentidad, label: lista.entidad},
            descripcion: lista.descripcion,
            titulo: lista.titulo,
            fechainicio: Calendar.calendario11(lista.fechainicio),
            fechatermino: Calendar.calendario11(lista.fechatermino),
            obervaciones: lista.observaciones,
            folios: lista.folios,
            archivo2: lista.archivo,
            ruta: lista.ruta,
            areatrabajador: lista.areatrabajador,
            trabajador: lista.trabajador
          });
          this.listatipos();
        }else{
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Error al obtener informacion!", "pe-7s-info")
        }
      })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async obtenerDatosFormacionOtros(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/academico/obtenerformacionotros?nidregistro=' + this.state.detalle.nid)
    .then((res) => {
        let lista = res.data.response;
        if(lista != null){
          this.setState({
            nidregistro: lista.nid,
            selectedOption5: {value: lista.nidentidad, label: lista.entidad},
            curso: lista.curso,
            fechainicio: Calendar.calendario11(lista.fechainicio),
            fechatermino: Calendar.calendario11(lista.fechatermino),
            horas: lista.horas,
            creditos: lista.creditos,
            nota: lista.nota,
            obervaciones: lista.observaciones,
            folios: lista.folios,
            archivo2: lista.archivo,
            ruta: lista.ruta,
            areatrabajador: lista.areatrabajador,
            trabajador: lista.trabajador
          });
          this.listatipos();
        }else{
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Error al obtener informacion!", "pe-7s-info")
        }
      })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listatipos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'private/tipos/listartiposacademico')
    .then((res) => {
        let lista2 = []
        let lista = res.data.response;
        if(lista != null){
          lista.map((data) => {
            lista2.push(
              { value: data.nid,
                label: data.descripcion,
            })
          });
          this.setState({tipos: lista2, isLoading: false})
      }else{
        this.setState({tipos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error al consultar listado!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async validar(param){
    var folios = ""; var creditos = ""; var nota = "";
    if(this.state.folios == ''){
        folios = "0";
    }else{
        folios = this.state.folios;
    }
    if(this.state.creditos == ''){
        creditos = "0";
    }else{
        creditos = this.state.creditos;
    }
    if(this.state.nota == ''){
        nota = "0";
    }else{
        nota = this.state.nota;
    }
    this.verificar(param,folios,creditos,nota)
  }

  async verificar(param,folios,creditos,nota){
      if(param == 0){
        if(this.state.descripcion != '' || this.state.titulo != '' || this.state.fechainicio != '' || this.state.fechatermino != '' ||  this.state.observaciones != ''){
            if(this.state.archivo == ''){
                this.setState({archivo: '--'})
            }
            this.actualizar(folios);
        }else{
            this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
        }
      }else{
        if(this.state.curso != '' || this.state.fechainicio != '' || this.state.fechatermino != '' || this.state.observaciones != ''){
            if(this.state.archivo == ''){
                this.setState({archivo: '--'})
            }
            this.actualizar2(folios,creditos,nota);
        }else{
            this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close")
        }
      }
  }

  async actualizar(folios){   
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('archivo', this.state.archivo);
    fromData.append('grado', this.state.selectedOption3.value);
    fromData.append('tipoformacion', this.state.selectedOption4.value);
    fromData.append('centroestudios', this.state.selectedOption5.value);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('titulo', this.state.titulo);
    fromData.append('fechainicio', this.state.fechainicio);
    fromData.append('fechatermino', this.state.fechatermino);
    fromData.append('observaciones', this.state.observaciones);
    fromData.append('folios', folios);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/formacion/editarformacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar2(folios,creditos,nota){   
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('archivo', this.state.archivo);
    fromData.append('centroestudios', this.state.selectedOption5.value);
    fromData.append('curso', this.state.curso);
    fromData.append('fechainicio', this.state.fechainicio);
    fromData.append('fechatermino', this.state.fechatermino);
    fromData.append('horas', this.state.horas);
    fromData.append('creditos', creditos);
    fromData.append('nota', nota);
    fromData.append('observaciones', this.state.observaciones);
    fromData.append('folios', folios);
    fromData.append('nidregistro', this.state.nidregistro);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'private/formacion/agregarformacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.props.handleClick("tl", "success", "Registro editado correctamente!", "pe-7s-close")
          this.setState({editable: false, isLoading: false})
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/historicoAcademico');
  }

  async editar(){
      if(this.state.editable == false){
        this.setState({editable: true});
      }else{
        this.setState({editable: false});
      }
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async limpiar(){
    this.setState({archivo: "", file: []})
  }

  async ver(){
    window.open(this.state.ruta, "_blank")
  }

  render() {
    const { selectedOption3, selectedOption4, selectedOption5 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Detalle del Registro Académico</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA DEL TRABAJADOR</ControlLabel>
                    <FormControl type="text" value={this.state.areatrabajador} onChange= {this.handleAreaTrabajador} disabled/>

                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                    <FormControl type="text" value={this.state.trabajador} onChange= {this.handleTrabajador} disabled/>

                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <Tabs style={{marginTop: 30}}>
                        <TabList>
                        {this.state.tipo == 0 ? (
                            <Tab>ESTUDIOS DE FORMACIÓN PROFESIONAL</Tab>
                            ) : ( 
                            <Tab>CURSOS, CONOCIMIENTOS Y ESPECIALIZACIONES</Tab>
                        )}
                        </TabList>
                        {this.state.tipo == 0 ? (
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>GRADO ACADÉMICO</ControlLabel>
                                <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.grado} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CARRERA PROFESIONAL</ControlLabel>
                                <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.tipoformacion} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.centroestudios} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN DEL GRADO</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO RECIBIDO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INICIO</ControlLabel>
                                <FormControl type="date" value={this.state.fechainicio} onChange= {this.handleFechaInicio} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                                <FormControl type="date" value={this.state.fechatermino} onChange= {this.handleFechaTermino} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>OBSERVACIONES</ControlLabel>
                                <FormControl type="text" value={this.state.observaciones} onChange= {this.handleObservaciones} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE FOLIOS</ControlLabel>
                                <FormControl type="text" value={this.state.folios} onChange= {this.handleFolios} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning" onClick={() => { this.ver() }} style={{cursor: 'pointer'}}>
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                {this.state.editable == true ? (
                                <div>
                                    <Alert bsStyle="warning">
                                        <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                    </Alert>
                                    <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                        <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                    </Button>
                                    {this.state.archivo != "" ? (
                                    <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                    ) : ( 
                                    null
                                    )}
                                </div>
                                ) : ( 
                                    null
                                )}
                            </div>
                        </div>
                        {this.state.editable == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar a Histórico</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar(0) }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                        ) : ( 
                        <TabPanel>
                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CENTRO DE ESTUDIOS</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.centroestudios} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CURSO O ESPECIALIZACIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.curso} onChange= {this.handleCurso} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INICIO</ControlLabel>
                                <FormControl type="date" value={this.state.fechainicio} onChange= {this.handleFechaInicio} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                                <FormControl type="date" value={this.state.fechatermino} onChange= {this.handleFechaTermino} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE HORAS</ControlLabel>
                                <FormControl type="text" value={this.state.horas} onChange= {this.handleHoras} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CREDITOS</ControlLabel>
                                <FormControl type="text" value={this.state.creditos} onChange= {this.handleCreditos} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>NOTA EMITIDA</ControlLabel>
                                <FormControl type="text" value={this.state.nota} onChange= {this.handleNota} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>OBSERVACIONES</ControlLabel>
                                <FormControl type="text" value={this.state.observaciones} onChange= {this.handleObservaciones} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE FOLIOS</ControlLabel>
                                <FormControl type="text" value={this.state.folios} onChange= {this.handleFolios} disabled={this.state.editable}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning" onClick={() => { this.ver() }} style={{cursor: 'pointer'}}>
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                {this.state.editable == true ? (
                                <div>
                                    <Alert bsStyle="warning">
                                        <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                    </Alert>
                                    <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                        <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                    </Button>
                                    {this.state.archivo != "" ? (
                                    <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                    ) : ( 
                                    null
                                    )}
                                </div>
                                ) : ( 
                                    null
                                )}
                            </div>
                        </div>
                        {this.state.editable == false ? (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar a Histórico</Button>
                        </div>
                        ) : (
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar(1) }}>Actualizar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.editar() }}>Cancelar Edición</Button>
                        </div>
                        )}
                        </TabPanel>
                        )}
                    </Tabs>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarAcademico;